import SVG from 'react-inlinesvg'
import { toAbsoluteUrl } from '../../../_metronic/helpers'

type Props = {
    title: string
    site: string
    description: string
    logo: string
}

export const PushPreview = ({ title, site, description, logo }: Props) => {
    let siteUrl: URL | undefined
    try {
        siteUrl = new URL(site)
    } catch (e) { }
    return (
        <>
            <div className="card bg-light shadow-xs rounded">
                <div className="d-flex flex-row py-2">
                    <div className="d-flex align-items-center me-4 ms-4">
                        <span className={`svg-icon`}>
                            <SVG src={toAbsoluteUrl('/media/svg/brand-logos/chrome.svg')} className='w-35px h-35px' />
                        </span>
                    </div>
                    <div className="d-flex flex-grow-1 flex-column">
                        <div className="text-dark fw-bolder d-block fs-4">
                            {title.length === 0 ? 'Title' : title}
                        </div>
                        <div className="text-dark d-block fs-6">
                            {!siteUrl?.hostname ? 'Domain' : siteUrl.hostname}
                        </div>
                        <div className="text-dark d-block fs-6">
                            {description.length === 0 ? 'Description' : description}
                        </div>
                    </div>
                    <div className="d-flex align-items-center ms-2 me-4">
                        <img src={logo} className='w-45px h-45px'/>
                    </div>
                </div>
            </div>
        </>
    )
}