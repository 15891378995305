import { gql } from "./gen";

export const QUERY_MY_FULL_PROFILE = gql(`
query MyProfile {
    myProfile{
      id
      email
      role
      advertiserProfile{
        id
        advertiserID
        fname
        lname
        usageType
        company
        country
        region
        city
        address
        zipCode
        taxID
        telegram
        phone
        language
        timezone
        currency
        createdAt
      }
    }
  }
`)

export const QUERY_FIND_USERS = gql(`
  query FindUsers($query:String!,$pagination:PaginationInput!) {
    findUsers(query:$query,pagination:$pagination){
      pageInfo{
        totalCount
      }
      users{
        id
        email
        role
      }
    }
  }
`)

export const QUERY_GET_USER = gql(`
  query GetUser($id:ID!) {
    getUser(id:$id){
      id
      email
      role
      createdAt
      balance{
        id
        amount
        loadLimit
      }
      advertiserProfile{
        id
        advertiserID
        fname
        lname
        usageType
        company
        country
        region
        city
        address
        zipCode
        taxID
        telegram
        phone
        language
        timezone
        currency
        createdAt
      }
    }
  }
`)

export const QUERY_FIND_USERS_WITH_PROFILE = gql(`
  query FindUsersWithProfile($query:String!,$pagination:PaginationInput!) {
    findUsers(query:$query,pagination:$pagination){
      pageInfo{
        totalCount
      }
      users{
        id
        email
        role
        balance{
          id
          amount
        }
        advertiserProfile{
          id
          advertiserID
          telegram
          fname
          lname
          createdAt
        }
      }
    }
  }
`)

export const MUTATION_UPDATE_ADV_PROFILE = gql(`
  mutation UpdateAdvertiserProfile($userID:ID!, $input:UpdateAdvertiserInput!) {
    updateAdvertiserProfile(userID:$userID,input:$input){
      id
    }
  }
`)