import { DeviceBrowserMultiResult } from "../../components/DeviceBrowserMultiPicker";
import { DeviceOSMultiResult } from "../../components/DeviceOSMultiPicker";
import { DeviceTypeMultiResult } from "../../components/DeviceTypeMultiPicker";

export type VersionedFilter<T> = {
    // Объект, который используется в adv-interface (например: IOS)
    frontendValue: T,
    // Значение, которое будет передано в фильтр (например: ios)
    filterValue: string | number,
    // Ключ по которому будет фильтроваться (например: os)
    filterKey: string,
    // Использовать ли фильтр по версиям
    useVersionFilter: boolean,
    // Ключ по которому будет фильтроваться версия (например: osv)
    versionKey: string,
    // Оператор сравнения для фильтрации версии ('<' | '>' | '<=' | '>=' | '==')
    versionOperator: string,
    // Значение ко которому будет фильтроваться версия: (например 10)
    versionValue: number,
}

export type EncodedFiltersHash = {
    OSFilters: VersionedFilter<DeviceOSMultiResult>[],
    Browser: VersionedFilter<DeviceBrowserMultiResult>[],
    DeviceTypes: DeviceTypeMultiResult[],
    WebView: boolean,
}

export const encodeFilters = (filters: Array<VersionedFilter<any>>): String => {
    if (filters.length === 0) return 'true';

    let filterGroups: Array<String> = [];
    for (var i = 0; i < filters.length; i++) {
        const filter = filters[i]
        var filterGroup = `(`
        filterGroup += `${filter.filterKey} == "${filter.filterValue}"`
        if (filter.useVersionFilter) {
            filterGroup += ` && ${filter.versionKey} ${filter.versionOperator} ${filter.versionValue})`
        } else {
            filterGroup += `)`
        }

        filterGroups.push(filterGroup)
    }

    return filterGroups.join(' || ')
}

export const decodeFilters = (deviceFilter: String): EncodedFiltersHash => {
    const hash = deviceFilter.substring(deviceFilter.indexOf('/*') + 2, deviceFilter.indexOf('*/')).trim()
    return JSON.parse(hash)
}