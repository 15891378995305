// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {Stats} from './Stats'
type Props = {
  row: Row<Stats>
}
export const StatsRow: FC<Props> = ({row}) => {
  const formatDate = (dateString: string): string => {
    if (!dateString) return ''
    const date = new Date(dateString)
    return date.toLocaleDateString()
  }

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        if (cell.column.id === 'event_date') {
          return (
            <td {...cell.getCellProps()} className='min-w-100px'>
              {formatDate(cell.value)}
            </td>
          )
        }
        return (
          <td
            {...cell.getCellProps()}
            className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
    </tr>
  )
}
