import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_COUNTRIES } from '../../api/v2/gql/geo';
import { FindCountriesQuery } from '../../api/v2/gql/gen/graphql';

export type CountryMultyPickerResult = FindCountriesQuery['findCountries']['countries'][number]

type Props = {
  options?: Partial<AsyncPaginateProps<CountryMultyPickerResult, GroupBase<CountryMultyPickerResult>, { page: number }, true>>
  value: CountryMultyPickerResult[] | null
  onChange?: (newValue: MultiValue<CountryMultyPickerResult>) => void;
}

export const CountryMultiPicker = (props: Props) => {
  const [fetchCountries] = useLazyQuery(QUERY_FIND_COUNTRIES)

  const loadOptions: LoadOptions<CountryMultyPickerResult, GroupBase<CountryMultyPickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchCountries({
      variables: {
        query: search,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findCountries.countries,
      hasMore: response.data!.findCountries.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className='react-select'
      classNamePrefix='select'
      debounceTimeout={100}
      getOptionLabel={(u) => u.name}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}