import React from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

const RulesInfo = () => {
  return (
    <div className='bg-opacity-5 bg-info border border-info rounded p-3'>
      <div className='d-flex align-items-center'>
        <div className='flex-shrink-0'>
          <KTSVG
            path='/media/icons/duotune/general/gen048.svg'
            className='text-info svg-icon-2hx'
          />
        </div>
        <div className='ms-3'>
          <h6>Rules Info</h6>
          <p>
            These rules turn off sources in a campaign when all specific conditions for one rule are
            met. Use a blacklist in the source settings.
          </p>
        </div>
      </div>
    </div>
  )
}

export default RulesInfo
