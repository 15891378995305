import React from 'react';
import { KTCard } from '../../../_metronic/helpers';
import { StatsTable } from '../dashboard/table/StatsTable';
import { useParams } from 'react-router-dom';

const CampStats: React.FC = () => {
    const { id } = useParams()
    return (
        <KTCard>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <StatsTable campID={id} />
            </div>
        </KTCard>
    );
};

export default CampStats;