import { useEffect, useState } from 'react'
import Flatpickr from 'react-flatpickr'
import { AdminGetStatsByGroup, getStatsByCampaign, getStatsByGroup } from '../../../../api/stats'
import { useAuth } from '../../../modules/auth'
import 'flatpickr/dist/themes/dark.css'
import Select from 'react-select'
import { KTIcon } from '../../../../_metronic/helpers'
import { StatsSelectOptions } from './model/StatsInterface'
import { UserRole } from '../../../../api/v2/gql/gen/graphql'
import { client } from '../../../../api/v2/gql/client'
import { QUERY_GET_CAMPAIGN_ADVERTISER } from '../../../../api/v2/gql/campaigns'

const StatsGroupSelector = ({
  onStatsDataReceived,
  pageSize,
  handleExportData,
  handlePageSizeInHeader,
  statsOptions,
  setLoading,
  campID,
}) => {
  const { currentUser } = useAuth()
  const [dateState, setDateState] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
  })

  const [selectedOptions, setSelectedOptions] = useState(
    campID !== null
      ? [statsOptions.event_date, statsOptions.camp, statsOptions.camp_id]
      : [statsOptions.event_date]
  )
  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions.map((option) => option))
  }

  const handleButtonClick = async () => {
    setLoading(true)
    if (currentUser?.role !== UserRole.Admin) {
      const selectedFieldValues = selectedOptions.map((field) => field.value)
      let formattedStartDate = ''
      let formattedEndDate = ''

      if (dateState.startDate) {
        const startDateObject = new Date(dateState.startDate)
        const startYear = startDateObject.getFullYear()
        const startMonth = String(startDateObject.getMonth() + 1).padStart(2, '0')
        const startDay = String(startDateObject.getDate()).padStart(2, '0')
        formattedStartDate = `${startYear}-${startMonth}-${startDay}`
      }

      if (dateState.endDate) {
        const endDateObject = new Date(dateState.endDate)
        const endYear = endDateObject.getFullYear()
        const endMonth = String(endDateObject.getMonth() + 1).padStart(2, '0')
        const endDay = String(endDateObject.getDate()).padStart(2, '0')
        formattedEndDate = `${endYear}-${endMonth}-${endDay}`
      }

      if (campID !== null) {
        getStatsByCampaign(
          currentUser!.advertiserProfile.advertiserID,
          campID,
          [formattedStartDate, formattedEndDate],
          selectedFieldValues
        )
          .then((response) => {
            onStatsDataReceived(selectedOptions, response)
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        getStatsByGroup(
          currentUser!.advertiserProfile.advertiserID,
          [formattedStartDate, formattedEndDate],
          selectedFieldValues
        )
          .then((response) => {
            onStatsDataReceived(selectedOptions, response)
          })
          .catch((e) => {
            console.log(e)
          })
      }
    } else {
      const selectedFieldValues = selectedOptions.map((field) => field.value)
      let formattedStartDate = ''
      let formattedEndDate = ''

      if (dateState.startDate) {
        const startDateObject = new Date(dateState.startDate)
        const startYear = startDateObject.getFullYear()
        const startMonth = String(startDateObject.getMonth() + 1).padStart(2, '0')
        const startDay = String(startDateObject.getDate()).padStart(2, '0')
        formattedStartDate = `${startYear}-${startMonth}-${startDay}`
      }

      if (dateState.endDate) {
        const endDateObject = new Date(dateState.endDate)
        const endYear = endDateObject.getFullYear()
        const endMonth = String(endDateObject.getMonth() + 1).padStart(2, '0')
        const endDay = String(endDateObject.getDate()).padStart(2, '0')
        formattedEndDate = `${endYear}-${endMonth}-${endDay}`
      }

      if (campID !== null) {
        const campAdvID = (await client.query({
          query: QUERY_GET_CAMPAIGN_ADVERTISER,
          variables: {
            id: campID,
          }
        })).data.getCampaign.advertiser.advertiserID;
        getStatsByCampaign(
          campAdvID,
          campID,
          [formattedStartDate, formattedEndDate],
          selectedFieldValues
        )
          .then((response) => {
            onStatsDataReceived(selectedOptions, response)
          })
          .catch((e) => {
            console.log(e)
            onStatsDataReceived(selectedOptions, [])
          })
      } else {
        AdminGetStatsByGroup([formattedStartDate, formattedEndDate], selectedFieldValues)
          .then((response) => {
            onStatsDataReceived(selectedOptions, response)
          })
          .catch((e) => {
            console.log(e)
            onStatsDataReceived(selectedOptions, [])
          })
      }
    }
  }

  const sortedOptions = Object.values(statsOptions as StatsSelectOptions).sort((a, b) => {
    if (a.label < b.label) return -1
    if (a.label > b.label) return 1
    return 0
  })

  useEffect(() => {
    const fetchData = async () => {
      if (campID !== null) {
        const startDate = new Date()
        startDate.setDate(startDate.getDate() - 7)
        const endDate = new Date()

        setDateState({ startDate, endDate })
        const formattedStartDate = startDate.toISOString().split('T')[0]
        const formattedEndDate = endDate.toISOString().split('T')[0]
        const selectedFieldValues = selectedOptions.map((field) => field.value)

        if (currentUser?.role !== UserRole.Admin) {
          getStatsByCampaign(
            currentUser!.advertiserProfile.advertiserID,
            campID,
            [formattedStartDate, formattedEndDate],
            selectedFieldValues
          )
            .then((response) => {
              onStatsDataReceived(selectedOptions, response)
            })
            .catch((e) => {
              console.log(e)
              onStatsDataReceived(selectedOptions, [])
            })
        } else {
          const campAdvID = (await client.query({
            query: QUERY_GET_CAMPAIGN_ADVERTISER,
            variables: {
              id: campID,
            }
          })).data.getCampaign.advertiser.advertiserID;
          getStatsByCampaign(
            campAdvID,
            campID,
            [formattedStartDate, formattedEndDate],
            selectedFieldValues
          )
            .then((response) => {
              onStatsDataReceived(selectedOptions, response)
            })
            .catch((e) => {
              console.log(e)
              onStatsDataReceived(selectedOptions, [])
            })
        }
      }
    }
    fetchData()
  }, [])

  return (
    <div className='row align-items-center'>
      <div className='col d-flex flex-wrap'>
        <div style={{ height: '50px' }}>
          <Select
            id='categoriestypes'
            name='type-select'
            className='react-select me-2 mb-2'
            options={sortedOptions}
            value={selectedOptions}
            onChange={handleSelectChange}
            isMulti
          />
        </div>
      </div>
      <div className='col-auto'>
        <Flatpickr
          value={[dateState.startDate, dateState.endDate]}
          onChange={([startDate, endDate]) => {
            setDateState({ startDate, endDate })
          }}
          style={{ width: 'auto' }}
          options={{
            mode: 'range',
          }}
          className='form-control form-control-solid me-2'
          placeholder='Pick date'
        />
      </div>
      <div className='col-auto'>
        <button
          type='button'
          className='btn btn-primary ms-2 me-2 h-100'
          onClick={handleButtonClick}
        >
          Submit
        </button>
      </div>
      <div className='col-auto'>
        <button type='button' className='btn btn-light-primary me-3' onClick={handleExportData}>
          <KTIcon iconName='exit-up' className='fs-2' />
          Export
        </button>
      </div>
      <div className='col-auto'>
        <select
          id='pageSize'
          value={pageSize}
          onChange={(e) => handlePageSizeInHeader(parseInt(e.target.value))}
          className='form-select w-100'
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
        </select>
      </div>
    </div>
  )
}

export { StatsGroupSelector }
