import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { CampaignCategoriesDesc } from './CampaignCategoriesData';

export type CampaignCategoryMultiPickerResult = { id: string }

type Props = {
  options?: Partial<AsyncPaginateProps<CampaignCategoryMultiPickerResult, GroupBase<CampaignCategoryMultiPickerResult>, { page: number }, true>>
  value: CampaignCategoryMultiPickerResult[]
  onChange?: (newValue: MultiValue<CampaignCategoryMultiPickerResult>) => void;
}

export const CampaignCategoryMultiPicker = (props: Props) => {
  const loadOptions: LoadOptions<CampaignCategoryMultiPickerResult, GroupBase<CampaignCategoryMultiPickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const q = search.toLowerCase();
    const response = search && search.length > 0 ? Object.keys(CampaignCategoriesDesc).filter((e) => {
      return (CampaignCategoriesDesc[e] as string).toLowerCase().indexOf(q) > -1 || e.toLowerCase().indexOf(q) > -1;
    }) : Object.keys(CampaignCategoriesDesc);

    return {
      options: response.map(e => {
        return {
          id: e,
        }
      }),
      hasMore: false,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.id} ${CampaignCategoriesDesc[u.id]}`}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}