import { gql } from "./gen";

export const MUTATION_SYNC_COSTS = gql(`
  mutation SyncCosts($date:Time!) {
    balanceSyncCosts(date:$date) {
      errorMessages
    }
}
`)

export const MUTATION_CREATE_TRANSACTION = gql(`
  mutation CreateTransaction($input:CreateTransactionInput!) {
    createTransaction(input:$input) {
      id
    }
  }
`)

export const QUERY_FIND_TRANSACTIONS = gql(`
  query FindTransactions($userID:ID, $pagination: PaginationInput!, $startDate: Time, $endDate: Time, $query: String) {
    findTransactions(userID:$userID, pagination: $pagination, startDate: $startDate, endDate: $endDate, query: $query){
      pageInfo{
        totalCount
      }
      transactions{
        id
        before
        after
        type
        reason
        amount
        createdAt
        balance{
          user{
            advertiserProfile{
              fname
              lname
            }
          }
        }
      }
    }
  }
`)