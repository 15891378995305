import React from 'react'
import classnames from 'classnames'
import {usePagination, DOTS} from '../../../usePagination'

export const Pagination = (props) => {
  const {onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className} = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  let lastPage = paginationRange[paginationRange.length - 1]

  const onNext = (event) => {
    event.preventDefault() // Prevent default behavior
    if (currentPage !== lastPage) {
      onPageChange(currentPage + 1)
    }
  }

  const onPrevious = (event) => {
    event.preventDefault() // Prevent default behavior
    if (currentPage !== 1) {
      onPageChange(currentPage - 1)
    }
  }

  return (
    <ul className={classnames('pagination', {[className]: className})}>
      {/* Left navigation arrow */}
      <li
        className={classnames('page-item previous', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <a className='page-link'>
          <i className='previous'></i>
        </a>
      </li>
      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className='page-item dots'>
              &#8230;
            </li>
          )
        }

        // Render our Page Pills
        return (
          <li
            key={pageNumber}
            className={classnames('page-item', {
              active: pageNumber === currentPage,
            })}
            onClick={(event) => {
              event.preventDefault() // Prevent default behavior
              onPageChange(pageNumber)
            }}
          >
            <a href='#' className='page-link'>
              {pageNumber}
            </a>
          </li>
        )
      })}
      {/*  Right Navigation arrow */}
      <li
        className={classnames('page-item next', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <a className='page-link'>
          <i className='next'></i>
        </a>
      </li>
    </ul>
  )
}
