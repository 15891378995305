import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_REGIONS } from '../../api/v2/gql/geo';
import { FindRegionsQuery } from '../../api/v2/gql/gen/graphql';

export type RegionMultiPickerResult = FindRegionsQuery['findRegions']['regions'][number]

type Props = {
  countryIDs: string[],
  options?: Partial<AsyncPaginateProps<RegionMultiPickerResult, GroupBase<RegionMultiPickerResult>, { page: number }, true>>
  value: RegionMultiPickerResult[] | null
  onChange?: (newValue: MultiValue<RegionMultiPickerResult>) => void;
}

export const RegionMultiPicker = (props: Props) => {
  const [fetchRegions] = useLazyQuery(QUERY_FIND_REGIONS)

  const loadOptions: LoadOptions<RegionMultiPickerResult, GroupBase<RegionMultiPickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchRegions({
      variables: {
        query: search,
        countryIDs: props.countryIDs,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findRegions.regions,
      hasMore: response.data!.findRegions.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.name}`}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}