import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTIcon, useDebounce } from '../../../_metronic/helpers';
import { IPaginationRequest } from '../../../api/models';
import { Pagination } from '../../helpers/pagination/Pagination';
import { CampaignDisplayType } from '../campaign/Campaigns';
import { client } from '../../../api/v2/gql/client';
import { QUERY_FIND_CREATIVES } from '../../../api/v2/gql/campaigns';
import { useAuth } from '../../modules/auth';
import { CampaignType, CreativeType, UserRole } from '../../../api/v2/gql/gen/graphql';

type Props = {
  campaignType: CampaignType
  ignoredIds?: number[]
  data: { setCreative: (creative: CampaignDisplayType['creatives'][number]) => {} }
  show: boolean
  handleClose: () => void
}

const SelectCreativeModal: React.FC<Props> = ({ campaignType, ignoredIds, show, handleClose, data }) => {
  const { currentUser } = useAuth();
  const [pagination, setPagination] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })
  const [totalCount, setTotalCount] = useState(0)

  const [search, setSearch] = useState('');
  const debouncesSearch = useDebounce(search, 300)

  const [items, setItems] = useState<CampaignDisplayType['creatives'][number][]>([])
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        setItems([])
        var filterType: CreativeType
        switch (campaignType) {
          case CampaignType.Banner:
            filterType = CreativeType.Banner;
            break;
          case CampaignType.Pops:
            filterType = CreativeType.Pops;
            break;
          case CampaignType.Push:
            filterType = CreativeType.Push;
            break;
        }
        const response = await client.query({
          query: QUERY_FIND_CREATIVES,
          variables: {
            type: filterType,
            pagination: {
              pageNumber: pagination.pageNumber,
              pageSize: pagination.pageSize,
            },
            advID: currentUser!.role === UserRole.Admin ? null : currentUser!.advertiserProfile.advertiserID,
            query: debouncesSearch,
          }
        });
        if (response.data) {
          const creatives = response.data.findCreatives.creatives;
          const actualCreatives = ignoredIds ? creatives.filter((e) => ignoredIds.indexOf(e.id) === -1) : creatives
          setTotalCount(response.data.findCreatives.pageInfo.totalCount);
          setItems(actualCreatives)
        }
      } catch (e) {
        setError(e);
      }
    }

    if (show) {
      fetch();
    }
  }, [show, pagination, debouncesSearch, campaignType])

  const dismiss = () => {
    handleClose()
  }

  const apply = (e: CampaignDisplayType['creatives'][number]) => {
    data.setCreative(e);
    handleClose()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_creative'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-xl'
      aria-hidden='true'
      onHide={dismiss}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Select creative</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dismiss}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <input
            type='text'
            value={search}
            placeholder='Search...'
            className='form-control fw-normal'
            onChange={(e) => setSearch(e.target.value)}
          />
          <div id='kt_modal_select_creatives_map' className='map h-450px overflow-auto'>
            {error && <>
              <div className='mt-4 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  Sorry, looks like there are some errors detected, please try again.
                </div>
              </div>
            </>}
            <table className='table align-middle gs-0 mt-2' style={{ marginBottom: '0' }}>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 w-60px'>Status</th>
                  <th className='w-200px'>Creatives</th>
                  <th className='w-200px'>Creative data</th>
                </tr>
              </thead>
              <tbody>
                {items.map((creative) => (
                  <tr key={creative.id} className='cursor-pointer bg-hover-light' onClick={() => apply(creative)}>
                    <td>
                      <div className='d-flex'>
                        <div className='d-flex align-items-center'>
                          <div className="form-check form-switch form-check-custom form-check-solid justify-content-start">
                            <input className="form-check-input w-35px h-20px ms-4" type="checkbox"
                              defaultChecked={creative.enabled}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        {creative.imgRurl && (<div>
                          <span className='symbol-label'>
                            <img src={creative.imgRurl} style={{ maxWidth: "100px" }} className='h-50px rounded align-self-center me-2' alt='' />
                          </span>
                        </div>)}
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {creative.name}
                          </a>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {(() => {
                              switch (creative.type) {
                                case 'banner':
                                  return <>Banner({creative.size})</>
                                default:
                                  return <>{creative.type}</>
                              }
                            })()}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href={creative.rurl ?? undefined}
                        className='text-dark fw-bold text-hover-primary d-block fs-6'
                      >
                        {creative.rurl && (<>
                          {creative.rurl.length >= 30
                            ? `${creative.rurl.substring(0, 30)}...`
                            : creative.rurl}
                        </>)}

                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7' style={{
                        maxHeight: "3.6em",
                        lineHeight: "1.8em",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        overflow: "hidden",
                      }}>
                        {[creative.title, creative.description]
                          .filter((e) => e ? e.trim().length > 0 : '')
                          .join(' ')}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Pagination
              className='mt-10 mb-5'
              currentPage={pagination.pageNumber}
              totalCount={totalCount}
              pageSize={pagination.pageSize}
              onPageChange={(page: number) => {
                setPagination({ ...pagination, pageNumber: page })
              }}
            />
          </div>
        </div>
        <div className='modal-footer justify-content-between'>
          <a href='/creatives/new' target="_blank" rel="noreferrer" className='btn btn-primary'>
            Create
          </a>
          <button type='button' className='btn btn-light-primary' onClick={dismiss}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  )
}

export { SelectCreativeModal }


// import { useEffect, useState } from "react"
// import { ICreative } from "../../../api/models"
// import { getMyCreatives } from "../../../api/creatives"
// import Select, { MultiValue, SingleValue } from 'react-select'

// type Props = {
//     menuIsOpen: boolean
//     onClosed: (item: ICreative | undefined) => {},
// }

// export const CreativePicker = ({ menuIsOpen, onClosed } : Props) => {
//     const [items, setItems] = useState<ICreative[]>([])

//     useEffect(() => {
//         const fetch = async () => {
//             setItems(await getMyCreatives())
//         }

//         fetch();
//     }, [])

//     return (<>
//         <Select
//             options={items.map((e) => ({
//                 label: e,
//                 value: e,
//             }))}
//             openMenuOnClick={false}
//             openMenuOnFocus={false}
//             menuIsOpen={menuIsOpen}
//             formatOptionLabel={(data) => {
//                 return (<>
//                     <div>
//                         <div className='d-flex align-items-center'>
//                             <div className='me-5'>
//                                 <span className='symbol-label'>
//                                     <img src={data?.value.img_rurl} style={{ maxWidth: "100px" }} className='h-50px rounded align-self-center' alt='' />
//                                 </span>
//                             </div>
//                             <div className='d-flex justify-content-start flex-column'>
//                                 <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
//                                     {data?.value.name}
//                                 </a>
//                                 <span className='text-muted fw-semibold text-muted d-block fs-7'>
//                                     {(() => {
//                                         switch (data?.value.type) {
//                                             case 'banner':
//                                                 return <>Banner({data?.value.size})</>
//                                             default:
//                                                 return <>{data?.value.type}</>
//                                         }
//                                     })()}
//                                 </span>
//                             </div>
//                         </div>
//                         <a
//                             href={data?.value.rurl}
//                             className='text-dark fw-bold text-hover-primary d-block fs-6'
//                         >
//                             {data?.value.rurl.length ?? 0 >= 30
//                                 ? `${data?.value.rurl.substring(0, 30)}...`
//                                 : data?.value.rurl}
//                         </a>
//                         <span className='text-muted fw-semibold text-muted d-block fs-7' style={{
//                             maxHeight: "3.6em",
//                             lineHeight: "1.8em",
//                             textOverflow: "ellipsis",
//                             wordWrap: "break-word",
//                             overflow: "hidden",
//                         }}>
//                             {[data?.value.title, data?.value.desc]
//                                 .filter((e) => e && e.trim().length > 0)
//                                 .join(',')}
//                         </span>
//                     </div>
//                 </>)
//             }}
//             id="creatives"
//             name="type-select"
//             className="react-select"
//             classNamePrefix="select"
//             value={null}
//             onChange={(e) => {
//                 onClosed(e?.value);
//             }}
//         />
//     </>)
// }