import { gql } from "./gen";

export const QUERY_GET_CREATIVE = gql(`
  query GetCreative($id: Int64!) {
    getCreative(id: $id) {
      id
      enabled
      advertiser {
        id
        advertiserID
        telegram
        fname
        lname
        createdAt
        user {
          id
          email
          role
        }
      }
      name
      type
      title
      description
      size
      imgRurl
      rurl
      iconRurl
      createdAt
    }
  }
`);

export const MUTATION_UPDATE_CREATIVE = gql(`
  mutation UpdateCreative($id:Int64!, $input:UpdateCreativeInput!) {
    updateCreative(id:$id,input:$input){
      id
    }
  }
`)

export const MUTATION_CREATE_CREATIVE = gql(`
  mutation CreateCreative($input:CreateCreativeInput!) {
    createCreative(input:$input){
      id
    }
  }
`)

export const MUTATION_DELETE_CREATIVE = gql(`
  mutation DeleteCreative($id:Int64!) {
    deleteCreative(id:$id)
  }
`)

export const MUTATION_DUPLICATE_CREATIVE = gql(`
  mutation DuplicateCreative($id:Int64!) {
    duplicateCreative(id:$id) {
      id
    }
  }
`)