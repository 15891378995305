import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom";
import './component.css'

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const CreativeTemplate = () => {
  const query = useQuery();
  const name = query.get('name')
  const camp = query.get('camp')
  const title = query.get('title')
  const desc = query.get('desc')
  const click_url = query.get('click_url')

  useEffect(() => {
    eval(`
  function mouseMoveHandler(e) {
    var cursor = document.querySelector('.c-tmpl-cursor');
    cursor.style.top = e.clientY + 'px';
    cursor.style.left = e.clientX + 'px';
  }

  document.addEventListener('mousemove', mouseMoveHandler);
  `)

    //   eval(`
    // document.addEventListener('DOMContentLoaded', function() {
    //   const img = document.querySelector('.c-tmpl-card .c-tmpl-img');
    //   const colorThief = new ColorThief();

    //   // Функция для применения доминирующего цвета изображения к фону блока .card
    //   function applyBackgroundColorFromImage() {
    //     // Функция для установки фона после получения цвета
    //     const setColor = (color) => {
    //       const card = img.closest('.c-tmpl-card');
    //       card.style.backgroundColor = 'rgb(\${color.join(',')})';
    //     };

    //       if (img.complete && img.naturalHeight !== 0) {
    //       const dominantColor = colorThief.getColor(img, 10);
    //       setColor(dominantColor);
    //     } else {
    //         img.addEventListener('load', () => {
    //           const dominantColor = colorThief.getColor(img, 10);
    //           setColor(dominantColor);
    //         });
    //     }
    //   }

    //       applyBackgroundColorFromImage();
    // });
    // `)
  }, [])

  return (
    <>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link href="https://fonts.googleapis.com/css2?family=Russo+One&display=swap" rel="stylesheet" />
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
      <script src='https://cdnjs.cloudflare.com/ajax/libs/color-thief/2.3.0/color-thief.umd.js' />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/color-thief/2.3.0/color-thief.umd.js"></script>
      <script>
        {`
          document.addEventListener('DOMContentLoaded', function() {
          const img = document.querySelector('.card img');
          const colorThief = new ColorThief();
  
          // Функция для применения доминирующего цвета изображения к фону блока .card
          function applyBackgroundColorFromImage() {
            // Функция для установки фона после получения цвета
            const setColor = (color) => {
              const card = img.closest('.card');
              card.style.backgroundColor = \`rgb(\${color.join(',')})\`;
            };

            if (img.complete && img.naturalHeight !== 0) {
              const dominantColor = colorThief.getColor(img, 10);
            setColor(dominantColor);
            } else {
              img.addEventListener('load', () => {
                const dominantColor = colorThief.getColor(img, 10);
                setColor(dominantColor);
              });
            }
          }

            applyBackgroundColorFromImage();
        });
        `}
      </script>
      <a href={click_url ? atob(click_url) : ''} className="c-tmpl-a c-tmpl-block" target="_blank">
        <div className="c-tmpl-card">
          <img className="c-tmpl-img" src={camp ?? ''} alt="Avatar" style={{ width: '100%', zIndex: '-1' }} />
          <div className="c-tmpl-container">
            <div className="c-tmpl-text">
              <p className="c-tmpl-p" style={{ fontFamily: `'Russo One', sans-serif`, fontSize: '16px' }}>{title} {desc}</p>
            </div>
            <div className="c-tmpl-arrow">
              <span className="material-symbols-outlined c-tmpl-ars ">
                east
              </span>
            </div>
          </div>
        </div>
      </a>

      <div className="c-tmpl-cursor">
        <div className="c-tmpl-circle"></div>
        <div className="c-tmpl-pulse"></div>
      </div>
    </>
  )
}
