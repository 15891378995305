import { gql } from "./gen";

export const MUTATION_CREATE_DSP = gql(`
  mutation CreateDSPFull($input:CreateDSPFullInput!) {
    createDSPFull(input:$input){
      id
    }
  }
`)

export const MUTATION_UPDATE_DSP = gql(`
  mutation UpdateDSPFull($id: Int64!, $input: UpdateDSPFullInput!) {
    updateDSPFull(id: $id, input: $input) {
      id
    }
  }
`)

export const MUTATION_DELETE_DSP = gql(`
  mutation DeleteDSP($id: Int64!) {
    deleteDSP(id: $id)
  }
`)

export const QUERY_FIND_DSPS = gql(`
  query FindDsps($pagination: PaginationInput!) {
    findDsps(pagination: $pagination) {
      pageInfo {
        totalCount
      }
      dsps {
        id
        enabled
        type
        name
        roi
        payType
        advertiser {
          id
          advertiserID
          user {
            email
          }
        }
        limitStatus
        totalCls
        totalImp
        totalCost
        dailyCls
        dailyImp
        dailyCost
      }
    }
  }
`)

export const QUERY_GET_DSP = gql(`
  query GetDSP($id: Int64!) {
    getDSP(id: $id) {
      id
      enabled
      type
      name
      roi
      advertiser {
        id
        advertiserID
        telegram
        fname
        lname
        createdAt
        user {
          id
          email
          role
        }
      }
      totalCls
      totalImp
      totalCost
      dailyCls
      dailyImp
      dailyCost
      subsTTL
      method
      discrepancy
      requestLimitPercentage
      payType
      limitStatus
      urlWithMacro
      bodyWithMacro
      parsingBid
      parsingURL
      parsingBURL
      parsingAdID
      parsingDesc
      parsingTitle
      parsingIconURL
      parsingBannerW
      parsingBannerH
      parsingCreativeID
      parsingBURL
      parsingAdID
      parsingImageURL
      parsingNotificationURL
      limitsFilter {
        dailyLimitUniq
        dailyLimitCls
        dailyLimitImp
        dailyLimitCost
        totalLimitCls
        totalLimitImp
        totalLimitCost
      }
      countriesFilter {
        whitelistCountries {
          id
          name
        }
      }
      regionsFilter {
        whitelistRegions {
          id
          name
        }
      }
      citiesFilter {
        whitelistCities {
          id
          name
        }
      }
      brandsFilter {
        whitelistBrands
        connectionType
        disableProxy
      }
      categoriesFilter {
        whitelistCategories
        blacklistCategories
      }
      devicesFilter {
        whitelistExec
      }
      sourceFilter {
        whitelistSourceID
        whitelistSSP
        blacklistSourceID
        blacklistSSP
      }
      languageFilter {
        whitelistLanguageCodes
      }
      ipRangeFilter {
        whitelistIPRanges
        blacklistIPRanges
      }
    }
  }
`)