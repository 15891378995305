import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  IPaginationRequest,
} from '../../../api/models'
import { Modal, Spinner } from 'react-bootstrap'
import { Pagination } from '../../helpers/pagination/Pagination'
import clsx from 'clsx'
import { FindDspsQuery, UserRole } from '../../../api/v2/gql/gen/graphql'
import { useLazyQuery } from '@apollo/client'
import { QUERY_FIND_DSPS } from '../../../api/v2/gql/dsps'
import { DSP } from './DSP'

export type DSPDisplayType = FindDspsQuery['findDsps']['dsps'][number]

export const DSPS = () => {
  const PageSize = 10
  const intl = useIntl()
  const [pagination, setPagination] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: PageSize,
  })
  const [totalCount, setTotalCount] = useState(0)

  const [fetchDsps, { loading }] = useLazyQuery(QUERY_FIND_DSPS)
  const [dsps, setDsps] = useState<DSPDisplayType[]>([]);

  useEffect(() => {
    update()
  }, [pagination])

  const update = () => {
    const fetchData = async () => {
      try {
        const response = await fetchDsps({
          variables: {
            pagination: {
              pageNumber: pagination.pageNumber,
              pageSize: pagination.pageSize,
            },
          }
        });
        if (response.data) {
          setDsps(response.data?.findDsps.dsps)
          setTotalCount(response.data?.findDsps.pageInfo.totalCount);
        }
      } catch (error) {

      }
    }

    fetchData()
  }

  return (
    <>
      <Modal show={loading}>
        <div className='d-flex column align-self-center align-items-center'>
          <Spinner animation='border' role='status' />
          <span className='px-4 py-4'>Loading...</span>
        </div>
      </Modal>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Dsps' })}</PageTitle>
      <>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <div>
              {/* Обертка для всех кампаний и творческих элементов */}
              <div className='card-xxl-stretch mb-5 mb-xl-8'>
                {/* begin::Table */}
                <div className='row align-middle gs-0 gy-4'>
                  {dsps.map((dsp, index) => (
                    <div key={dsp.id} className='col-12'>
                      {/* Карточка для кампании */}
                      <div className={clsx('bg-secondary border border-gray-300 rounded')}>
                        <div className='bg-secondary'>
                          <DSP
                            key={dsp.id}
                            index={index}
                            dsp={dsp}
                            update={update}
                            deleteUpdate={() =>
                              setDsps(
                                dsps.filter((x) => x.id !== dsp.id)
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* end::Table */}
                <Pagination
                  className='mt-10 mb-5'
                  currentPage={pagination.pageNumber}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page: number) => {
                    setPagination({ ...pagination, pageNumber: page })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}
