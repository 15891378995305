import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_USERS } from '../../api/v2/gql/user';
import { FindUsersQuery } from '../../api/v2/gql/gen/graphql';

type Props = {
  options?: Partial<AsyncPaginateProps<UserPickerEntity, GroupBase<UserPickerEntity>, { page: number }, false>>
  value?: UserPickerEntity
  onChange?: (value: UserPickerEntity | undefined) => void;
}

export type UserPickerEntity = FindUsersQuery['findUsers']['users'][number]

export const UserPicker = (props: Props) => {
  const [findUsers] = useLazyQuery(QUERY_FIND_USERS)
  const loadOptions: LoadOptions<UserPickerEntity, GroupBase<UserPickerEntity>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await findUsers({
      variables: {
        pagination: {
          pageSize: pageSize,
          pageNumber: page,
        },
        query: search,
      },
    })

    return {
      options: response.data!.findUsers.users,
      hasMore: response.data!.findUsers.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate<UserPickerEntity, GroupBase<UserPickerEntity>, { page: number }>
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange){
          if (u) {
            props.onChange(u)
          } else {
            props.onChange(undefined)
          }
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => u.email}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}