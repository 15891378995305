import React, { useState, useEffect, useMemo } from 'react'
import { CampaignCurrentStatus, getCampaignCurrentStatus, getDSPCurrentStatus } from '../../../api/models'
import { generateAlert, AlertType } from '../../helpers/alert'
import { KTIcon, KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { ConfirmModal } from '../../../_metronic/partials/modals/confirm'
import { useAuth } from '../../modules/auth'
import clsx from 'clsx'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { Modal } from 'react-bootstrap'
import { UserRole } from '../../../api/v2/gql/gen/graphql'
import { CampaignDisplayType } from './Campaigns'
import { useMutation } from '@apollo/client'
import { client } from '../../../api/v2/gql/client'
import { DSPDisplayType } from './DSPS'
import { MUTATION_DELETE_DSP, MUTATION_UPDATE_DSP } from '../../../api/v2/gql/dsps'

type Props = {
  dsp: DSPDisplayType
  index: number
  update: () => void
  deleteUpdate: () => void
}

export const DSP = ({ dsp, index, update, deleteUpdate }: Props) => {
  const [isToggle, setIsToggle] = useState(false)
  const [loading, setLoading] = useState(false)

  const { currentUser } = useAuth()
  const [updateCampaign] = useMutation(MUTATION_UPDATE_DSP)
  const mode = useThemeMode().currentMode()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsToggle(dsp.enabled)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [dsp.enabled])

  const handleUpdateStatus = async () => {
    setLoading(true)
    try {
      await updateCampaign({
        variables: {
          id: dsp.id,
          input: {
            enabled: !isToggle,
          }
        }
      })
      setIsToggle((prev) => !prev)
    } catch (data: any) {
      console.error('An error occurred:', data.error)
      generateAlert(data.error as string, AlertType.ERROR)
    } finally {
      setLoading(false)
    }
  }

  const [showModal, setShowModal] = useState(false)
  const isUnderline = useMemo(() => {
    return currentUser?.role === UserRole.Admin
  }, [currentUser?.role])

  const handleDelete = () => {
    setShowModal(true)
  }

  const confirmDelete = async () => {
    await client.mutate({
      mutation: MUTATION_DELETE_DSP,
      variables: {
        id: dsp.id,
      }
    })
    setShowModal(false)
    update()
    deleteUpdate()
  }

  const cancelDelete = () => {
    setShowModal(false)
  }


  const statusOptions: Array<{ value: CampaignCurrentStatus, label: string, className: string }> = [
    {
      value: 'active',
      label: 'In Progress',
      className: 'badge badge-light-success',
    },
    {
      value: 'limit_status',
      label: 'Paused by limit',
      className: 'badge badge-light-warning',
    },
    {
      value: 'disabled',
      label: 'Paused',
      className: 'badge badge-light-warning',
    },
  ]

  const getStatusLabel = (statusValue) => {
    const status = statusOptions.find((option) => option.value === statusValue)
    return status ? status.label : ''
  }

  const getStatusClass = (statusValue) => {
    const status = statusOptions.find((option) => option.value === statusValue)
    return status ? status.className : ''
  }

  const [showLimits, setShowLimits] = useState(false);

  const handleToggleLimits = () => {
    setShowLimits(!showLimits);
  };

  const renderContent = () => {
    const status = getDSPCurrentStatus({
      ...dsp,
      enabled: isToggle,
    });
    const statusLabel = getStatusLabel(status.code)
    const statusClass = getStatusClass(status.code)

    if (status.code === 'limit_status') {
      return (
        <>
          <div className={` row ${statusClass}`} onClick={handleToggleLimits}>
            {statusLabel}
            <span className={`row mt-2`}>Reason: {status.reason}</span>
            {showLimits && (
              <>
                <Modal
                  className='modal fade'
                  data-backdrop='static'
                  tabIndex={-1}
                  role='dialog'
                  show={showLimits && status.code === 'limit_status'}
                  dialogClassName='modal-md'
                  aria-hidden='true'
                >
                  <tr>
                    <td className='text-center'>Daily Impressions: {dsp.dailyImp}</td>
                    <td className='text-center'>Daily Clicks: {dsp.dailyCls}</td>
                    <td className='text-center'>Daily Cost: {dsp.dailyCost}</td>
                    <td className='text-center'>Total Impressions: {dsp.totalImp}</td>
                    <td className='text-center'>Total Clicks: {dsp.totalCls}</td>
                    <td className='text-center'>Total Cost: {dsp.totalCost}</td>
                  </tr>
                </Modal>
              </>
            )}
          </div>
        </>
      )
    } else {
      return (
        <div className={` row ${statusClass}`}>
          {statusLabel}
          {status.code === 'rejected' && (
            <span className={`row mt-2`}>Reason: {status.reason}</span>
          )}
        </div>
      )
    }
  }

  return (
    <div className={`bg-secondary rounded-top`}>
      {/* begin::Body */}
      <div>
        {/* begin::Table container */}
        <div className={clsx('table-responsive rounded-top', { 'card rounded-0': mode === 'light' })}>
          {/* begin::Table */}
          <table className='table align-middle gs-0' style={{ marginBottom: '0' }}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold bg-light'>
                <th className='text-center ps-4 w-70px rounded-start'>Status</th>
                <th className='text-center w-150px'>Actual process</th>
                <th className='text-center w-150px'>DSP</th>
                {currentUser?.role === UserRole.Admin && <th className='text-center w-150px'>Advertiser</th>}
                <th className='text-center w-150px'>Type</th>
                <th className='text-center w-150px'>Pay type</th>
                <th className='w-200px text-end pe-4'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='form-check form-switch form-check-custom form-check-solid justify-content-start'>
                    <input
                      className='form-check-input ms-4'
                      type='checkbox'
                      disabled={loading}
                      checked={isToggle}
                      onChange={(e) => {
                        handleUpdateStatus()
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    {renderContent()}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <span className='d-text-dark mb-1 fs-6'>
                      {dsp.name}
                    </span>
                  </div>
                </td>
                {currentUser?.role === UserRole.Admin && (
                  <td>
                    <div className='d-flex justify-content-center'>
                      {dsp.advertiser.advertiserID} - {dsp.advertiser.user.email}
                    </div>
                  </td>
                )}
                <td>
                  <div className='d-flex justify-content-center'>
                    <span className='ms-2'>{dsp.type}</span>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <span className='ms-2'>{dsp.payType}</span>
                  </div>
                </td>
                <td className='text-end pe-4'>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      to={`/dsps/${dsp.id}`}
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </Link>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={handleDelete}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                    {showModal && (
                      <div className='modal fade show' style={{ display: 'block' }} tabIndex={-1}>
                        <div className='modal-dialog'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title'>Confirm deletion</h5>
                              <button type='button' className='btn-close' onClick={cancelDelete} />
                            </div>
                            <div className='modal-body d-flex align-items-center'>Are you sure you want to delete the campaign?</div>
                            <div className='modal-footer'>
                              <button type='button' className='btn btn-secondary' onClick={cancelDelete}>
                                Cancel
                              </button>
                              <button type='button' className='btn btn-danger' onClick={confirmDelete}>
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
