import {FC, useState} from 'react'
import {ColumnInstance} from 'react-table'
import {Stats} from '../Stats'
import {FiChevronDown, FiChevronUp} from 'react-icons/fi'

type SortKey = keyof Stats
type SortState = {
  id: SortKey
  desc: boolean
}

type Props = {
  column: ColumnInstance<Stats>
  sortOrder: SortState
}

const CustomHeaderColumn: FC<Props> = ({column, sortOrder}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const isAsc = sortOrder.desc === false
  const isDesc = sortOrder.desc === true

  const isSorted = column.id === sortOrder.id

  return (
    <th
      {...column.getHeaderProps()}
      style={{borderBottom: 'none'}}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={{verticalAlign: 'middle'}}>{column.render('Header') as React.ReactNode}</span>
        <div style={{width: '1em', height: '1em', marginLeft: '5px', marginBottom: '7px'}}>
          {isHovered && (
            <>
              {isAsc && <FiChevronDown />}
              {isDesc && <FiChevronUp />}
            </>
          )}
          {isSorted && !isHovered && (
            <>
              {isAsc && <FiChevronDown />}
              {isDesc && <FiChevronUp />}
            </>
          )}
        </div>
      </div>
    </th>
  )
}

export {CustomHeaderColumn}
