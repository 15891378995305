/** @format */


// TODO: Deprecated
import axios from "axios";
import { getAuth, setAuth } from "../app/modules/auth";
import * as gqlClient from "./v2/gql/client";
import { MUTATION_REFRESH_TOKEN } from "./v2/gql/auth";

export const basePath = window._env_.REACT_APP_API_BASE_URL || 'http://localhost:8081';

export const $api = axios.create({
    baseURL: basePath,
    withCredentials: true,
});

$api.interceptors.request.use(
    (config) => {
        const authModel = getAuth();
        if (authModel) {
            config.headers.Authorization = `Bearer ${authModel.access_token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

$api.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;

        if (
            error.response.status === 401 &&
            error.config &&
            !originalRequest.__isRetry
        ) {
            originalRequest.__isRetry = true;
            try {
                const authModel = getAuth();
                const response = await gqlClient.client.mutate({
                    mutation: MUTATION_REFRESH_TOKEN,
                    variables: {
                        token: authModel.refresh_token,
                    }
                })
                if (response.data.refreshToken.accessToken && response.data.refreshToken.refreshToken) {
                    setAuth({
                        access_token: response.data.refreshToken.accessToken,
                        refresh_token: response.data.refreshToken.refreshToken,
                    });
                }

                return $api.request(originalRequest);
            } catch (e) {
                console.log("Unauthorized");
                throw e;
            }
        }
        throw error;
    }
);
