import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { GetLanguages } from 'react-country-state-city'
import { useEffect, useMemo, useState } from 'react';

export type LanguageMultiPickerResult = {
  code: string
  name: string
}

type Props = {
  options?: Partial<AsyncPaginateProps<LanguageMultiPickerResult, GroupBase<LanguageMultiPickerResult>, { page: number }, true>>
  value: string[] | null
  onChange?: (newValue: MultiValue<LanguageMultiPickerResult>) => void;
}

export const LanguageMultiPicker = (props: Props) => {
  const [allLanguages, setAllLanguages] = useState<LanguageMultiPickerResult[]>([]);

  useEffect(() => {
    const update = async () => {
      setAllLanguages(await GetLanguages());
    }
    
    update();
  }, [])

  const fullValue = useMemo(() => {
    if (allLanguages.length === 0) return [];

    const values = props.value?.map(e => allLanguages.find(l => l.code === e));
    return values?.filter(e => e) as (LanguageMultiPickerResult[] | undefined)
  }, [props.value, allLanguages])

  const loadOptions: LoadOptions<LanguageMultiPickerResult, GroupBase<LanguageMultiPickerResult>, { page: number }> = async (search, options, additional) => {
    var response = allLanguages;

    if (search.length > 0) {
      response = response.filter(e => e.code.toLowerCase().indexOf(search.toLowerCase().trim()) > -1 || e.name.toLowerCase().indexOf(search.toLowerCase().trim()) > -1);
    }

    return {
      options: response,
      hasMore: false,
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.name}`}
      getOptionValue={(u) => u.code}
      value={fullValue}
      {...props.options}
    />
  )
}