import React, { useEffect, useMemo, useState } from "react";
import { Column, Row, useTable } from "react-table";
import { KTCardBody, KTIcon } from "../../../_metronic/helpers";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from 'yup'
import Select, { SingleValue } from 'react-select';
import { Modal } from "react-bootstrap";
import { FindUsersWithProfileQuery, UsageType, UserRole } from "../../../api/v2/gql/gen/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MUTATION_UPDATE_ADV_PROFILE, QUERY_GET_USER } from "../../../api/v2/gql/user";
import { MUTATION_UPDATE_EMAIL, MUTATION_UPDATE_PASSWORD } from "../../../api/v2/gql/auth";
import { MUTATION_UPDATE_LOAN_LIMIT } from "../../../api/v2/gql/balance";

type UserView = FindUsersWithProfileQuery['findUsers']['users'][number]

interface UsersTableProps {
  data: Array<UserView>
  update: () => {},
}

export const UsersTable: React.FC<UsersTableProps> = ({ data, update }) => {
  const [editModalUserID, setEditModalUserID] = useState<UserView['id'] | null>(null);
  const [updatePasswordUserID, setUpdatePasswordUserID] = useState<UserView['id'] | null>(null);

  const columns = useMemo<Array<Column<UserView>>>(() => [
    {
      Header: 'Name',
      Cell: (cell) => <span>{cell.row.original.advertiserProfile.fname} {cell.row.original.advertiserProfile.lname}</span>
    },
    {
      Header: 'Balance',
      Cell: (cell) => <span>{cell.row.original.balance.amount}</span>
    },
    {
      Header: 'Email',
      Cell: (cell) => <span>{cell.row.original.email}</span>
    },
    {
      Header: 'Role',
      Cell: (cell) => <span>{cell.row.original.role}</span>,
    },
    {
      Header: 'Adv ID',
      Cell: (cell) => <span>{cell.row.original.advertiserProfile.advertiserID}</span>,
    },
    {
      Header: 'Telegram',
      Cell: (cell) => <span>{cell.row.original.advertiserProfile.telegram}</span>,
    },
    {
      Header: 'Created At',
      Cell: (cell) => <span>{moment(cell.row.original.advertiserProfile.createdAt).format('YYYY-MM-DD, h:mm:ss')}</span>,
    },
    {
      Header: 'Actions',
      Cell: (cell) =>
        <div className="d-flex justify-content-center flex-shrink-0">
          <a
            href="#"
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => setEditModalUserID(cell.row.original.id)}
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </a>
          <a
            href="#"
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            onClick={() => setUpdatePasswordUserID(cell.row.original.id)}
          >
            <KTIcon iconName='key-square' className='fs-3' />
          </a>
        </div>
    }
  ], []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <UpdateAdvProfileModal
        onClose={() => {
          setEditModalUserID(null);
          update();
        }}
        show={editModalUserID !== null}
        userID={editModalUserID}
      />
      <UpdatePasswordModal
        onClose={() => {
          setUpdatePasswordUserID(null);
          update();
        }}
        show={updatePasswordUserID !== null}
        userID={updatePasswordUserID}
      />
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headerGroups[0].headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ borderBottom: 'none' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ verticalAlign: 'middle' }}>{column.render('Header') as React.ReactNode}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<UserView>, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render('Cell') as React.ReactNode}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  )
}

type UpdateAdvProfileModalProps = {
  onClose: (created: boolean) => void,
  show: boolean,
  userID: string | null,
}

type UpdateAdvProfileForm = {
  email?: string
  password?: string
  role?: UserRole
  balanceID?: string
  loanLimit?: string
  firstName?: string
  lastName?: string
  advID?: number
  usageType?: UsageType
  company?: string
  country?: string
  region?: string
  city?: string
  address?: string
  zipCode?: string
  taxID?: string
  telegram?: string
  phone?: string
  language?: string
  timezone?: string
  currency?: string
}

type UpdateAdvProfileFormSchema = {
  [K in keyof Required<UpdateAdvProfileForm>]: Yup.Schema<UpdateAdvProfileForm[K]>;
};

export const UpdateAdvProfileModal = (props: UpdateAdvProfileModalProps) => {
  const roleOptions = [
    {
      label: 'Admin',
      value: UserRole.Admin,
    },
    {
      label: 'User',
      value: UserRole.User,
    }
  ];

  const usageTypeOptions = [
    {
      label: 'Campaign',
      value: UsageType.Campaign,
    },
    {
      label: 'Individual',
      value: UsageType.Individual,
    },
  ];

  const [getUser] = useLazyQuery(QUERY_GET_USER)

  useEffect(() => {
    if (props.userID) {
      getUser({
        variables: {
          id: props.userID,
        },
      }).then((response) => {
        if (response.data) {
          formik.setValues({
            password: undefined,
            balanceID: response.data.getUser.balance.id ?? undefined,
            loanLimit: response.data.getUser.balance.loadLimit ?? undefined,
            address: response.data.getUser.advertiserProfile.address ?? undefined,
            advID: response.data.getUser.advertiserProfile.advertiserID,
            city: response.data.getUser.advertiserProfile.city ?? undefined,
            company: response.data.getUser.advertiserProfile.company ?? undefined,
            country: response.data.getUser.advertiserProfile.country ?? undefined,
            currency: response.data.getUser.advertiserProfile.currency ?? undefined,
            email: response.data.getUser.email,
            firstName: response.data.getUser.advertiserProfile.fname,
            language: response.data.getUser.advertiserProfile.language ?? undefined,
            lastName: response.data.getUser.advertiserProfile.lname,
            phone: response.data.getUser.advertiserProfile.phone ?? undefined,
            region: response.data.getUser.advertiserProfile.region ?? undefined,
            role: response.data.getUser.role,
            taxID: response.data.getUser.advertiserProfile.taxID ?? undefined,
            telegram: response.data.getUser.advertiserProfile.telegram ?? undefined,
            timezone: response.data.getUser.advertiserProfile.timezone ?? undefined,
            usageType: response.data.getUser.advertiserProfile.usageType,
            zipCode: response.data.getUser.advertiserProfile.zipCode ?? undefined,
          })
        }
      })
    }
  }, [props.userID])

  const [updateProfile] = useMutation(MUTATION_UPDATE_ADV_PROFILE);
  const [updateLoanLimit] = useMutation(MUTATION_UPDATE_LOAN_LIMIT);

  const formik = useFormik<UpdateAdvProfileForm>({
    initialValues: {} as any as UpdateAdvProfileForm,
    validationSchema: Yup.object().shape<UpdateAdvProfileFormSchema>({
      email: Yup.string().email('Incorrect email'),
      password: Yup.string().min(3, 'Minimum 3 symbols'),
      loanLimit: Yup.string(),
      balanceID: Yup.string(),
      role: Yup.string().oneOf(Object.values(UserRole), 'Incorrect role'),
      company: Yup.string(),
      country: Yup.string(),
      region: Yup.string(),
      city: Yup.string(),
      address: Yup.string(),
      zipCode: Yup.string(),
      taxID: Yup.string(),
      telegram: Yup.string(),
      phone: Yup.string(),
      language: Yup.string(),
      timezone: Yup.string(),
      advID: Yup.number().min(0, "Incorrect advertiser ID"),
      currency: Yup.string(),
      usageType: Yup.string().oneOf(Object.values(UsageType), 'Incorrect usage type'),
      firstName: Yup.string(),
      lastName: Yup.string(),
    }),
    onSubmit: async (values) => {
      if (!props.userID) return;

      await updateProfile({
        variables: {
          userID: props.userID,
          input: {
            address: values.address,
            advID: values.advID,
            city: values.city,
            company: values.company,
            country: values.country,
            currency: values.currency,
            firstName: values.firstName,
            language: values.language,
            lastName: values.lastName,
            phone: values.phone,
            region: values.region,
            taxID: values.taxID,
            telegram: values.telegram,
            timezone: values.timezone,
            usageType: values.usageType,
            zipCode: values.zipCode,
          }
        }
      })
      if (values.loanLimit && values.balanceID) {
        await updateLoanLimit({
          variables: {
            balanceID: values.balanceID,
            limit: values.loanLimit,
          }
        })
      }
      props.onClose(true);
    },
  })

  useEffect(() => {
    formik.resetForm();
  }, [props.show])

  return (
    <Modal
      show={props.show}
      onBackdropClick={() => {
        return props.onClose(false);
      }}
    >
      <div className="modal-content shadow-none">
        <div className="container-xxl">
          <div className="modal-body py-12">
            <form
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {/* Email Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    type='text'
                    className={
                      formik.touched.email && formik.errors.email
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Password input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Password'
                    {...formik.getFieldProps('password')}
                    type='text'
                    className={
                      formik.touched.password && formik.errors.password
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Role input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Role</label>
                <div className='col-lg-8'>
                  <Select
                    options={roleOptions}
                    id="type"
                    name="type-select"
                    className="react-select"
                    classNamePrefix="select"
                    value={roleOptions.find((e) => e.value === formik.values.role)}
                    onChange={(event: SingleValue<{ label: string, value: string }>) => {
                      formik.setFieldValue('role', event?.value);
                    }}
                  />
                  {formik.touched.role && formik.errors.role && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.role}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Loan limit */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Loan limit</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Loan limit'
                    {...formik.getFieldProps('loanLimit')}
                    type='text'
                    className={
                      formik.touched.loanLimit && formik.errors.loanLimit
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.loanLimit && formik.errors.loanLimit && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.loanLimit}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Fname Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>First name</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='First name'
                    {...formik.getFieldProps('firstName')}
                    type='text'
                    className={
                      formik.touched.firstName && formik.errors.firstName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstName}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Last name Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Last name</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Last name'
                    {...formik.getFieldProps('lastName')}
                    type='text'
                    className={
                      formik.touched.lastName && formik.errors.lastName
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastName}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* ADV-ID Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>ADV-ID</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Advertiser ID'
                    {...formik.getFieldProps('advID')}
                    type='number'
                    className={
                      formik.touched.advID && formik.errors.advID
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.advID && formik.errors.advID && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.advID}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Usage type input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Usage type</label>
                <div className='col-lg-8'>
                  <Select
                    options={usageTypeOptions}
                    id="type"
                    name="type-select"
                    className="react-select"
                    classNamePrefix="select"
                    value={usageTypeOptions.find((e) => e.value === formik.values.usageType)}
                    onChange={(event: SingleValue<{ label: string, value: UsageType }>) => {
                      formik.setFieldValue('usageType', event?.value);
                    }}
                  />
                  {formik.touched.usageType && formik.errors.usageType && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.usageType}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Telegram Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Telegram</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Telegram'
                    {...formik.getFieldProps('telegram')}
                    type='text'
                    className={
                      formik.touched.telegram && formik.errors.telegram
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.telegram && formik.errors.telegram && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.telegram}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Language Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Language</label>
                <div className='col-lg-8'>
                  <select
                    className='form-select'
                    {...formik.getFieldProps('language')}
                  >
                    <option value=''>Select a Language...</option>
                    <option value='id'>Bahasa Indonesia - Indonesian</option>
                    <option value='msa'>Bahasa Melayu - Malay</option>
                    <option value='ca'>Català - Catalan</option>
                    <option value='cs'>Čeština - Czech</option>
                    <option value='da'>Dansk - Danish</option>
                    <option value='de'>Deutsch - German</option>
                    <option value='en'>English</option>
                    <option value='en-gb'>English UK - British English</option>
                    <option value='es'>Español - Spanish</option>
                    <option value='fil'>Filipino</option>
                    <option value='fr'>Français - French</option>
                    <option value='ga'>Gaeilge - Irish (beta)</option>
                    <option value='gl'>Galego - Galician (beta)</option>
                    <option value='hr'>Hrvatski - Croatian</option>
                    <option value='it'>Italiano - Italian</option>
                    <option value='hu'>Magyar - Hungarian</option>
                    <option value='nl'>Nederlands - Dutch</option>
                    <option value='no'>Norsk - Norwegian</option>
                    <option value='pl'>Polski - Polish</option>
                    <option value='pt'>Português - Portuguese</option>
                    <option value='ro'>Română - Romanian</option>
                    <option value='sk'>Slovenčina - Slovak</option>
                    <option value='fi'>Suomi - Finnish</option>
                    <option value='sv'>Svenska - Swedish</option>
                    <option value='vi'>Tiếng Việt - Vietnamese</option>
                    <option value='tr'>Türkçe - Turkish</option>
                    <option value='el'>Ελληνικά - Greek</option>
                    <option value='bg'>Български език - Bulgarian</option>
                    <option value='ru'>Русский - Russian</option>
                    <option value='sr'>Српски - Serbian</option>
                    <option value='uk'>Українська мова - Ukrainian</option>
                    <option value='he'>עִבְרִית - Hebrew</option>
                    <option value='ur'>اردو - Urdu (beta)</option>
                    <option value='ar'>العربية - Arabic</option>
                    <option value='fa'>فارسی - Persian</option>
                    <option value='mr'>मराठी - Marathi</option>
                    <option value='hi'>हिन्दी - Hindi</option>
                    <option value='bn'>বাংলা - Bangla</option>
                    <option value='gu'>ગુજરાતી - Gujarati</option>
                    <option value='ta'>தமிழ் - Tamil</option>
                    <option value='kn'>ಕನ್ನಡ - Kannada</option>
                    <option value='th'>ภาษาไทย - Thai</option>
                    <option value='ko'>한국어 - Korean</option>
                    <option value='ja'>日本語 - Japanese</option>
                    <option value='zh-cn'>简体中文 - Simplified Chinese</option>
                    <option value='zh-tw'>繁體中文 - Traditional Chinese</option>
                  </select>
                  {formik.touched.language && formik.errors.language && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.language}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Timezone Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Timezone</label>
                <div className='col-lg-8'>

                  <select
                    className='form-select'
                    {...formik.getFieldProps('timezone')}
                  >
                    <option value=''>Select a Timezone..</option>
                    <option value='International Date Line West'>
                      (GMT-11:00) International Date Line West
                    </option>
                    <option value='Midway Island'>(GMT-11:00) Midway Island</option>
                    <option value='Samoa'>(GMT-11:00) Samoa</option>
                    <option value='Hawaii'>(GMT-10:00) Hawaii</option>
                    <option value='Alaska'>(GMT-08:00) Alaska</option>
                    <option value='Pacific Time (US &amp; Canada)'>
                      (GMT-07:00) Pacific Time (US &amp; Canada)
                    </option>
                    <option value='Tijuana'>(GMT-07:00) Tijuana</option>
                    <option value='Arizona'>(GMT-07:00) Arizona</option>
                    <option value='Mountain Time (US &amp; Canada)'>
                      (GMT-06:00) Mountain Time (US &amp; Canada)
                    </option>
                    <option value='Chihuahua'>(GMT-06:00) Chihuahua</option>
                    <option value='Mazatlan'>(GMT-06:00) Mazatlan</option>
                    <option value='Saskatchewan'>(GMT-06:00) Saskatchewan</option>
                    <option value='Central America'>(GMT-06:00) Central America</option>
                    <option value='Central Time (US &amp; Canada)'>
                      (GMT-05:00) Central Time (US &amp; Canada)
                    </option>
                    <option value='Guadalajara'>(GMT-05:00) Guadalajara</option>
                    <option value='Mexico City'>(GMT-05:00) Mexico City</option>
                    <option value='Monterrey'>(GMT-05:00) Monterrey</option>
                    <option value='Bogota'>(GMT-05:00) Bogota</option>
                    <option value='Lima'>(GMT-05:00) Lima</option>
                    <option value='Quito'>(GMT-05:00) Quito</option>
                    <option value='Eastern Time (US &amp; Canada)'>
                      (GMT-04:00) Eastern Time (US &amp; Canada)
                    </option>
                    <option value='Indiana (East)'>(GMT-04:00) Indiana (East)</option>
                    <option value='Caracas'>(GMT-04:00) Caracas</option>
                    <option value='La Paz'>(GMT-04:00) La Paz</option>
                    <option value='Georgetown'>(GMT-04:00) Georgetown</option>
                    <option value='Atlantic Time (Canada)'>(GMT-03:00) Atlantic Time (Canada)</option>
                    <option value='Santiago'>(GMT-03:00) Santiago</option>
                    <option value='Brasilia'>(GMT-03:00) Brasilia</option>
                    <option value='Buenos Aires'>(GMT-03:00) Buenos Aires</option>
                    <option value='Newfoundland'>(GMT-02:30) Newfoundland</option>
                    <option value='Greenland'>(GMT-02:00) Greenland</option>
                    <option value='Mid-Atlantic'>(GMT-02:00) Mid-Atlantic</option>
                    <option value='Cape Verde Is.'>(GMT-01:00) Cape Verde Is.</option>
                    <option value='Azores'>(GMT) Azores</option>
                    <option value='Monrovia'>(GMT) Monrovia</option>
                    <option value='UTC'>(GMT) UTC</option>
                    <option value='Dublin'>(GMT+01:00) Dublin</option>
                    <option value='Edinburgh'>(GMT+01:00) Edinburgh</option>
                    <option value='Lisbon'>(GMT+01:00) Lisbon</option>
                    <option value='London'>(GMT+01:00) London</option>
                    <option value='Casablanca'>(GMT+01:00) Casablanca</option>
                    <option value='West Central Africa'>(GMT+01:00) West Central Africa</option>
                    <option value='Belgrade'>(GMT+02:00) Belgrade</option>
                    <option value='Bratislava'>(GMT+02:00) Bratislava</option>
                    <option value='Budapest'>(GMT+02:00) Budapest</option>
                    <option value='Ljubljana'>(GMT+02:00) Ljubljana</option>
                    <option value='Prague'>(GMT+02:00) Prague</option>
                    <option value='Sarajevo'>(GMT+02:00) Sarajevo</option>
                    <option value='Skopje'>(GMT+02:00) Skopje</option>
                    <option value='Warsaw'>(GMT+02:00) Warsaw</option>
                    <option value='Zagreb'>(GMT+02:00) Zagreb</option>
                    <option value='Brussels'>(GMT+02:00) Brussels</option>
                    <option value='Copenhagen'>(GMT+02:00) Copenhagen</option>
                    <option value='Madrid'>(GMT+02:00) Madrid</option>
                    <option value='Paris'>(GMT+02:00) Paris</option>
                    <option value='Amsterdam'>(GMT+02:00) Amsterdam</option>
                    <option value='Berlin'>(GMT+02:00) Berlin</option>
                    <option value='Bern'>(GMT+02:00) Bern</option>
                    <option value='Rome'>(GMT+02:00) Rome</option>
                    <option value='Stockholm'>(GMT+02:00) Stockholm</option>
                    <option value='Vienna'>(GMT+02:00) Vienna</option>
                    <option value='Cairo'>(GMT+02:00) Cairo</option>
                    <option value='Harare'>(GMT+02:00) Harare</option>
                    <option value='Pretoria'>(GMT+02:00) Pretoria</option>
                    <option value='Bucharest'>(GMT+03:00) Bucharest</option>
                    <option value='Helsinki'>(GMT+03:00) Helsinki</option>
                    <option value='Kiev'>(GMT+03:00) Kiev</option>
                    <option value='Kyiv'>(GMT+03:00) Kyiv</option>
                    <option value='Riga'>(GMT+03:00) Riga</option>
                    <option value='Sofia'>(GMT+03:00) Sofia</option>
                    <option value='Tallinn'>(GMT+03:00) Tallinn</option>
                    <option value='Vilnius'>(GMT+03:00) Vilnius</option>
                    <option value='Athens'>(GMT+03:00) Athens</option>
                    <option value='Istanbul'>(GMT+03:00) Istanbul</option>
                    <option value='Minsk'>(GMT+03:00) Minsk</option>
                    <option value='Jerusalem'>(GMT+03:00) Jerusalem</option>
                    <option value='Moscow'>(GMT+03:00) Moscow</option>
                    <option value='St. Petersburg'>(GMT+03:00) St. Petersburg</option>
                    <option value='Volgograd'>(GMT+03:00) Volgograd</option>
                    <option value='Kuwait'>(GMT+03:00) Kuwait</option>
                    <option value='Riyadh'>(GMT+03:00) Riyadh</option>
                    <option value='Nairobi'>(GMT+03:00) Nairobi</option>
                    <option value='Baghdad'>(GMT+03:00) Baghdad</option>
                    <option value='Abu Dhabi'>(GMT+04:00) Abu Dhabi</option>
                    <option value='Muscat'>(GMT+04:00) Muscat</option>
                    <option value='Baku'>(GMT+04:00) Baku</option>
                    <option value='Tbilisi'>(GMT+04:00) Tbilisi</option>
                    <option value='Yerevan'>(GMT+04:00) Yerevan</option>
                    <option value='Tehran'>(GMT+04:30) Tehran</option>
                    <option value='Kabul'>(GMT+04:30) Kabul</option>
                    <option value='Ekaterinburg'>(GMT+05:00) Ekaterinburg</option>
                    <option value='Islamabad'>(GMT+05:00) Islamabad</option>
                    <option value='Karachi'>(GMT+05:00) Karachi</option>
                    <option value='Tashkent'>(GMT+05:00) Tashkent</option>
                    <option value='Chennai'>(GMT+05:30) Chennai</option>
                    <option value='Kolkata'>(GMT+05:30) Kolkata</option>
                    <option value='Mumbai'>(GMT+05:30) Mumbai</option>
                    <option value='New Delhi'>(GMT+05:30) New Delhi</option>
                    <option value='Sri Jayawardenepura'>(GMT+05:30) Sri Jayawardenepura</option>
                    <option value='Kathmandu'>(GMT+05:45) Kathmandu</option>
                    <option value='Astana'>(GMT+06:00) Astana</option>
                    <option value='Dhaka'>(GMT+06:00) Dhaka</option>
                    <option value='Almaty'>(GMT+06:00) Almaty</option>
                    <option value='Urumqi'>(GMT+06:00) Urumqi</option>
                    <option value='Rangoon'>(GMT+06:30) Rangoon</option>
                    <option value='Novosibirsk'>(GMT+07:00) Novosibirsk</option>
                    <option value='Bangkok'>(GMT+07:00) Bangkok</option>
                    <option value='Hanoi'>(GMT+07:00) Hanoi</option>
                    <option value='Jakarta'>(GMT+07:00) Jakarta</option>
                    <option value='Krasnoyarsk'>(GMT+07:00) Krasnoyarsk</option>
                    <option value='Beijing'>(GMT+08:00) Beijing</option>
                    <option value='Chongqing'>(GMT+08:00) Chongqing</option>
                    <option value='Hong Kong'>(GMT+08:00) Hong Kong</option>
                    <option value='Kuala Lumpur'>(GMT+08:00) Kuala Lumpur</option>
                    <option value='Singapore'>(GMT+08:00) Singapore</option>
                    <option value='Taipei'>(GMT+08:00) Taipei</option>
                    <option value='Perth'>(GMT+08:00) Perth</option>
                    <option value='Irkutsk'>(GMT+08:00) Irkutsk</option>
                    <option value='Ulaan Bataar'>(GMT+08:00) Ulaan Bataar</option>
                    <option value='Seoul'>(GMT+09:00) Seoul</option>
                    <option value='Osaka'>(GMT+09:00) Osaka</option>
                    <option value='Sapporo'>(GMT+09:00) Sapporo</option>
                    <option value='Tokyo'>(GMT+09:00) Tokyo</option>
                    <option value='Yakutsk'>(GMT+09:00) Yakutsk</option>
                    <option value='Darwin'>(GMT+09:30) Darwin</option>
                    <option value='Adelaide'>(GMT+09:30) Adelaide</option>
                    <option value='Canberra'>(GMT+10:00) Canberra</option>
                    <option value='Melbourne'>(GMT+10:00) Melbourne</option>
                    <option value='Sydney'>(GMT+10:00) Sydney</option>
                    <option value='Brisbane'>(GMT+10:00) Brisbane</option>
                    <option value='Hobart'>(GMT+10:00) Hobart</option>
                    <option value='Vladivostok'>(GMT+10:00) Vladivostok</option>
                    <option value='Guam'>(GMT+10:00) Guam</option>
                    <option value='Port Moresby'>(GMT+10:00) Port Moresby</option>
                    <option value='Solomon Is.'>(GMT+10:00) Solomon Is.</option>
                    <option value='Magadan'>(GMT+11:00) Magadan</option>
                    <option value='New Caledonia'>(GMT+11:00) New Caledonia</option>
                    <option value='Fiji'>(GMT+12:00) Fiji</option>
                    <option value='Kamchatka'>(GMT+12:00) Kamchatka</option>
                    <option value='Marshall Is.'>(GMT+12:00) Marshall Is.</option>
                    <option value='Auckland'>(GMT+12:00) Auckland</option>
                    <option value='Wellington'>(GMT+12:00) Wellington</option>
                    <option value="Nuku'alofa">(GMT+13:00) Nuku'alofa</option>
                  </select>
                  {formik.touched.timezone && formik.errors.timezone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.timezone}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Currency Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Currency</label>
                <div className='col-lg-8'>
                  <select
                    className='form-select'
                    disabled
                    {...formik.getFieldProps('currency')}
                  >
                    <option value='USD'>USD - USA dollar</option>
                    <option value='GBP'>GBP - British pound</option>
                    <option value='AUD'>AUD - Australian dollar</option>
                    <option value='JPY'>JPY - Japanese yen</option>
                    <option value='SEK'>SEK - Swedish krona</option>
                    <option value='CAD'>CAD - Canadian dollar</option>
                    <option value='CHF'>CHF - Swiss franc</option>
                  </select>
                  {formik.touched.currency && formik.errors.currency && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.currency}</div>
                    </div>
                  )}
                </div>
              </div>

              {formik.values.usageType === UsageType.Campaign && (
                <>
                  {/* Company Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Company</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='Company'
                        {...formik.getFieldProps('company')}
                        type='text'
                        className={
                          formik.touched.company && formik.errors.company
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.company && formik.errors.company && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.company}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Country Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Country</label>
                    <div className='col-lg-8'>
                      <select
                        className='form-select'
                        {...formik.getFieldProps('country')}
                      >
                        <option value=''>Select a Country...</option>
                        <option value='AF'>Afghanistan</option>
                        <option value='AX'>Aland Islands</option>
                        <option value='AL'>Albania</option>
                        <option value='DZ'>Algeria</option>
                        <option value='AS'>American Samoa</option>
                        <option value='AD'>Andorra</option>
                        <option value='AO'>Angola</option>
                        <option value='AI'>Anguilla</option>
                        <option value='AQ'>Antarctica</option>
                        <option value='AG'>Antigua and Barbuda</option>
                        <option value='AR'>Argentina</option>
                        <option value='AM'>Armenia</option>
                        <option value='AW'>Aruba</option>
                        <option value='AU'>Australia</option>
                        <option value='AT'>Austria</option>
                        <option value='AZ'>Azerbaijan</option>
                        <option value='BS'>Bahamas</option>
                        <option value='BH'>Bahrain</option>
                        <option value='BD'>Bangladesh</option>
                        <option value='BB'>Barbados</option>
                        <option value='BY'>Belarus</option>
                        <option value='BE'>Belgium</option>
                        <option value='BZ'>Belize</option>
                        <option value='BJ'>Benin</option>
                        <option value='BM'>Bermuda</option>
                        <option value='BT'>Bhutan</option>
                        <option value='BO'>Bolivia, Plurinational State of</option>
                        <option value='BQ'>Bonaire, Sint Eustatius and Saba</option>
                        <option value='BA'>Bosnia and Herzegovina</option>
                        <option value='BW'>Botswana</option>
                        <option value='BV'>Bouvet Island</option>
                        <option value='BR'>Brazil</option>
                        <option value='IO'>British Indian Ocean Territory</option>
                        <option value='BN'>Brunei Darussalam</option>
                        <option value='BG'>Bulgaria</option>
                        <option value='BF'>Burkina Faso</option>
                        <option value='BI'>Burundi</option>
                        <option value='KH'>Cambodia</option>
                        <option value='CM'>Cameroon</option>
                        <option value='CA'>Canada</option>
                        <option value='CV'>Cape Verde</option>
                        <option value='KY'>Cayman Islands</option>
                        <option value='CF'>Central African Republic</option>
                        <option value='TD'>Chad</option>
                        <option value='CL'>Chile</option>
                        <option value='CN'>China</option>
                        <option value='CX'>Christmas Island</option>
                        <option value='CC'>Cocos (Keeling) Islands</option>
                        <option value='CO'>Colombia</option>
                        <option value='KM'>Comoros</option>
                        <option value='CG'>Congo</option>
                        <option value='CD'>Congo, the Democratic Republic of the</option>
                        <option value='CK'>Cook Islands</option>
                        <option value='CR'>Costa Rica</option>
                        <option value='CI'>Côte d'Ivoire</option>
                        <option value='HR'>Croatia</option>
                        <option value='CU'>Cuba</option>
                        <option value='CW'>Curaçao</option>
                        <option value='CY'>Cyprus</option>
                        <option value='CZ'>Czech Republic</option>
                        <option value='DK'>Denmark</option>
                        <option value='DJ'>Djibouti</option>
                        <option value='DM'>Dominica</option>
                        <option value='DO'>Dominican Republic</option>
                        <option value='EC'>Ecuador</option>
                        <option value='EG'>Egypt</option>
                        <option value='SV'>El Salvador</option>
                        <option value='GQ'>Equatorial Guinea</option>
                        <option value='ER'>Eritrea</option>
                        <option value='EE'>Estonia</option>
                        <option value='ET'>Ethiopia</option>
                        <option value='FK'>Falkland Islands (Malvinas)</option>
                        <option value='FO'>Faroe Islands</option>
                        <option value='FJ'>Fiji</option>
                        <option value='FI'>Finland</option>
                        <option value='FR'>France</option>
                        <option value='GF'>French Guiana</option>
                        <option value='PF'>French Polynesia</option>
                        <option value='TF'>French Southern Territories</option>
                        <option value='GA'>Gabon</option>
                        <option value='GM'>Gambia</option>
                        <option value='GE'>Georgia</option>
                        <option value='DE'>Germany</option>
                        <option value='GH'>Ghana</option>
                        <option value='GI'>Gibraltar</option>
                        <option value='GR'>Greece</option>
                        <option value='GL'>Greenland</option>
                        <option value='GD'>Grenada</option>
                        <option value='GP'>Guadeloupe</option>
                        <option value='GU'>Guam</option>
                        <option value='GT'>Guatemala</option>
                        <option value='GG'>Guernsey</option>
                        <option value='GN'>Guinea</option>
                        <option value='GW'>Guinea-Bissau</option>
                        <option value='GY'>Guyana</option>
                        <option value='HT'>Haiti</option>
                        <option value='HM'>Heard Island and McDonald Islands</option>
                        <option value='VA'>Holy See (Vatican City State)</option>
                        <option value='HN'>Honduras</option>
                        <option value='HK'>Hong Kong</option>
                        <option value='HU'>Hungary</option>
                        <option value='IS'>Iceland</option>
                        <option value='IN'>India</option>
                        <option value='ID'>Indonesia</option>
                        <option value='IR'>Iran, Islamic Republic of</option>
                        <option value='IQ'>Iraq</option>
                        <option value='IE'>Ireland</option>
                        <option value='IM'>Isle of Man</option>
                        <option value='IL'>Israel</option>
                        <option value='IT'>Italy</option>
                        <option value='JM'>Jamaica</option>
                        <option value='JP'>Japan</option>
                        <option value='JE'>Jersey</option>
                        <option value='JO'>Jordan</option>
                        <option value='KZ'>Kazakhstan</option>
                        <option value='KE'>Kenya</option>
                        <option value='KI'>Kiribati</option>
                        <option value='KP'>Korea, Democratic People's Republic of</option>
                        <option value='KW'>Kuwait</option>
                        <option value='KG'>Kyrgyzstan</option>
                        <option value='LA'>Lao People's Democratic Republic</option>
                        <option value='LV'>Latvia</option>
                        <option value='LB'>Lebanon</option>
                        <option value='LS'>Lesotho</option>
                        <option value='LR'>Liberia</option>
                        <option value='LY'>Libya</option>
                        <option value='LI'>Liechtenstein</option>
                        <option value='LT'>Lithuania</option>
                        <option value='LU'>Luxembourg</option>
                        <option value='MO'>Macao</option>
                        <option value='MK'>Macedonia, the former Yugoslav Republic of</option>
                        <option value='MG'>Madagascar</option>
                        <option value='MW'>Malawi</option>
                        <option value='MY'>Malaysia</option>
                        <option value='MV'>Maldives</option>
                        <option value='ML'>Mali</option>
                        <option value='MT'>Malta</option>
                        <option value='MH'>Marshall Islands</option>
                        <option value='MQ'>Martinique</option>
                        <option value='MR'>Mauritania</option>
                        <option value='MU'>Mauritius</option>
                        <option value='YT'>Mayotte</option>
                        <option value='MX'>Mexico</option>
                        <option value='FM'>Micronesia, Federated States of</option>
                        <option value='MD'>Moldova, Republic of</option>
                        <option value='MC'>Monaco</option>
                        <option value='MN'>Mongolia</option>
                        <option value='ME'>Montenegro</option>
                        <option value='MS'>Montserrat</option>
                        <option value='MA'>Morocco</option>
                        <option value='MZ'>Mozambique</option>
                        <option value='MM'>Myanmar</option>
                        <option value='NA'>Namibia</option>
                        <option value='NR'>Nauru</option>
                        <option value='NP'>Nepal</option>
                        <option value='NL'>Netherlands</option>
                        <option value='NC'>New Caledonia</option>
                        <option value='NZ'>New Zealand</option>
                        <option value='NI'>Nicaragua</option>
                        <option value='NE'>Niger</option>
                        <option value='NG'>Nigeria</option>
                        <option value='NU'>Niue</option>
                        <option value='NF'>Norfolk Island</option>
                        <option value='MP'>Northern Mariana Islands</option>
                        <option value='NO'>Norway</option>
                        <option value='OM'>Oman</option>
                        <option value='PK'>Pakistan</option>
                        <option value='PW'>Palau</option>
                        <option value='PS'>Palestinian Territory, Occupied</option>
                        <option value='PA'>Panama</option>
                        <option value='PG'>Papua New Guinea</option>
                        <option value='PY'>Paraguay</option>
                        <option value='PE'>Peru</option>
                        <option value='PH'>Philippines</option>
                        <option value='PN'>Pitcairn</option>
                        <option value='PL'>Poland</option>
                        <option value='PT'>Portugal</option>
                        <option value='PR'>Puerto Rico</option>
                        <option value='QA'>Qatar</option>
                        <option value='RE'>Réunion</option>
                        <option value='RO'>Romania</option>
                        <option value='RU'>Russian Federation</option>
                        <option value='RW'>Rwanda</option>
                        <option value='BL'>Saint Barthélemy</option>
                        <option value='SH'>Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value='KN'>Saint Kitts and Nevis</option>
                        <option value='LC'>Saint Lucia</option>
                        <option value='MF'>Saint Martin (French part)</option>
                        <option value='PM'>Saint Pierre and Miquelon</option>
                        <option value='VC'>Saint Vincent and the Grenadines</option>
                        <option value='WS'>Samoa</option>
                        <option value='SM'>San Marino</option>
                        <option value='ST'>Sao Tome and Principe</option>
                        <option value='SA'>Saudi Arabia</option>
                        <option value='SN'>Senegal</option>
                        <option value='RS'>Serbia</option>
                        <option value='SC'>Seychelles</option>
                        <option value='SL'>Sierra Leone</option>
                        <option value='SG'>Singapore</option>
                        <option value='SX'>Sint Maarten (Dutch part)</option>
                        <option value='SK'>Slovakia</option>
                        <option value='SI'>Slovenia</option>
                        <option value='SB'>Solomon Islands</option>
                        <option value='SO'>Somalia</option>
                        <option value='ZA'>South Africa</option>
                        <option value='GS'>South Georgia and the South Sandwich Islands</option>
                        <option value='KR'>South Korea</option>
                        <option value='SS'>South Sudan</option>
                        <option value='ES'>Spain</option>
                        <option value='LK'>Sri Lanka</option>
                        <option value='SD'>Sudan</option>
                        <option value='SR'>Suriname</option>
                        <option value='SJ'>Svalbard and Jan Mayen</option>
                        <option value='SZ'>Swaziland</option>
                        <option value='SE'>Sweden</option>
                        <option value='CH'>Switzerland</option>
                        <option value='SY'>Syrian Arab Republic</option>
                        <option value='TW'>Taiwan, Province of China</option>
                        <option value='TJ'>Tajikistan</option>
                        <option value='TZ'>Tanzania, United Republic of</option>
                        <option value='TH'>Thailand</option>
                        <option value='TL'>Timor-Leste</option>
                        <option value='TG'>Togo</option>
                        <option value='TK'>Tokelau</option>
                        <option value='TO'>Tonga</option>
                        <option value='TT'>Trinidad and Tobago</option>
                        <option value='TN'>Tunisia</option>
                        <option value='TR'>Turkey</option>
                        <option value='TM'>Turkmenistan</option>
                        <option value='TC'>Turks and Caicos Islands</option>
                        <option value='TV'>Tuvalu</option>
                        <option value='UG'>Uganda</option>
                        <option value='UA'>Ukraine</option>
                        <option value='AE'>United Arab Emirates</option>
                        <option value='GB'>United Kingdom</option>
                        <option value='US'>United States</option>
                        <option value='UY'>Uruguay</option>
                        <option value='UZ'>Uzbekistan</option>
                        <option value='VU'>Vanuatu</option>
                        <option value='VE'>Venezuela, Bolivarian Republic of</option>
                        <option value='VN'>Vietnam</option>
                        <option value='VI'>Virgin Islands</option>
                        <option value='WF'>Wallis and Futuna</option>
                        <option value='EH'>Western Sahara</option>
                        <option value='YE'>Yemen</option>
                        <option value='ZM'>Zambia</option>
                        <option value='ZW'>Zimbabwe</option>
                      </select>
                      {formik.touched.country && formik.errors.country && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.country}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Region Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Region</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='Region'
                        {...formik.getFieldProps('region')}
                        type='text'
                        className={
                          formik.touched.region && formik.errors.region
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.region && formik.errors.region && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.region}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* City Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>City</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='City'
                        {...formik.getFieldProps('city')}
                        type='text'
                        className={
                          formik.touched.city && formik.errors.city
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.city && formik.errors.city && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.city}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Address Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Address</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='Address'
                        {...formik.getFieldProps('address')}
                        type='text'
                        className={
                          formik.touched.address && formik.errors.address
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.address && formik.errors.address && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.address}</div>
                        </div>
                      )}
                    </div>
                  </div>


                  {/* Zip code Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Zip-Code</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='Zip-Code'
                        {...formik.getFieldProps('zipCode')}
                        type='text'
                        className={
                          formik.touched.zipCode && formik.errors.zipCode
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.zipCode && formik.errors.zipCode && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.zipCode}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Tax-id Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Tax-ID</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='Tax-ID'
                        {...formik.getFieldProps('taxID')}
                        type='text'
                        className={
                          formik.touched.taxID && formik.errors.taxID
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.taxID && formik.errors.taxID && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.taxID}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Phone Input */}
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone</label>
                    <div className='col-lg-8'>
                      <input
                        placeholder='Phone'
                        {...formik.getFieldProps('phone')}
                        type='text'
                        className={
                          formik.touched.phone && formik.errors.phone
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                        autoComplete='off'
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.phone}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}

              {/* Submit Button */}
              <div className='modal-footer' style={{ height: '50px' }}>
                <div className='d-flex flex-row mb-6'>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                  <div className="px-2" />
                  <div>
                    <button type='button' onClick={() => props.onClose(false)} className='btn btn-secondary'>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}

type UpdatePasswordModalProps = {
  onClose: (created: boolean) => void,
  show: boolean,
  userID: string | null,
}

type UpdatePasswordForm = {
  email?: string
  password?: string
}

type UpdatePasswordFormSchema = {
  [K in keyof Required<UpdatePasswordForm>]: Yup.Schema<UpdatePasswordForm[K]>;
};

export const UpdatePasswordModal = (props: UpdatePasswordModalProps) => {
  const [getUser] = useLazyQuery(QUERY_GET_USER)

  useEffect(() => {
    if (props.userID) {
      getUser({
        variables: {
          id: props.userID,
        },
      }).then((response) => {
        if (response.data) {
          formik.setValues({
            email: response.data.getUser.email,
            password: "",
          })
        }
      })
    }
  }, [props.userID])

  const [updateEmail] = useMutation(MUTATION_UPDATE_EMAIL);
  const [updatePassword] = useMutation(MUTATION_UPDATE_PASSWORD);

  const formik = useFormik<UpdatePasswordForm>({
    initialValues: {} as any as UpdatePasswordForm,
    validationSchema: Yup.object().shape<UpdatePasswordFormSchema>({
      email: Yup.string().email('Incorrect email'),
      password: Yup.string().min(3, 'Minimum 3 symbols'),
    }),
    onSubmit: async (values) => {
      if (!props.userID) return;

      if (values.email) {
        await updateEmail({
          variables: {
            userID: props.userID,
            email: values.email!,
            password: null,
          }
        });
      }

      if (values.password) {
        await updatePassword({
          variables: {
            userID: props.userID,
            newPassword: values.password!,
            currentPassword: null,
          }
        });
      }
      props.onClose(true);
    },
  })

  useEffect(() => {
    formik.resetForm();
  }, [props.show])

  return (
    <Modal
      show={props.show}
      onBackdropClick={() => {
        return props.onClose(false);
      }}
    >
      <div className="modal-content shadow-none">
        <div className="container-xxl">
          <div className="modal-body py-12">
            <form
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >

              {/* Email Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Email</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    type='text'
                    className={
                      formik.touched.email && formik.errors.email
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Password Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Password</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Password'
                    {...formik.getFieldProps('password')}
                    type='text'
                    className={
                      formik.touched.password && formik.errors.password
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <div className='modal-footer' style={{ height: '50px' }}>
                <div className='d-flex flex-row mb-6'>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                  <div className="px-2" />
                  <div>
                    <button type='button' onClick={() => props.onClose(false)} className='btn btn-secondary'>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}
