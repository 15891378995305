import { useState, useEffect, ReactNode, useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import { PageTitle } from '../../../_metronic/layout/core'
import Select, { SingleValue } from 'react-select'
import { AlertType, generateAlert } from '../../helpers/alert'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../modules/auth'
import {
  EncodedFiltersHash,
  VersionedFilter,
  decodeFilters,
  encodeFilters,
} from '../../modules/filters/versioned_filters'
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers'
import CopyToClipboard from "react-copy-to-clipboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import clsx from 'clsx'
import { CampaignType, ConnectionType, DspMethod, GetCampaignQuery, GetDspQuery, PayType, UserRole } from '../../../api/v2/gql/gen/graphql'
import { useFormik } from 'formik'
import { client } from '../../../api/v2/gql/client'
import { MUTATION_ATTACH_CREATIVE, MUTATION_CREATE_CAMPAIGN, MUTATION_DETACH_CREATIVE, MUTATION_UPDATE_CAMPAIGN, QUERY_GET_CAMPAIGN, QUERY_GET_CAMPAIGN_CREATIVES } from '../../../api/v2/gql/campaigns'
import { AdvPicker, AdvPickerResult } from '../../components/AdvPicker'
import { CountryMultiPicker, CountryMultyPickerResult } from '../../components/CountryMultiPicker'
import { RegionMultiPicker, RegionMultiPickerResult } from '../../components/RegionMultiPicker'
import { CityMultiPicker, CityMultiPickerResult } from '../../components/CityMultiPicker'
import { DeviceTypeMultiPicker, DeviceTypeMultiResult } from '../../components/DeviceTypeMultiPicker'
import { DeviceOSMultiPicker, DeviceOSMultiResult } from '../../components/DeviceOSMultiPicker'
import { DeviceBrowserMultiPicker, DeviceBrowserMultiResult } from '../../components/DeviceBrowserMultiPicker'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { MobileCarrierMultiPicker } from '../../components/MobileCarriersMultiPicker'
import { CampaignCategoryMultiPicker } from '../../components/CampaignCategoryMultiPicker'
import { CampaignCategoryTypePicker } from '../../components/CampaignCategoryTypePicker'
import { LanguageMultiPicker } from '../../components/LanguageMultiPicker'
import { MUTATION_CREATE_DSP, MUTATION_UPDATE_DSP, QUERY_GET_DSP } from '../../../api/v2/gql/dsps'

export const CreateEditDSP = () => {
  return (
    <>
      <CreateEditDSPCard />
    </>
  )
}

const macroses = {
  SOURCE_ID: {
    value: "{SOURCE_ID}",
    hint: "",
  },
  SOURCE: {
    value: "{SOURCE}",
    hint: "",
  },
  P: {
    value: "{P}",
    hint: "",
  },
  PLACEMENT: {
    value: "{PLACEMENT}",
    hint: "",
  },
  USER_AGENT: {
    value: "{USER_AGENT}",
    hint: "",
  },
  IP: {
    value: "{IP}",
    hint: "",
  },
  LANG: {
    value: "{LANG}",
    hint: "",
  },
  REF: {
    value: "{REF}",
    hint: "",
  },
  KEYWORDS: {
    value: "{KEYWORDS}",
    hint: "",
  },
  CAT: {
    value: "{CAT}",
    hint: "",
  },
  GEO: {
    value: "{GEO}",
    hint: "",
  },
  REGION: {
    value: "{REGION}",
    hint: "",
  },
  CITY: {
    value: "{CITY}",
    hint: "",
  },
  ISP: {
    value: "{ISP}",
    hint: "",
  },
  SUBS_DATE: {
    value: "{SUBS_DATE}",
    hint: "",
  },
  MCC: {
    value: "{MCC}",
    hint: "",
  },
  MNC: {
    value: "{MNC}",
    hint: "",
  },
  LATITUDE: {
    value: "{LATITUDE}",
    hint: "",
  },
  LONGITUDE: {
    value: "{LONGITUDE}",
    hint: "",
  },
  CID: {
    value: "{CID}",
    hint: "",
  },
  USER_ID: {
    value: "{USER_ID}",
    hint: "",
  },
  DEVICE: {
    value: "{DEVICE}",
    hint: "",
  },
  OS: {
    value: "{OS}",
    hint: "",
  },
  OSV1: {
    value: "{OSV1}",
    hint: "",
  },
  OSV2: {
    value: "{OSV2}",
    hint: "",
  },
  BROWSER: {
    value: "{BROWSER}",
    hint: "",
  },
  BROWSERV1: {
    value: "{BROWSERV1}",
    hint: "",
  },
  BROWSERV2: {
    value: "{BROWSERV2}",
    hint: "",
  },
  DESKTOP: {
    value: "{DESKTOP}",
    hint: "",
  },
  MOBILE: {
    value: "{MOBILE}",
    hint: "",
  },
  WV: {
    value: "{WV}",
    hint: "",
  },
  IS_HUMAN: {
    value: "{IS_HUMAN}",
    hint: "",
  },
  CONNECTION: {
    value: "{CONNECTION}",
    hint: "",
  },
  MOBILE_BRAND: {
    value: "{MOBILE_BRAND}",
    hint: "",
  },
  SITE_ID: {
    value: "{SITE_ID}",
    hint: "",
  },
  GEO_ISO3: {
    value: "{GEO_ISO3}",
    hint: "",
  },
  REQ_ID: {
    value: "{REQ_ID}",
    hint: "",
  },
  WIDTH: {
    value: "{WIDTH}",
    hint: "",
  },
  HEIGHT: {
    value: "{HEIGHT}",
    hint: "",
  }
}

const connectionOptions: { label: string, value: ConnectionType }[] = [
  { value: ConnectionType.Any, label: 'Any' },
  { value: ConnectionType.Wifi, label: 'Wi-Fi' },
  { value: ConnectionType.Mobile, label: 'Mobile' },
]

type TypeOption = {
  value: CampaignType,
  title: string,
  description: string,
  icon: ReactNode,
  allowedPayOptions: string[],
}
const typeOptions: TypeOption[] = [
  {
    value: CampaignType.Pops,
    title: "Pops",
    description: "New tab on click",
    allowedPayOptions: ["cpm", "cpa"],
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-window-stack" viewBox="0 0 16 16">
          <path d="M4.5 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M6 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0" />
          <path d="M12 1a2 2 0 0 1 2 2 2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zM2 12V5a2 2 0 0 1 2-2h9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1m1-4v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8zm12-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2z" />
        </svg>
      </span>
    ),
  },
  {
    value: CampaignType.Push,
    title: "Push",
    description: "Push notification on browser",
    allowedPayOptions: ["cpc", "cpa"],
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-dots" viewBox="0 0 16 16">
          <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
          <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
        </svg>
      </span>
    ),
  },
  {
    value: CampaignType.Banner,
    title: "Banner",
    description: "300x250",
    allowedPayOptions: ["cpm", "cpa"],
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-file-richtext" viewBox="0 0 16 16">
          <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208M5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z" />
          <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
        </svg>
      </span>
    ),
  },
]

type PayOption = {
  value: PayType,
  title: string,
  description: string,
  icon: ReactNode,
}

const payOptions: PayOption[] = [
  {
    value: PayType.Cpm,
    title: "CPM",
    description: "Cost per 1000 impressions",
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
        </svg>
      </span>
    ),
  },
  {
    value: PayType.Cpc,
    title: "CPC",
    description: "Cost per 1 click",
    icon: (
      <span className="svg-icon svg-icon-2x me-6">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-index" viewBox="0 0 16 16">
          <path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435l.106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1M8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5 5 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.6 2.6 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046zm2.094 2.025" />
        </svg>
      </span>
    ),
  }
];

type DSPMethodOption = {
  value: DspMethod,
  title: string,
  description: string,
}

const dspMethodOptions: DSPMethodOption[] = [
  {
    title: "JSON",
    value: DspMethod.Json,
    description: "GET request with 'application/json' header"
  },
  {
    title: "OpenRTB",
    value: DspMethod.Rtb,
    description: "POST request by OpenRTB specification"
  },
  {
    title: "XML",
    value: DspMethod.Xml,
    description: "GET request with 'application/xml' header"
  }
]

type CategoryOption = {
  value: string[]
  title: string
  isBlacklist: boolean,
  icon: string
}

const categoryOptions: CategoryOption[] = [
  {
    value: [],
    title: 'All',
    isBlacklist: false,
    icon: ' bi-soundwave',
  },
  {
    value: ['IAB25', 'IAB25-3'],
    title: 'Mainstream',
    isBlacklist: true,
    icon: ' bi-person-lines-fill',
  },
  {
    value: ['IAB25', 'IAB25-3'],
    title: 'Adult',
    isBlacklist: false,
    icon: ' bi-gender-ambiguous',
  },
]

type OperatorOption = {
  label: string,
  value: string,
}

const operatorOptions: OperatorOption[] = [
  {
    label: '=',
    value: '==',
  },
  {
    label: '<',
    value: '<',
  },
  {
    label: '>',
    value: '>',
  },
  {
    label: '<=',
    value: '<=',
  },
  {
    label: '>=',
    value: '>=',
  },
]


type CreateEditDSPForm = {
  adv: AdvPickerResult,
  blacklistIPRanges?: string,
  blacklistSSP?: string,
  blacklistSourceID?: string,
  method: DspMethod,
  discrepancy: string,
  requestLimitPercentage: string,
  urlWithMacro: string,
  bodyWithMacro: string,
  parsingAdID?: string,
  parsingBannerH?: string,
  parsingBannerW?: string,
  parsingBid?: string,
  parsingBurl?: string,
  parsingCreativeID?: string,
  parsingDesc?: string,
  parsingIconURL?: string,
  parsingImageURL?: string,
  parsingNotificationURL?: string,
  parsingTitle?: string,
  parsingURL?: string,
  dailyLimitUniq: number,
  dailyLimitCls: number,
  dailyLimitCost: number,
  dailyLimitImp: number,
  enabled: boolean,
  name: string,
  payType: PayType | undefined,
  roi: string,
  subsTTL: number,
  totalLimitCls: number,
  totalLimitCost: number,
  totalLimitImp: number,
  connectionType: ConnectionType,
  disableProxy: boolean,
  type: CampaignType | undefined,
  whitelistBrands?: string[],
  categories?: string[],
  categoriesIsBlack?: boolean,
  whitelistCities?: CityMultiPickerResult[],
  whitelistCountries?: CountryMultyPickerResult[],
  whitelistRegions?: RegionMultiPickerResult[],
  whitelistLanguageCodes?: string[],
  whitelistExec?: string,
  whitelistIPRanges?: string,
  whitelistSSP?: string,
  whitelistSourceID?: string,
}

type CreateEditDSPFormSchema = {
  [K in keyof Required<CreateEditDSPForm>]: Yup.Schema<CreateEditDSPForm[K] extends Object ? any : CreateEditDSPForm[K]>;
};


const sharedShape: CreateEditDSPFormSchema = {
  type: Yup.string().oneOf(Object.values(CampaignType)),
  adv: Yup.object(),
  bodyWithMacro: Yup.string(),
  discrepancy: Yup.number(),
  method: Yup.string().oneOf(Object.values(DspMethod)),
  parsingAdID: Yup.string(),
  parsingBannerH: Yup.string(),
  parsingBannerW: Yup.string(),
  parsingBid: Yup.string(),
  parsingBurl: Yup.string(),
  parsingCreativeID: Yup.string(),
  parsingDesc: Yup.string(),
  parsingIconURL: Yup.string(),
  parsingImageURL: Yup.string(),
  parsingNotificationURL: Yup.string(),
  parsingTitle: Yup.string(),
  parsingURL: Yup.string(),
  urlWithMacro: Yup.string().min(0, "URL is too short"),
  requestLimitPercentage: Yup.number(),
  name: Yup.string().min(3, "Name is too short").max(50, "Name is too big"),
  roi: Yup.string().test("must-be-positive", "Roi must be positive", (value) => value ? !Number.isNaN(parseFloat(value)) && parseFloat(value) > 0 : true),
  subsTTL: Yup.number().min(0, "SubsTTL must be positive"),
  dailyLimitUniq: Yup.number().min(0, "Must be positive"),
  dailyLimitCls: Yup.number().min(0, "Must be positive"),
  dailyLimitImp: Yup.number().min(0, "Must be positive"),
  dailyLimitCost: Yup.number().min(0, "Must be positive"),
  totalLimitCls: Yup.number().min(0, "Must be positive"),
  totalLimitCost: Yup.number().min(0, "Must be positive"),
  totalLimitImp: Yup.number().min(0, "Must be positive"),
  enabled: Yup.bool(),
  connectionType: Yup.string().oneOf(Object.values(ConnectionType)),
  disableProxy: Yup.boolean(),
  blacklistIPRanges: Yup.string(),
  blacklistSourceID: Yup.string(),
  blacklistSSP: Yup.string(),
  whitelistBrands: Yup.array(),
  categories: Yup.array(),
  categoriesIsBlack: Yup.boolean(),
  whitelistCities: Yup.array(),
  whitelistCountries: Yup.array(),
  whitelistExec: Yup.string(),
  whitelistIPRanges: Yup.string(),
  whitelistRegions: Yup.array(),
  whitelistLanguageCodes: Yup.array(),
  payType: Yup.string().oneOf(Object.values(PayType)),
  whitelistSourceID: Yup.string(),
  whitelistSSP: Yup.string(),
}

const CreateEditDSPCard = () => {
  const idStr = useParams().id;
  const id = idStr ? parseInt(idStr) : undefined;
  const { currentUser } = useAuth()

  const [exists, setExists] = useState<GetDspQuery['getDSP'] | null>(null);
  const [findExists] = useLazyQuery(QUERY_GET_DSP);

  useEffect(() => {
    const fetch = async () => {
      if (id) {
        const response = await findExists({
          variables: {
            id: id,
          }
        });
        if (response.data) {
          setExists(response.data.getDSP)
        }
      }
    }

    fetch();
  }, [id])

  return (
    <>
      {id ?
        <>
          {exists ?
            <CreateEditDSPFormWidget
              id={id}
              initialValues={{
                enabled: exists.enabled,
                adv: {
                  ...exists.advertiser.user,
                  advertiserProfile: exists.advertiser,
                  // FIXME (marcus): 22.07.24 fix balance requirements
                  balance: {
                    id: "",
                    amount: "",
                  },
                },
                bodyWithMacro: exists.bodyWithMacro ?? '',
                discrepancy: exists.discrepancy?.toString() ?? '',
                method: exists.method ?? null,
                requestLimitPercentage: exists.requestLimitPercentage?.toString() ?? '',
                urlWithMacro: exists.urlWithMacro ?? '',
                parsingAdID: exists.parsingAdID ?? '',
                parsingBannerH: exists.parsingBannerH ?? '',
                parsingBannerW: exists.parsingBannerW ?? '',
                parsingBid: exists.parsingBid ?? '',
                parsingBurl: exists.parsingBURL ?? '',
                parsingCreativeID: exists.parsingCreativeID ?? '',
                parsingDesc: exists.parsingDesc ?? '',
                parsingIconURL: exists.parsingIconURL ?? '',
                parsingImageURL: exists.parsingImageURL ?? '',
                parsingNotificationURL: exists.parsingNotificationURL ?? '',
                parsingTitle: exists.parsingTitle ?? '',
                parsingURL: exists.parsingURL ?? '',
                dailyLimitUniq: exists.limitsFilter?.dailyLimitUniq ?? 0,
                dailyLimitCls: exists.limitsFilter?.dailyLimitCls ?? 0,
                dailyLimitCost: exists.limitsFilter?.dailyLimitCost ?? 0,
                dailyLimitImp: exists.limitsFilter?.dailyLimitImp ?? 0,
                name: exists.name,
                payType: exists.payType,
                roi: exists.roi,
                subsTTL: exists.subsTTL ?? 0,
                connectionType: exists.brandsFilter?.connectionType ?? ConnectionType.Any,
                disableProxy: exists.brandsFilter?.disableProxy ?? true,
                totalLimitCls: exists.limitsFilter?.totalLimitCls ?? 0,
                totalLimitCost: exists.limitsFilter?.totalLimitCost ?? 0,
                totalLimitImp: exists.limitsFilter?.totalLimitImp ?? 0,
                type: exists.type,
                blacklistIPRanges: exists.ipRangeFilter?.blacklistIPRanges?.join(','),
                whitelistIPRanges: exists.ipRangeFilter?.whitelistIPRanges?.join(','),
                blacklistSourceID: exists.sourceFilter?.blacklistSourceID?.join(','),
                blacklistSSP: exists.sourceFilter?.blacklistSSP?.join(','),
                whitelistExec: exists.devicesFilter?.whitelistExec ?? '',
                whitelistBrands: exists.brandsFilter?.whitelistBrands ?? undefined,
                categories: ((exists.categoriesFilter?.whitelistCategories?.length ?? 0) > 0 ? exists.categoriesFilter?.whitelistCategories : exists.categoriesFilter?.blacklistCategories) ?? undefined,
                categoriesIsBlack: (exists.categoriesFilter?.blacklistCategories?.length ?? 0) > 0,
                whitelistRegions: exists.regionsFilter?.whitelistRegions ?? undefined,
                whitelistCountries: exists.countriesFilter?.whitelistCountries ?? undefined,
                whitelistCities: exists.citiesFilter?.whitelistCities ?? undefined,
                whitelistLanguageCodes: exists.languageFilter?.whitelistLanguageCodes ?? undefined,
                whitelistSourceID: exists.sourceFilter?.whitelistSourceID?.join(','),
                whitelistSSP: exists.sourceFilter?.whitelistSSP?.join(','),
              }}
            /> :
            <>Loading...</>}
        </> :
        <>
          <CreateEditDSPFormWidget
            initialValues={{
              adv: {
                ...currentUser!,
                // FIXME (marcus): 22.07.24 fix balance requirements
                balance: {
                  id: "",
                  amount: "",
                },
              },
              bodyWithMacro: "",
              discrepancy: '0.2',
              method: DspMethod.Json,
              requestLimitPercentage: '',
              urlWithMacro: "",
              parsingAdID: "",
              parsingBannerH: "",
              parsingBannerW: "",
              parsingBid: "",
              parsingBurl: "",
              parsingCreativeID: "",
              parsingDesc: "",
              parsingIconURL: "",
              parsingImageURL: "",
              parsingNotificationURL: "",
              parsingTitle: "",
              parsingURL: "",
              dailyLimitUniq: 0,
              dailyLimitCls: 0,
              dailyLimitCost: 0,
              dailyLimitImp: 0,
              enabled: true,
              name: "",
              payType: undefined,
              roi: "0.4",
              subsTTL: 0,
              totalLimitCls: 0,
              totalLimitCost: 0,
              totalLimitImp: 0,
              connectionType: ConnectionType.Any,
              disableProxy: false,
              type: undefined,
              blacklistIPRanges: '',
              blacklistSourceID: '',
              blacklistSSP: '',
              whitelistBrands: [],
              categories: [],
              categoriesIsBlack: false,
              whitelistCities: [],
              whitelistCountries: [],
              whitelistExec: '',
              whitelistIPRanges: '',
              whitelistRegions: [],
              whitelistLanguageCodes: [],
              whitelistSourceID: '',
              whitelistSSP: '',
            }}
          />
        </>}
    </>
  )
}

const CreateEditDSPFormWidget = ({ initialValues, id }: { initialValues: CreateEditDSPForm, id?: number }) => {
  const { currentUser } = useAuth()
  const intl = useIntl()
  const navigate = useNavigate();

  const [customCategories, setCustomCategories] = useState<boolean>(categoryOptions.findIndex(e => JSON.stringify(e.value) === JSON.stringify(initialValues.categories)) === -1);
  const accordionRefs = {
    limits: useRef<HTMLButtonElement>(null),
    admin: useRef<HTMLButtonElement>(null),
    geoLanguage: useRef<HTMLButtonElement>(null),
    device: useRef<HTMLButtonElement>(null),
    connection: useRef<HTMLButtonElement>(null),
    pushSubs: useRef<HTMLButtonElement>(null),
    parsing: useRef<HTMLButtonElement>(null),
    source: useRef<HTMLButtonElement>(null),
    trafficSettings: useRef<HTMLButtonElement>(null),
    aiSetting: useRef<HTMLButtonElement>(null),
    other: useRef<HTMLButtonElement>(null),
  }

  const fieldsAccordions: {
    [K in keyof Required<CreateEditDSPForm>]: React.RefObject<any> | null;
  } = {
    name: null,
    adv: null,
    type: null,
    payType: null,
    enabled: null,
    categories: null,
    categoriesIsBlack: null,
    dailyLimitCls: accordionRefs.limits,
    dailyLimitCost: accordionRefs.limits,
    dailyLimitImp: accordionRefs.limits,
    totalLimitCls: accordionRefs.limits,
    totalLimitCost: accordionRefs.limits,
    totalLimitImp: accordionRefs.limits,
    dailyLimitUniq: accordionRefs.limits,
    roi: accordionRefs.admin,
    urlWithMacro: null,
    bodyWithMacro: null,
    discrepancy: accordionRefs.trafficSettings,
    method: null,
    requestLimitPercentage: accordionRefs.trafficSettings,
    parsingAdID: accordionRefs.parsing,
    parsingBannerH: accordionRefs.parsing,
    parsingBannerW: accordionRefs.parsing,
    parsingBid: accordionRefs.parsing,
    parsingBurl: accordionRefs.parsing,
    parsingCreativeID: accordionRefs.parsing,
    parsingDesc: accordionRefs.parsing,
    parsingIconURL: accordionRefs.parsing,
    parsingImageURL: accordionRefs.parsing,
    parsingNotificationURL: accordionRefs.parsing,
    parsingTitle: accordionRefs.parsing,
    parsingURL: accordionRefs.parsing,
    whitelistCountries: accordionRefs.geoLanguage,
    whitelistRegions: accordionRefs.geoLanguage,
    whitelistCities: accordionRefs.geoLanguage,
    whitelistLanguageCodes: accordionRefs.geoLanguage,
    whitelistExec: accordionRefs.device,
    subsTTL: accordionRefs.pushSubs,
    whitelistSourceID: accordionRefs.source,
    whitelistSSP: accordionRefs.source,
    blacklistSSP: accordionRefs.source,
    blacklistSourceID: accordionRefs.source,
    blacklistIPRanges: accordionRefs.connection,
    whitelistIPRanges: accordionRefs.connection,
    connectionType: accordionRefs.connection,
    disableProxy: accordionRefs.connection,
    whitelistBrands: accordionRefs.connection,
  }

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        await Yup.object().shape(sharedShape).validate(values);
      } catch (e) {
        if (e instanceof Yup.ValidationError && e.params?.path) {
          const accordion = fieldsAccordions[e.params?.path as string];
          if (accordion && accordion.current) {
            if ((accordion.current.className as string).indexOf('collapsed') > -1) {
              accordion.current?.click();
            }
          }
          formik.setFieldError(e.params.path as string, e.message);
          return;
        }
      }

      try {
        if (!id) {
          const response = await client.mutate({
            mutation: MUTATION_CREATE_DSP,
            variables: {
              input: {
                enabled: true,
                advID: values.adv!.advertiserProfile.advertiserID,
                method: values.method!,
                bodyWithMacro: values.bodyWithMacro!,
                discrepancy: isNaN(parseFloat(values.discrepancy!)) ? 0 : parseFloat(values.discrepancy),
                parsingAdID: values.parsingAdID!,
                parsingBannerH: values.parsingBannerH!,
                parsingBannerW: values.parsingBannerW!,
                parsingBid: values.parsingBid!,
                parsingBurl: values.parsingBurl!,
                parsingCreativeID: values.parsingCreativeID!,
                parsingDesc: values.parsingDesc!,
                parsingIconURL: values.parsingIconURL!,
                parsingImageURL: values.parsingImageURL!,
                parsingNotificationURL: values.parsingNotificationURL!,
                parsingTitle: values.parsingTitle!,
                parsingURL: values.parsingURL!,
                requestLimitPercentage: isNaN(parseFloat(values.requestLimitPercentage!)) ? 0 : parseFloat(values.requestLimitPercentage),
                urlWithMacro: values.urlWithMacro!,
                blacklistSourceID: values.blacklistSourceID!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistSSP: values.blacklistSSP!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistIPRanges: values.blacklistIPRanges!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                dailyLimitUniq: values.dailyLimitUniq,
                dailyLimitCls: values.dailyLimitCls!,
                dailyLimitCost: values.dailyLimitCost!,
                dailyLimitImp: values.dailyLimitImp!,
                totalLimitCls: values.totalLimitCls!,
                totalLimitCost: values.totalLimitCost!,
                totalLimitImp: values.totalLimitImp!,
                name: values.name!,
                payType: values.payType!,
                roi: values.roi!.toString(),
                subsTTL: values.subsTTL!,
                type: values.type!,
                connectionType: values.connectionType,
                disableProxy: values.disableProxy,
                whitelistExec: values.whitelistExec,
                whitelistSourceID: values.whitelistSourceID!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistSSP: values.whitelistSSP!.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistIPRanges: values.whitelistIPRanges?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistBrands: values.whitelistBrands?.map((e) => e.trim()).filter(e => e.length > 0),
                whitelistCategories: values.categoriesIsBlack ? [] : values.categories?.map((e) => e.trim()).filter(e => e.length > 0),
                blacklistCategories: values.categoriesIsBlack ? values.categories?.map((e) => e.trim()).filter(e => e.length > 0) : [],
                whitelistCitiesIDs: values.whitelistCities?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistCountriesIDs: values.whitelistCountries?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistRegionsIDs: values.whitelistRegions?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistLanguageCodes: values.whitelistLanguageCodes?.map((e) => e.trim()).filter(e => e.length > 0),
              }
            },
          });
          if (response.data) {
            navigate(`/dsps/${response.data.createDSPFull.id}`);
          }
        } else {
          const response = await client.mutate({
            mutation: MUTATION_UPDATE_DSP,
            variables: {
              id: id!,
              input: {
                enabled: values.enabled!,
                type: values.type!,
                method: values.method!,
                bodyWithMacro: values.bodyWithMacro!,
                discrepancy: isNaN(parseFloat(values.discrepancy!)) ? 0 : parseFloat(values.discrepancy),
                parsingAdID: values.parsingAdID!,
                parsingBannerH: values.parsingBannerH!,
                parsingBannerW: values.parsingBannerW!,
                parsingBid: values.parsingBid!,
                parsingBurl: values.parsingBurl!,
                parsingCreativeID: values.parsingCreativeID!,
                parsingDesc: values.parsingDesc!,
                parsingIconURL: values.parsingIconURL!,
                parsingImageURL: values.parsingImageURL!,
                parsingNotificationURL: values.parsingNotificationURL!,
                parsingTitle: values.parsingTitle!,
                parsingURL: values.parsingURL!,
                requestLimitPercentage: isNaN(parseFloat(values.requestLimitPercentage!)) ? 0 : parseFloat(values.requestLimitPercentage),
                urlWithMacro: values.urlWithMacro!,
                dailyLimitUniq: values.dailyLimitUniq,
                dailyLimitCls: values.dailyLimitCls,
                dailyLimitCost: values.dailyLimitCost,
                dailyLimitImp: values.dailyLimitImp,
                totalLimitCls: values.totalLimitCls,
                totalLimitCost: values.totalLimitCost,
                totalLimitImp: values.totalLimitImp,
                connectionType: values.connectionType,
                disableProxy: values.disableProxy,
                blacklistSSP: values.blacklistSSP?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistSourceID: values.blacklistSourceID?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                blacklistIPRanges: values.blacklistIPRanges?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                name: values.name,
                payType: values.payType,
                roi: values.roi ? values.roi.toString() : null,
                subsTTL: values.subsTTL,
                advID: values.adv?.advertiserProfile.advertiserID ?? currentUser?.advertiserProfile.advertiserID,
                whitelistExec: values.whitelistExec,
                whitelistSSP: values.whitelistSSP?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistSourceID: values.whitelistSourceID?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistIPRanges: values.whitelistIPRanges?.split(/[\n,]/).map((e) => e.trim()).filter(e => e.length > 0),
                whitelistBrands: values.whitelistBrands?.map((e) => e.trim()).filter(e => e.length > 0),
                whitelistCategories: values.categoriesIsBlack ? [] : values.categories?.map((e) => e.trim()).filter(e => e.length > 0),
                blacklistCategories: values.categoriesIsBlack ? values.categories?.map((e) => e.trim()).filter(e => e.length > 0) : [],
                whitelistCitiesIDs: values.whitelistCities?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistCountriesIDs: values.whitelistCountries?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistRegionsIDs: values.whitelistRegions?.map((e) => e.id.trim()).filter(e => e.length > 0),
                whitelistLanguageCodes: values.whitelistLanguageCodes?.map((e) => e.trim()).filter(e => e.length > 0),
              },
            },
          })
        }
        generateAlert('Successfully', AlertType.SUCCESS)
      } catch (e) {
        if (e instanceof ApolloError && e.graphQLErrors && e.message) {
          generateAlert(e.message, AlertType.ERROR);
        } else {
          generateAlert('Unknown error', AlertType.ERROR);
        }
      }
    }
  })

  useEffect(() => {
    if (formik.values.whitelistCountries?.length === 0) {
      formik.setFieldValue('whitelistRegions', []);
      formik.setFieldValue('whitelistCities', []);
    }
  }, [formik.values.whitelistCountries])

  useEffect(() => {
    if (formik.values.whitelistRegions?.length === 0) {
      formik.setFieldValue('whitelistCities', []);
    }
  }, [formik.values.whitelistRegions])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: id ? 'Edit DSP' : 'Create DSP' })}</PageTitle>
      <div className='col-xl-12'>
        <div className='card card-xxl-stretch mb-5 mb-xl-8'>
          <div className='card-body py-3 pt-8 container'>
            <form onSubmit={formik.handleSubmit} noValidate>
              {/* NAME */}
              <div className='mb-5'>
                <label className="required form-label">Name</label>
                <input
                  type="text"
                  className={
                    formik.touched.name && formik.errors.name
                      ? 'form-control form-control-solid is-invalid'
                      : 'form-control form-control-solid'
                  }
                  {...formik.getFieldProps('name')}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.name}</div>
                  </div>
                )}
              </div>

              {/* ADVERTISER */}
              {currentUser?.role === UserRole.Admin && <>
                <div className="mb-5">
                  <label htmlFor="adv-id" className="required form-label">Advertiser</label>
                  <AdvPicker value={formik.values.adv} onChange={(u) => formik.setFieldValue('adv', u)} />

                  {formik.touched.adv && formik.errors.adv && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.adv.toString()}</div>
                    </div>
                  )}
                </div>
              </>}

              {/* AD TYPE */}
              {formik.values.name && formik.values.adv && (<>
                <label className="form-label">Ad Type</label>
                <div className='row'>
                  {typeOptions.map((e) => {
                    return (
                      <div
                        className='col-lg-4'
                        key={e.value}
                        onClick={(_) => {
                          formik.setFieldValue('type', e.value);
                        }}
                      >
                        <label
                          className={clsx(
                            'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5',
                            { 'border-primary': e.value === formik.values.type },
                          )}
                        >
                          {e.icon}
                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>{e.title}</span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              {e.description}
                            </span>
                          </span>
                        </label>
                      </div>
                    );
                  })}

                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.type}</div>
                    </div>
                  )}
                </div>
              </>)}

              {/* PAY TYPE */}
              {formik.values.type && (
                <>
                  <label className="form-label">Pay Type</label>
                  <div className='row'>
                    {payOptions.filter((e) => {
                      const type = typeOptions.find((e) => e.value === formik.values.type);
                      if (!type) return false;
                      return type!.allowedPayOptions.indexOf(e.value) > -1;
                    }).map((e) => {
                      return (
                        <div className='col-lg-6' onClick={(_) => {
                          formik.setFieldValue('payType', e.value);
                          formik.setFieldValue('convTrack', e.value == PayType.Cpa);
                        }}
                          key={e.value}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5',
                              { 'border-primary': e.value === formik.values.payType },
                            )} >
                            {e.icon}

                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>{e.title}</span>
                              <span className='text-gray-400 fw-bold fs-6'>
                                {e.description}
                              </span>
                            </span>
                          </label>
                        </div>
                      );
                    })}

                    {formik.touched.payType && formik.errors.payType && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.payType}</div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* DSP METHOD */}
              {formik.values.type && (
                <>
                  <label className="form-label">Method</label>
                  <div className='row'>
                    {dspMethodOptions.map((e) => {
                      return (
                        <div className='col-lg-4' onClick={(_) => {
                          formik.setFieldValue('method', e.value);
                        }}
                          key={e.value}
                        >
                          <label
                            className={clsx(
                              'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5',
                              { 'border-primary': e.value === formik.values.method },
                            )} >
                            {/* {e.icon} */}

                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>{e.title}</span>
                              <span className='text-gray-400 fw-bold fs-6'>
                                {e.description}
                              </span>
                            </span>
                          </label>
                        </div>
                      );
                    })}

                    {formik.touched.method && formik.errors.method && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.method}</div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* Whitelist categories */}
              {formik.values.payType && (
                <>
                  <label className='form-label'>Category</label>
                  <div
                    className='grid-container'
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, 200px) minmax(200px, max-content)',
                      gap: '10px',
                    }}
                  >
                    {categoryOptions.map((e) => (
                      <div
                        key={e.title}
                        className={`grid-item rounded-3`}
                        onClick={(_) => {
                          setCustomCategories(false);
                          formik.setFieldValue('categoriesIsBlack', e.isBlacklist);
                          formik.setFieldValue('categories', e.value);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <label
                          className={clsx(
                            'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center',
                            { 'border-primary': JSON.stringify(formik.values.categories) === JSON.stringify(e.value) && formik.values.categoriesIsBlack === e.isBlacklist && !customCategories },
                          )}
                          style={{ height: '70%' }}
                        >
                          <KTIcon iconName={e.icon} className='fs-3x me-5' />

                          <div className='text-block' style={{ flexGrow: 1 }}>
                            <span className='text-dark fw-bolder d-block fs-4'>{e.title}</span>
                          </div>
                        </label>
                      </div>
                    ))}

                    <div
                      className={`grid-item rounded-3`}
                      onClick={(_) => {
                        formik.setFieldValue('categories', []);
                        setCustomCategories(true);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <label
                        className={clsx(
                          'btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center',
                          { 'border-primary': customCategories },
                        )}
                        style={{ height: '70%' }}
                      >
                        <KTIcon iconName='address-book' className='fs-3x me-5' />

                        <div className='text-block' style={{ flexGrow: 1 }}>
                          <span className='text-dark fw-bolder d-block fs-4'>Specific IAB category</span>
                        </div>
                      </label>
                    </div>

                    {formik.touched.categories && formik.errors.categories && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.categories}</div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* Request URL */}
              <div className="mb-5 col-md-12 col-12">
                <label htmlFor="d_requrl" className="form-label">Request URL</label>
                <input type="text" id="d_requrl" name="d_requrl" placeholder={(() => {
                  return 'Enter request URL';
                })()} className="form-control form-control-solid"
                  value={formik.values.urlWithMacro} onChange={(e) => {
                    formik.setFieldValue("urlWithMacro", e.target.value);
                  }} />

                {formik.touched.urlWithMacro && formik.errors.urlWithMacro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.urlWithMacro}</div>
                  </div>
                )}

                {Object.keys(macroses).map((key) => {
                  return (<>
                    <OverlayTrigger key={key} overlay={
                      <Tooltip>{macroses[key].hint}</Tooltip>
                    }>
                      <a
                        className={clsx(
                          'badge cursor-pointer fs-8 fw-bold my-2 py-3 px-4 me-2',
                          { 'badge-light-success': formik.values.urlWithMacro.indexOf(macroses[key].value) > -1 },
                          { 'badge-light-primary': formik.values.urlWithMacro.indexOf(macroses[key].value) === -1 }
                        )}
                        onClick={(e) => {
                          e.preventDefault()
                          if (formik.values.urlWithMacro.indexOf(macroses[key].value) > -1) {
                            formik.setFieldValue('urlWithMacro', formik.values.urlWithMacro.replaceAll(macroses[key].value, ""))
                          } else {
                            formik.setFieldValue('urlWithMacro', `${formik.values.urlWithMacro}${macroses[key].value}`);
                          }
                        }}
                      >
                        {macroses[key].value}
                      </a>
                    </OverlayTrigger>
                  </>)
                })}
              </div>

              {/* Request Body */}
              <div className="mb-5 col-md-12 col-12">
                <label htmlFor="d_reqbody" className="form-label">Request Body</label>

                <textarea id="d_reqbody" name="Request body" className="form-control form-control-solid"
                  placeholder="Please enter request body"
                  value={formik.values.bodyWithMacro} onChange={(e) => {
                    formik.setFieldValue('bodyWithMacro', e.target.value);
                  }} />

                {formik.touched.bodyWithMacro && formik.errors.bodyWithMacro && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.bodyWithMacro}</div>
                  </div>
                )}
                {Object.keys(macroses).map((key) => {
                  return (<>
                    <OverlayTrigger key={key} overlay={
                      <Tooltip>{macroses[key].hint}</Tooltip>
                    }>
                      <a
                        className={clsx(
                          'badge cursor-pointer fs-8 fw-bold my-2 py-3 px-4 me-2',
                          { 'badge-light-success': formik.values.bodyWithMacro.indexOf(macroses[key].value) > -1 },
                          { 'badge-light-primary': formik.values.bodyWithMacro.indexOf(macroses[key].value) === -1 }
                        )}
                        onClick={(e) => {
                          e.preventDefault()
                          if (formik.values.bodyWithMacro.indexOf(macroses[key].value) > -1) {
                            formik.setFieldValue('bodyWithMacro', formik.values.bodyWithMacro.replaceAll(macroses[key].value, ""))
                          } else {
                            formik.setFieldValue('bodyWithMacro', `${formik.values.bodyWithMacro}${macroses[key].value}`);
                          }
                        }}
                      >
                        {macroses[key].value}
                      </a>
                    </OverlayTrigger>
                  </>)
                })}
              </div>

              {/* Whitelist categories custom selector */}

              {customCategories && (
                <div className='mb-5'>
                  <label className="form-label">Specific categories</label>
                  <CampaignCategoryMultiPicker value={formik.values.categories?.map((e) => {
                    return { id: e }
                  }) ?? []} onChange={(e) => {
                    formik.setFieldValue('categories', Array.from(e.values()).map(e => e.id))
                  }} />

                  {((formik.values.categories?.length ?? 0) > 0) && (
                    <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                      <label className="form-label me-5 mt-2" htmlFor="device_webview">
                        Blacklist
                      </label>
                      <input className="form-check-input h-20px w-30px" type="checkbox" id="device_webview"
                        checked={formik.values.categoriesIsBlack} onChange={(e) => {
                          formik.setFieldValue('categoriesIsBlack', !formik.values.categoriesIsBlack);
                        }} />
                    </div>
                  )}
                </div>
              )}

              <div className='mb-5'>
                <div className='accordion' id='kt_accordion_1'>
                  {/* Parsing */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button fs-4 fw-bold'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_44_body_parsing'
                        aria-expanded='true'
                        aria-controls='kt_accordion_44_body_parsing'
                        ref={accordionRefs.parsing}
                      >
                        Parsing
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_44_body_parsing'
                      className='accordion-collapse show'
                      aria-labelledby='kt_accordion_44_header_parsing'
                      data-bs-parent='#kt_accordion_44'>
                      <div className='accordion-body container'>
                        {(formik.values.type === CampaignType.Pops && (formik.values.method === DspMethod.Json || formik.values.method === DspMethod.Xml)) && (<>
                          <div className='row mt-5'>
                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_bid" className="form-label">Parsing bid</label>
                              <input type="text" id="d_parsing_bid" name="d_parsing_bid" className="form-control form-control-solid"
                                value={formik.values.parsingBid} onChange={(e) => {
                                  formik.setFieldValue('parsingBid', e.target.value);
                                }} />

                              {formik.touched.parsingBid && formik.errors.parsingBid && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingBid}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_url" className="form-label">Parsing URL</label>
                              <input type="text" id="d_parsing_url" name="d_parsing_url" className="form-control form-control-solid"
                                value={formik.values.parsingURL} onChange={(e) => {
                                  formik.setFieldValue('parsingURL', e.target.value);
                                }} />

                              {formik.touched.parsingURL && formik.errors.parsingURL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingURL}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>)}
                        {(formik.values.type === CampaignType.Push && (formik.values.method === DspMethod.Json || formik.values.method === DspMethod.Xml)) && (<>
                          <div className='row mt-5'>
                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_bid" className="form-label">Parsing bid</label>
                              <input type="text" id="d_parsing_bid" name="d_parsing_bid" className="form-control form-control-solid"
                                value={formik.values.parsingBid} onChange={(e) => {
                                  formik.setFieldValue('parsingBid', e.target.value);
                                }} />

                              {formik.touched.parsingBid && formik.errors.parsingBid && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingBid}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_url" className="form-label">Parsing URL</label>
                              <input type="text" id="d_parsing_url" name="d_parsing_url" className="form-control form-control-solid"
                                value={formik.values.parsingURL} onChange={(e) => {
                                  formik.setFieldValue('parsingURL', e.target.value);
                                }} />

                              {formik.touched.parsingURL && formik.errors.parsingURL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingURL}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_icon_url" className="form-label">Parsing icon URL</label>
                              <input type="text" id="d_parsing_icon_url" name="d_parsing_icon_url" className="form-control form-control-solid"
                                value={formik.values.parsingIconURL} onChange={(e) => {
                                  formik.setFieldValue('parsingIconURL', e.target.value);
                                }} />

                              {formik.touched.parsingIconURL && formik.errors.parsingIconURL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingIconURL}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_image_url" className="form-label">Parsing image URL</label>
                              <input type="text" id="d_parsing_image_url" name="d_parsing_image_url" className="form-control form-control-solid"
                                value={formik.values.parsingImageURL} onChange={(e) => {
                                  formik.setFieldValue('parsingImageURL', e.target.value);
                                }} />

                              {formik.touched.parsingImageURL && formik.errors.parsingImageURL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingImageURL}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>)}
                        {(formik.values.type === CampaignType.Banner || formik.values.method === DspMethod.Rtb) && (<>
                          <div className='row mt-5'>
                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_bid" className="form-label">Parsing bid</label>
                              <input type="text" id="d_parsing_bid" name="d_parsing_bid" className="form-control form-control-solid"
                                value={formik.values.parsingBid} onChange={(e) => {
                                  formik.setFieldValue('parsingBid', e.target.value);
                                }} />

                              {formik.touched.parsingBid && formik.errors.parsingBid && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingBid}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_url" className="form-label">Parsing URL</label>
                              <input type="text" id="d_parsing_url" name="d_parsing_url" className="form-control form-control-solid"
                                value={formik.values.parsingURL} onChange={(e) => {
                                  formik.setFieldValue('parsingURL', e.target.value);
                                }} />

                              {formik.touched.parsingURL && formik.errors.parsingURL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingURL}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_nurl" className="form-label">Parsing notification URL</label>
                              <input type="text" id="d_parsing_nurl" name="d_parsing_nurl" className="form-control form-control-solid"
                                value={formik.values.parsingNotificationURL} onChange={(e) => {
                                  formik.setFieldValue('parsingNotificationURL', e.target.value);
                                }} />

                              {formik.touched.parsingNotificationURL && formik.errors.parsingNotificationURL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingNotificationURL}</div>
                                </div>
                              )}
                            </div>

                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="d_parsing_burl" className="form-label">Parsing BURL</label>
                              <input type="text" id="d_parsing_burl" name="d_parsing_burl" className="form-control form-control-solid"
                                value={formik.values.parsingBurl} onChange={(e) => {
                                  formik.setFieldValue('parsingBurl', e.target.value);
                                }} />

                              {formik.touched.parsingBurl && formik.errors.parsingBurl && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.parsingBurl}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </>)}
                      </div>
                    </div>
                  </div>
                  {/* Traffic settings */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_40_header_traffic'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_40_body_traffic'
                        aria-expanded='false'
                        aria-controls='kt_accordion_40_body_traffic'
                        ref={accordionRefs.trafficSettings}
                      >
                        Traffic settings
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_40_body_traffic'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_40_header_traffic'
                      data-bs-parent='#kt_accordion_40'
                    >
                      <div className='accordion-body'>
                        <div className="mb-5 col-md-4 col-12">
                          <label htmlFor="d_req_limit" className="form-label">Request limit %</label>
                          <input type="text" id="d_req_limit" name="d_req_limit" className="form-control form-control-solid"
                            value={isNaN(parseFloat(formik.values.requestLimitPercentage)) ? '' : formik.values.requestLimitPercentage} min={0} onChange={(e) => {
                              formik.setFieldValue('requestLimitPercentage', e.target.value.replaceAll(',', '.'));
                            }} />
                        </div>
                        <div className="mb-5 col-md-8 col-12" />
                        <div className="mb-5 col-md-4 col-12">
                          <label htmlFor="d_discrepancy" className="form-label">Discrepancy %</label>
                          <input type="text" id="d_discrepancy" name="d_discrepancy" className="form-control form-control-solid"
                            value={isNaN(parseFloat(formik.values.discrepancy)) ? '' : formik.values.discrepancy} min={0} onChange={(e) => {
                              formik.setFieldValue('discrepancy', e.target.value.replaceAll(',', '.'));
                            }} />
                        </div>
                        <div className="mb-5 col-md-8 col-12" />
                      </div>
                    </div>
                  </div>
                  {/* LIMITS */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        aria-expanded='false'
                        data-bs-target='#kt_accordion_1_body_limits'
                        aria-controls='kt_accordion_1_body_limits'
                        ref={accordionRefs.limits}
                      >
                        Limits
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_limits'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_limits'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body container'>
                        <div className="row mt-5">
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_uniq" className="form-label">Capping</label>
                            <input type="text" id="d_limit_uniq" name="d_limit_uniq" className="form-control form-control-solid"
                              value={formik.values.dailyLimitUniq === 0 ? '' : formik.values.dailyLimitUniq} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitUniq', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />
                          </div>
                          <div className="mb-5 col-md-8 col-12" />
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_cls" className="form-label">Daily Limit Clicks</label>
                            <input type="text" id="d_limit_cls" name="d_limit_cls" className="form-control form-control-solid"
                              value={formik.values.dailyLimitCls === 0 ? '' : formik.values.dailyLimitCls} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitCls', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.dailyLimitCls && formik.errors.dailyLimitCls && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.dailyLimitCls}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_imp" className="form-label">Daily Limit Impressions</label>
                            <input type="text" id="d_limit_imp" name="d_limit_imp" className="form-control form-control-solid"
                              value={formik.values.dailyLimitImp === 0 ? '' : formik.values.dailyLimitImp} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitImp', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.dailyLimitImp && formik.errors.dailyLimitImp && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.dailyLimitImp}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_limit_cost" className="form-label">Daily Limit Cost</label>
                            <input type="text" id="d_limit_cost" name="d_limit_cost" className="form-control form-control-solid"
                              value={formik.values.dailyLimitCost === 0 ? '' : formik.values.dailyLimitCost} min={0} onChange={(e) => {
                                formik.setFieldValue('dailyLimitCost', isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value));
                              }} />

                            {formik.touched.dailyLimitCost && formik.errors.dailyLimitCost && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.dailyLimitCost}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="t_limit_cls" className="form-label">Total Limit Clicks</label>
                            <input type="text" id="t_limit_cls" name="t_limit_cls" className="form-control form-control-solid"
                              value={formik.values.totalLimitCls === 0 ? '' : formik.values.totalLimitCls} min={0} onChange={(e) => {
                                formik.setFieldValue('totalLimitCls', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.totalLimitCls && formik.errors.totalLimitCls && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.totalLimitCls}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="t_limit_imp" className="form-label">Total Limit Impressions</label>
                            <input type="text" id="t_limit_imp" name="t_limit_imp" className="form-control form-control-solid"
                              value={formik.values.totalLimitImp === 0 ? '' : formik.values.totalLimitImp} min={0} onChange={(e) => {
                                formik.setFieldValue('totalLimitImp', isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value));
                              }} />

                            {formik.touched.totalLimitImp && formik.errors.totalLimitImp && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.totalLimitImp}</div>
                              </div>
                            )}
                          </div>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="t_limit_cost" className="form-label">Total Limit Cost</label>
                            <input type="text" id="t_limit_cost" name="t_limit_cost" className="form-control form-control-solid"
                              value={formik.values.totalLimitCost === 0 ? '' : formik.values.totalLimitCost} min={0} onChange={(e) => {
                                formik.setFieldValue('totalLimitCost', isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value));
                              }} />

                            {formik.touched.totalLimitCost && formik.errors.totalLimitCost && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.totalLimitCost}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* ADMIN */}
                  {currentUser?.role === UserRole.Admin &&
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='kt_accordion_1_header_admin'>
                        <button
                          className='accordion-button fs-4 fw-bold collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#kt_accordion_1_body_admin'
                          aria-expanded='false'
                          aria-controls='kt_accordion_1_body_admin'
                          ref={accordionRefs.admin}
                        >
                          Admin
                        </button>
                      </h2>
                      <div
                        id='kt_accordion_1_body_admin'
                        className='accordion-collapse collapse'
                        aria-labelledby='kt_accordion_1_header_admin'
                        data-bs-parent='#kt_accordion_1'
                      >
                        <div className='accordion-body'>
                          <div className="mb-5 col-md-4 col-12">
                            <label htmlFor="d_roi" className="form-label">ROI</label>
                            <input type="text" id="d_roi" name="d_roi" className="form-control form-control-solid"
                              value={formik.values.roi} onChange={(e) => {
                                formik.setFieldValue('roi', e.target.value.replaceAll(',', '.'));
                              }} />

                            {formik.touched.roi && formik.errors.roi && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.roi}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* GEO & LANGUAGE */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_geo'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_geo'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_get'
                        ref={accordionRefs.geoLanguage}
                      >
                        Geo & Language
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_geo'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_geo'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <label htmlFor="countries" className="form-label">Country</label>
                        <CountryMultiPicker value={formik.values.whitelistCountries ?? null} onChange={(values) => {
                          formik.setFieldValue('whitelistCountries', values);
                        }} />

                        {(formik.values.whitelistCountries?.length ?? 0) > 0 &&
                          <div className="mt-5">
                            <label htmlFor="regions" className="form-label">Regions</label>
                            <RegionMultiPicker
                              countryIDs={formik.values.whitelistCountries!.map(e => e.id)}
                              value={formik.values.whitelistRegions ?? []}
                              onChange={(e) => formik.setFieldValue('whitelistRegions', Array.from(e.values()))}
                            />
                          </div>
                        }

                        {(formik.values.whitelistRegions?.length ?? 0) > 0 &&
                          <div className="mt-5">
                            <label htmlFor="cities" className="form-label">Cities</label>
                            <CityMultiPicker
                              regionIDs={formik.values.whitelistRegions!.map((e) => e.id)}
                              value={formik.values.whitelistCities ?? []}
                              onChange={(e) => formik.setFieldValue('whitelistCities', Array.from(e.values()))}
                            />
                          </div>
                        }


                        <div className='mt-5'>
                          <label htmlFor='languages' className='form-label'>Languages</label>
                          <LanguageMultiPicker
                            value={formik.values.whitelistLanguageCodes ?? []}
                            onChange={(e) => formik.setFieldValue('whitelistLanguageCodes', Array.from(e.values()).map(e => e.code))}
                          />
                        </div>
                        {/* <div className="mt-5">
                          <label htmlFor="lang" className="form-label"> Language </label>
                          <Select
                            options={languageList.map((item): SelectObj => {
                              return { value: item.code, label: item.name }
                            })}
                            id="lang"
                            name="type-select"
                            className="react-select"
                            classNamePrefix="select"
                            value={campaign.lang.length === 0 ? null : {
                              label: (languageList as any[]).find((e) => e.code === campaign.lang)?.name,
                              value: (languageList as any[]).find((e) => e.code === campaign.lang)?.code,
                            }}
                            onChange={(e: SingleValue<SelectObj>) => {
                              if (e !== null) {
                                setCampaign({ ...campaign, lang: e.value })
                              }
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* DEVICES */}
                  <DevicesTile
                    exec={formik.values.whitelistExec}
                    onChanged={(exec) => formik.setFieldValue('whitelistExec', exec)}
                    accordionRef={accordionRefs.device}
                  />
                  {formik.touched.whitelistExec && formik.errors.whitelistExec && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.whitelistExec}</div>
                    </div>
                  )}

                  {/* PUSH SUBSCRIPTION */}
                  {formik.values.type === 'push' &&
                    <div className='accordion-item'>
                      <h2 className='accordion-header' id='kt_accordion_1_header_push'>
                        <button
                          className='accordion-button fs-4 fw-bold collapsed'
                          type='button'
                          data-bs-toggle='collapse'
                          data-bs-target='#kt_accordion_1_body_push'
                          aria-expanded='false'
                          aria-controls='kt_accordion_1_body_push'
                          ref={accordionRefs.pushSubs}
                        >
                          Push Subscription time
                        </button>
                      </h2>
                      <div
                        id='kt_accordion_1_body_push'
                        className='accordion-collapse collapse'
                        aria-labelledby='kt_accordion_1_header_push'
                        data-bs-parent='#kt_accordion_1'
                      >
                        <div className='accordion-body'>
                          <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                            <div className="mb-5 col-md-4 col-12">
                              <label htmlFor="subs_ttl" className="form-label">Subscription TTL</label>
                              <input type="number" id="subs_ttl" name="subs_ttl" className="form-control form-control-solid"
                                value={formik.values.subsTTL} onChange={(e) => {
                                  formik.setFieldValue('subsTTL', e.target.valueAsNumber);
                                }} />
                              {' Time in seconds, e.g.: 86400 - display to recent subscribers within the last 24 hours'}

                              {formik.touched.subsTTL && formik.errors.subsTTL && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.subsTTL}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* SOURCE */}
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_3'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_3'
                        ref={accordionRefs.source}
                      >
                        Source
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_3'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_3'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className="mb-5">
                          <label htmlFor="sourceID" className="form-label">Whitelist source ID</label>
                          <textarea id="sourceID" name="Source ID" className="form-control form-control-solid"
                            placeholder="Please enter source id with any separator. Example: 123,456,789"
                            value={formik.values.whitelistSourceID} onChange={(e) => {
                              formik.setFieldValue('whitelistSourceID', e.target.value);
                            }} />

                          {formik.touched.whitelistSourceID && formik.errors.whitelistSourceID && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.whitelistSourceID}</div>
                            </div>
                          )}
                        </div>
                        <div className="mb-5">
                          <label htmlFor="sourceSSP" className="form-label">Whitelist source SSP</label>
                          <textarea id="sourceSSP" name="Source SSP" className="form-control form-control-solid"
                            placeholder="Please enter ssp with any separator. Example: 123,456,789"
                            value={formik.values.whitelistSSP} onChange={(e) => {
                              formik.setFieldValue('whitelistSSP', e.target.value);
                            }} />

                          {formik.touched.whitelistSSP && formik.errors.whitelistSSP && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.whitelistSSP}</div>
                            </div>
                          )}
                        </div>

                        <div className="mb-5">
                          <label htmlFor="blacklistSourceID" className="form-label">Source disabled ID</label>
                          <textarea id="blacklistSourceID" name="Source disabled ID" className="form-control form-control-solid"
                            placeholder="Please enter source id with any separator. Example: 123,456,789"
                            value={formik.values.blacklistSourceID} onChange={(e) => {
                              formik.setFieldValue('blacklistSourceID', e.target.value);
                            }} />

                          {formik.touched.blacklistSourceID && formik.errors.blacklistSourceID && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.blacklistSourceID}</div>
                            </div>
                          )}
                        </div>
                        <div className="mb-5">
                          <label htmlFor="blacklistSourceSSP" className="form-label">Source disabled SSP</label>
                          <textarea id="blacklistSourceSSP" name="Source disabled SSP" className="form-control form-control-solid"
                            placeholder="Please enter ssp with any separator. Example: 123,456,789"
                            value={formik.values.blacklistSSP} onChange={(e) => {
                              formik.setFieldValue('blacklistSSP', e.target.value);
                            }} />

                          {formik.touched.blacklistSSP && formik.errors.blacklistSSP && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.blacklistSSP}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_connection'>
                      <button
                        className='accordion-button fs-4 fw-bold collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_connection'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_connection'
                        ref={accordionRefs.connection}
                      >
                        Connection
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_connection'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_connection'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <label htmlFor="type" className="form-label">Connection Type</label>
                        <Select
                          options={connectionOptions}
                          id="type"
                          name="type-select"
                          className="react-select"
                          classNamePrefix="select"
                          value={connectionOptions.find((e) => e.value === formik.values.connectionType)}
                          onChange={(e: SingleValue<{ label: string, value: ConnectionType }>) => {
                            if (e !== null) {
                              formik.setFieldValue('connectionType', e.value);
                            }
                          }}
                        />
                        <label htmlFor="mobilecarriers" className="form-label mt-5">Mobile carriers</label>
                        <MobileCarrierMultiPicker value={formik.values.whitelistBrands?.map(e => {
                          return { name: e };
                        }) ?? null} onChange={(e) => {
                          formik.setFieldValue('whitelistBrands', Array.from(e.map(e => e.name)));
                        }} countryIDs={formik.values.whitelistCountries?.map(e => e.id)} />
                        <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                          <label className="form-label me-5 mt-2" htmlFor="proxy_filter">
                            All proxy disable
                          </label>
                          <input className="form-check-input h-20px w-30px" type="checkbox" id="proxy_filter"
                            checked={formik.values.disableProxy} onChange={(e) => {
                              formik.setFieldValue('disableProxy', !formik.values.disableProxy);
                            }} />
                        </div>
                        <div className="mt-5">
                          <label htmlFor="ipwhitelist" className="form-label">IP enabled range</label>
                          <textarea id="ipwhitelist" name="IP enabled range" className="form-control form-control-solid"
                            placeholder="0.0.0.0/0, 192.168.0.1/0"
                            value={formik.values.whitelistIPRanges} onChange={(e) => {
                              formik.setFieldValue('whitelistIPRanges', e.target.value);
                            }} />
                        </div>
                        <div className="mt-5">
                          <label htmlFor="ipwblacklist" className="form-label">IP disabled range</label>
                          <textarea id="ipwblacklist" name="IP disabled range" className="form-control form-control-solid"
                            placeholder="0.0.0.0/0, 192.168.0.1/0"
                            value={formik.values.blacklistIPRanges} onChange={(e) => {
                              formik.setFieldValue('blacklistIPRanges', e.target.value);
                            }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex'>
                <button
                  type='submit'
                  className='btn btn-success mt-2 me-2 px-6'
                >
                  Save
                </button>
              </div>
            </form>
          </div >
        </div >
      </div >
    </>
  )
}

const DevicesTile = ({ exec, onChanged, accordionRef }: { exec: String | undefined, onChanged: (exec: String) => void, accordionRef: React.RefObject<HTMLButtonElement> }) => {
  const parsedForm = useMemo(() => {
    if (exec && exec.length > 0) return decodeFilters(exec)
    return null;
  }, [exec]);

  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceTypeMultiResult[]>(parsedForm?.DeviceTypes ?? []);
  const [selectedDeviceOss, setSelectedDeviceOss] = useState<VersionedFilter<DeviceOSMultiResult>[]>(parsedForm?.OSFilters ?? []);
  const [selectedDeviceBrowsers, setSelectedDeviceBrowsers] = useState<VersionedFilter<DeviceBrowserMultiResult>[]>(parsedForm?.Browser ?? [])
  const [useCustomOsVersions, setUseCustomOsVersions] = useState<boolean>(parsedForm?.OSFilters.find((e) => e.useVersionFilter) !== undefined);
  const [useCustomBrowserVersion, setUseCustomBrowserVersion] = useState<boolean>(parsedForm?.Browser.find((e) => e.useVersionFilter) != undefined);
  const [blockDevicesWebView, setBlockDevicesWebView] = useState<boolean>(parsedForm?.WebView ?? false);

  useEffect(() => {
    setSelectedDeviceOss((e) => e.map((e) => ({ ...e, useVersionFilter: useCustomOsVersions })))
  }, [useCustomOsVersions])

  useEffect(() => {
    setSelectedDeviceBrowsers((e) => e.map((e) => ({ ...e, useVersionFilter: useCustomBrowserVersion })))
  }, [useCustomBrowserVersion])

  useEffect(() => {
    const encodedFilterOss = encodeFilters(selectedDeviceOss)
    const encodedFilterBrowsers = encodeFilters(selectedDeviceBrowsers)
    const encodedFilterTypes =
      selectedDeviceTypes.length === 0
        ? 'true'
        : `${selectedDeviceTypes
          .map((e) => {
            const isDesktop = e.name === 'desktop'
            return isDesktop ? 'desktop == true' : 'mobile == true'
          })
          .join(' || ')}`
    const hash: EncodedFiltersHash = {
      OSFilters: selectedDeviceOss,
      Browser: selectedDeviceBrowsers,
      WebView: blockDevicesWebView,
      DeviceTypes: selectedDeviceTypes,
    }
    const isEmptyFilter = [
      encodedFilterOss,
      encodedFilterBrowsers,
      encodedFilterTypes,
    ].every((e) => e === 'true') && !blockDevicesWebView
    const devicesFilter = isEmptyFilter ? '' : `${blockDevicesWebView ? 'wv == false' : 'true'} && (${encodedFilterTypes}) && (${encodedFilterOss} && ${encodedFilterBrowsers}) /*${JSON.stringify(
      hash
    )}*/`
    onChanged(devicesFilter);
  }, [selectedDeviceOss, selectedDeviceBrowsers, selectedDeviceTypes, blockDevicesWebView])

  return (
    <>
      <div className='accordion-item'>
        <h2 className='accordion-header' id='kt_accordion_1_header_2'>
          <button
            className='accordion-button fs-4 fw-bold collapsed'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_accordion_1_body_2'
            aria-expanded='false'
            aria-controls='kt_accordion_1_body_2'
            ref={accordionRef}
          >
            Device
          </button>
        </h2>
        <div
          id='kt_accordion_1_body_2'
          className='accordion-collapse collapse'
          aria-labelledby='kt_accordion_1_header_2'
          data-bs-parent='#kt_accordion_1'
        >
          <div className='accordion-body'>
            <div className="mb-5">
              <label htmlFor="devicetypes" className="form-label">Device types</label>
              <DeviceTypeMultiPicker
                value={selectedDeviceTypes}
                onChange={(e) => setSelectedDeviceTypes(Array.from(e.values()))}
              />
              <label htmlFor="deviceoss" className="form-label mt-4">Device OS</label>
              <DeviceOSMultiPicker
                value={selectedDeviceOss.map((e) => e.frontendValue)}
                onChange={(e) => {
                  setSelectedDeviceOss(e.map((e) => {
                    return {
                      versionOperator: '>=',
                      filterKey: 'os',
                      versionKey: 'osv',
                      useVersionFilter: false,
                      versionValue: 0,
                      frontendValue: e,
                      filterValue: e.name.toLowerCase(),
                    }
                  }))
                }}
              />
              <div className="form-check form-switch form-check-custom form-check-solid mt-2">
                <label className="form-label mt-2" htmlFor="specific_os_version">
                  Any OS version
                </label>
                <input className="form-check-input h-20px w-30px mx-4" type="checkbox" id="specific_os_version"
                  checked={useCustomOsVersions} onChange={(e) => {
                    setUseCustomOsVersions(!useCustomOsVersions);
                  }} />
                <label className="form-label mt-2" htmlFor="specific_os_version">
                  Specific OS version
                </label>
              </div>
              {useCustomOsVersions && <div className="form-check-solid row">
                {selectedDeviceOss.map((e) => (<div className="mt-2 row" key={e.frontendValue.id}>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={[]}
                      id="type"
                      isDisabled={true}
                      value={{ label: e.frontendValue.name, value: 'value' }}
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={operatorOptions}
                      id="type"
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                      value={{ label: e.versionOperator, value: e.versionOperator }}
                      onChange={(event: SingleValue<{ label: string, value: string }>) => {
                        setSelectedDeviceOss(selectedDeviceOss.map((os) => {
                          if (os.frontendValue.id === e.frontendValue.id && event != null) {
                            return {
                              ...os,
                              versionOperator: event.value,
                            }
                          }
                          return os;
                        }))
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <input type="number" className="form-control form-control-solid"
                      value={e.versionValue} onChange={(event) => {
                        setSelectedDeviceOss(selectedDeviceOss.map((os) => {
                          if (os.frontendValue.id === e.frontendValue.id) {
                            return {
                              ...os,
                              versionValue: event.target.valueAsNumber,
                            }
                          }
                          return os;
                        }))
                      }} />
                  </div>
                </div>))}
              </div>}
              <div className="mt-5">
                <label htmlFor="devicebrowsers" className="form-label">Device Browsers</label>
                <DeviceBrowserMultiPicker
                  value={selectedDeviceBrowsers.map((e) => e.frontendValue)}
                  onChange={(e) => {
                    setSelectedDeviceBrowsers(e.map((e) => {
                      return {
                        versionOperator: '>=',
                        filterKey: 'browser',
                        versionKey: 'browser_v',
                        useVersionFilter: false,
                        versionValue: 0,
                        frontendValue: e,
                        filterValue: e.name.toLowerCase(),
                      }
                    }));
                  }}
                />
              </div>
              <div className="mt-5 form-check form-switch form-check-custom form-check-solid mt-2">
                <label className="form-label mt-2" htmlFor="specific_browser_version">
                  Any browser version
                </label>
                <input className="form-check-input h-20px w-30px mx-4" type="checkbox" id="specific_browser_version"
                  checked={useCustomBrowserVersion} onChange={(e) => {
                    setUseCustomBrowserVersion(!useCustomBrowserVersion);
                  }} />
                <label className="form-label mt-2" htmlFor="specific_browser_version">
                  Specific Browser version
                </label>
              </div>
              {useCustomBrowserVersion && <div className="form-check-solid row">
                {selectedDeviceBrowsers.map((e) => (<div className="mt-2 row" key={e.frontendValue.id}>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={[]}
                      id="type"
                      isDisabled={true}
                      value={{ label: e.frontendValue.name, value: 'value' }}
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="mb-5 col-md-4 col-12">
                    <Select
                      options={operatorOptions}
                      id="type"
                      name="type-select"
                      className="react-select"
                      classNamePrefix="select"
                      value={{ label: e.versionOperator, value: e.versionOperator }}
                      onChange={(event: SingleValue<{ label: string, value: string }>) => {
                        setSelectedDeviceBrowsers(selectedDeviceBrowsers.map((browser) => {
                          if (browser.frontendValue.id === e.frontendValue.id && event != null) {
                            return {
                              ...browser,
                              versionOperator: event.value,
                            }
                          }
                          return browser;
                        }))
                      }}
                    />
                  </div>
                  <div className="col-md-4 col-12">
                    <input type="number" className="form-control form-control-solid"
                      value={e.versionValue} onChange={(event) => {
                        setSelectedDeviceBrowsers(selectedDeviceBrowsers.map((browser) => {
                          if (browser.frontendValue.id === e.frontendValue.id) {
                            return {
                              ...browser,
                              versionValue: event.target.valueAsNumber,
                            }
                          }
                          return browser;
                        }))
                      }} />
                  </div>
                </div>))}
              </div>}
            </div>
            <div className="form-check form-switch form-check-custom form-check-solid mt-2">
              <label className="form-label me-5 mt-2" htmlFor="device_webview">
                WebView disabled
              </label>
              <input className="form-check-input h-20px w-30px" type="checkbox" id="device_webview"
                checked={blockDevicesWebView} onChange={(e) => {
                  setBlockDevicesWebView(!blockDevicesWebView)
                }} />
            </div>
          </div>
        </div >
      </div>
    </>
  );
}