import { gql } from "./gen";

export const QUERY_FIND_DEVICE_TYPES = gql(`
  query FindDeviceTypes($query: String, $pagination: PaginationInput!) {
    findDeviceTypes(query: $query, pagination: $pagination) {
      pageInfo{
        totalCount
      }
      types{
        id
        name
      }
    }
  }
`)

export const QUERY_FIND_DEVICE_OSS = gql(`
  query FindDeviceOss($query: String, $pagination: PaginationInput!) {
    findDeviceOSs(query: $query, pagination: $pagination) {
      pageInfo{
        totalCount
      }
      oss{
        id
        name
      }
    }
  }
`)

export const QUERY_FIND_DEVICE_BROWSERS = gql(`
  query FindDeviceBrowsers($query: String, $pagination: PaginationInput!) {
    findDeviceBrowsers(query: $query, pagination: $pagination) {
      pageInfo{
        totalCount
      }
      browsers{
        id
        name
      }
    }
  }
`)