import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { FindDeviceOssQuery } from '../../api/v2/gql/gen/graphql';
import { QUERY_FIND_DEVICE_OSS } from '../../api/v2/gql/devices';

export type DeviceOSMultiResult = FindDeviceOssQuery['findDeviceOSs']['oss'][number]

type Props = {
  options?: Partial<AsyncPaginateProps<DeviceOSMultiResult, GroupBase<DeviceOSMultiResult>, { page: number }, true>>
  value: DeviceOSMultiResult[]
  onChange?: (newValue: MultiValue<DeviceOSMultiResult>) => void;
}

export const DeviceOSMultiPicker = (props: Props) => {
  const [fetchRegions] = useLazyQuery(QUERY_FIND_DEVICE_OSS)

  const loadOptions: LoadOptions<DeviceOSMultiResult, GroupBase<DeviceOSMultiResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchRegions({
      variables: {
        query: search,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findDeviceOSs.oss,
      hasMore: response.data!.findDeviceOSs.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.name}`}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}