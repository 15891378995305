import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Link } from "react-router-dom";
import { useCallback, useEffect, useState } from 'react';
import { IPaginationRequest } from '../../../api/models';
import { KTIcon } from '../../../_metronic/helpers';
import { Pagination } from '../../helpers/pagination/Pagination';
import { FindCreativesQuery } from '../../../api/v2/gql/gen/graphql';
import { useLazyQuery, useMutation } from '@apollo/client';
import { QUERY_FIND_CREATIVES } from '../../../api/v2/gql/campaigns';
import { client } from '../../../api/v2/gql/client';
import { MUTATION_DELETE_CREATIVE, MUTATION_DUPLICATE_CREATIVE, MUTATION_UPDATE_CREATIVE } from '../../../api/v2/gql/creatives';
import { Modal } from 'react-bootstrap';

export const CreativesPage = () => {
  const [pagination, setPagination] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: 10,
  })
  const [totalCount, setTotalCount] = useState(0)
  const [disableWarningModalShow, setDisableWarningModalShow] = useState(false);
  const [disableWarningModalShowed, setDisableWarningModalShowed] = useState(false);
  const intl = useIntl()

  const [creatives, setCreatives] = useState<FindCreativesQuery['findCreatives']['creatives'][number][]>([]);
  const [fetchCreatives] = useLazyQuery(QUERY_FIND_CREATIVES)
  const [deleteCreative] = useMutation(MUTATION_DELETE_CREATIVE)

  const update = useCallback(async () => {
    const response = await fetchCreatives({
      variables: {
        pagination: {
          pageNumber: pagination.pageNumber,
          pageSize: pagination.pageSize,
        },
      },
    })
    setCreatives(response.data!.findCreatives.creatives);
    setTotalCount(response.data!.findCreatives.pageInfo.totalCount);
  }, [pagination])

  useEffect(() => {
    update();
  }, [pagination])

  const handleCopy = async (creative) => {
    await client.mutate({
      mutation: MUTATION_DUPLICATE_CREATIVE,
      variables: {
        id: creative.id
      }
    })

    update();
  }

  const handleDelete = async (creative) => {
    await deleteCreative({
      variables: {
        id: creative.id,
      }
    });

    setCreatives(creatives.filter((e) => e.id !== creative.id))
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CREATIVES' })}</PageTitle>
      <Modal
        className='modal fade'
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={disableWarningModalShow}
        dialogClassName='modal-md'
        aria-hidden='true'
        onHide={() => {
          setDisableWarningModalShow(false)
          setDisableWarningModalShowed(true);
        }}
      >
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Do you want to disable creative?</h5>
          </div>
          <div className='modal-body'>
            This action will disable creative in all campaigns
          </div>
          <div className='modal-footer'>
            <button id='submit' type='button' className='btn btn-primary' onClick={(e) => {
              e.preventDefault()
              setDisableWarningModalShow(false)
              setDisableWarningModalShowed(true);
            }}>
              OK
            </button>
          </div>
        </div>
      </Modal>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            <div className='card-body py-3 container'>
              <table className='table align-middle gs-0' style={{ marginBottom: '0' }}>
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 w-60px'>Status</th>
                    <th className='w-200px'>Creatives</th>
                    <th className='w-200px'>Creative data</th>
                    <th className='w-200px text-end pe-4'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {creatives.map((creative) => (
                    <tr key={creative.id}>
                      <td>
                        <div className='d-flex'>
                          <div className='d-flex align-items-center'>
                            <div className="form-check form-switch form-check-custom form-check-solid justify-content-start">
                              <input className="form-check-input w-35px h-20px ms-4" type="checkbox"
                                checked={creative.enabled}
                                onChange={async (e) => {
                                  if (disableWarningModalShowed) {
                                    setCreatives(creatives.map((e) => e.id === creative.id ? { ...creative, enabled: !creative.enabled } : e))
                                    await client.mutate({
                                      mutation: MUTATION_UPDATE_CREATIVE,
                                      variables: {
                                        id: creative.id,
                                        input: {
                                          enabled: !creative.enabled,
                                        },
                                      }
                                    })
                                  } else if (!disableWarningModalShow) {
                                    setDisableWarningModalShow(true);
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {creative.imgRurl && (<div>
                            <span className='symbol-label'>
                              <img src={creative.imgRurl} style={{ maxWidth: "100px" }} className='h-50px rounded align-self-center me-2' alt='' />
                            </span>
                          </div>)}
                          <div className='d-flex justify-content-start flex-column'>
                            <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                              {creative.name}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {(() => {
                                switch (creative.type) {
                                  case 'banner':
                                    return <>Banner({creative.size})</>
                                  default:
                                    return <>{creative.type}</>
                                }
                              })()}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <a
                          href={creative.rurl ?? undefined}
                          className='text-dark fw-bold text-hover-primary d-block fs-6'
                        >
                          {creative.rurl?.length ?? 0 >= 30
                            ? `${creative.rurl!.substring(0, 30)}...`
                            : creative.rurl}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7' style={{
                          maxHeight: "3.6em",
                          lineHeight: "1.8em",
                          textOverflow: "ellipsis",
                          wordWrap: "break-word",
                          overflow: "hidden",
                        }}>
                          {[creative.title, creative.description]
                            .filter((e) => e && e.trim().length > 0)
                            .join(' ')}
                        </span>
                      </td>
                      <td className='text-end pe-4'>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <Link
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            to={`/creatives/${creative.id}`}
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </Link>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault();
                              handleCopy(creative);
                            }}
                          >
                            <KTIcon iconName='copy' className='fs-3' />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(creative);
                            }}
                          >
                            <KTIcon iconName='trash' className='fs-3' />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination
                className='mt-10 mb-5'
                currentPage={pagination.pageNumber}
                totalCount={totalCount}
                pageSize={pagination.pageSize}
                onPageChange={(page: number) => {
                  setPagination({ ...pagination, pageNumber: page })
                }}
              />
            </div>
          </div >
        </div >
      </div >
    </>
  )
}
