import React, { useState, useEffect, useMemo } from 'react'
import { CampaignCurrentStatus, getCampaignCurrentStatus } from '../../../api/models'
import { generateAlert, AlertType } from '../../helpers/alert'
import { KTIcon, KTSVG } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ConfirmModal } from '../../../_metronic/partials/modals/confirm'
import { useAuth } from '../../modules/auth'
import clsx from 'clsx'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import { Modal } from 'react-bootstrap'
import { UserRole } from '../../../api/v2/gql/gen/graphql'
import { CampaignDisplayType } from './Campaigns'
import { useMutation } from '@apollo/client'
import { MUTATION_DELETE_CAMPAIGN, MUTATION_DUPLICATE_CAMPAIGN, MUTATION_UPDATE_CAMPAIGN } from '../../../api/v2/gql/campaigns'
import { client } from '../../../api/v2/gql/client'

type Props = {
  campaign: CampaignDisplayType
  index: number
  update: () => void
  deleteUpdate: () => void
}

export const Campaign = ({ campaign, index, update, deleteUpdate }: Props) => {
  const [isToggle, setIsToggle] = useState(false)
  const [loading, setLoading] = useState(false)
  const [copyModalShow, setCopyModalShow] = useState(false)
  const [moderationStatus, setModerationStatus] = useState(campaign.moderationStatus)
  const [moderationReason, setModerationReason] = useState(campaign.moderationReason)

  const { currentUser } = useAuth()
  const [updateCampaign] = useMutation(MUTATION_UPDATE_CAMPAIGN)
  const [duplicateCampaign] = useMutation(MUTATION_DUPLICATE_CAMPAIGN)
  const mode = useThemeMode().currentMode()

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsToggle(campaign.enabled)
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [campaign.enabled])

  const handleUpdateStatus = async () => {
    setLoading(true)
    try {
      await updateCampaign({
        variables: {
          id: campaign.id,
          input: {
            enabled: !isToggle,
          }
        }
      })
      setIsToggle((prev) => !prev)
    } catch (data: any) {
      console.error('An error occurred:', data.error)
      generateAlert(data.error as string, AlertType.ERROR)
    } finally {
      setLoading(false)
    }
  }

  const [showModal, setShowModal] = useState(false)
  const isUnderline = useMemo(() => {
    return currentUser?.role === UserRole.Admin
  }, [currentUser?.role])
  const [showStatusModal, setShowStatusModal] = useState(false)

  const handleDelete = () => {
    setShowModal(true)
  }

  const handleChangeStatus = () => {
    setShowStatusModal(true)
  }

  const cancelModerationChangeStatus = () => {
    setShowStatusModal(false)
  }

  const confirmDelete = async () => {
    await client.mutate({
      mutation: MUTATION_DELETE_CAMPAIGN,
      variables: {
        id: campaign.id,
      }
    })
    setShowModal(false)
    update()
    deleteUpdate()
  }

  const cancelDelete = () => {
    setShowModal(false)
  }

  const [isBidInput, setIsBidInput] = useState(false)
  const [bidString, setBidString] = useState('')
  const [isFocused, setIsFocused] = useState(false)

  const bidMin = () => {
    if (campaign.type === 'pops' && campaign.payType === 'cpm') {
      return 0.1
    }
    if (campaign.type === 'push' && campaign.payType === 'cpc') {
      return 0.01
    }
    if (campaign.type === 'banner' && campaign.payType === 'cpm') {
      return 0.01
    }
  }

  const bidMax = () => {
    if (campaign.type === 'pops' && campaign.payType === 'cpm') {
      return 20
    }
    if (campaign.type === 'push' && campaign.payType === 'cpc') {
      return 1
    }
    if (campaign.type === 'banner' && campaign.payType === 'cpm') {
      return 10
    }
  }

  const handleBidClick = () => {
    setIsBidInput(true)
    setBidString(campaign.bid.toString())
  }

  const handleBidChange = (e) => {
    setBidString(e.target.value)
  }

  const handleBidBlur = async () => {
    setIsBidInput(false)
    setIsFocused(false)

    const value = parseFloat(bidString.replaceAll(',', '.'))
    const max = bidMax()
    const min = bidMin()
    if (max && value > max) return;
    if (min && value < min) return;

    await updateCampaign({
      variables: {
        id: campaign.id,
        input: {
          bid: value.toString(),
        }
      }
    })
    update()
  }

  const handleBidFocus = () => {
    setIsFocused(true)
  }

  const handleCopy = () => {
    setCopyModalShow(true)
  }

  const handleModerationStatusChange = async (e) => {
    const value = e.target.value
    setModerationStatus(value)
  }

  const statusOptions: Array<{ value: CampaignCurrentStatus, label: string, className: string }> = [
    {
      value: 'balance_low',
      label: 'Balance low',
      className: 'badge badge-light-danger',
    },
    {
      value: 'active',
      label: 'In Progress',
      className: 'badge badge-light-success',
    },
    {
      value: 'waiting',
      label: 'Under Moderation',
      className: 'badge badge-light-primary',
    },
    {
      value: 'rejected',
      label: 'Rejected',
      className: 'badge badge-light-danger',
    },
    {
      value: 'limit_status',
      label: 'Paused by limit',
      className: 'badge badge-light-warning',
    },
    {
      value: 'disabled',
      label: 'Paused',
      className: 'badge badge-light-warning',
    },
    {
      value: 'no_creatives',
      label: 'No Creatives',
      className: 'badge badge-light-danger',
    },
  ]

  const getStatusLabel = (statusValue) => {
    const status = statusOptions.find((option) => option.value === statusValue)
    return status ? status.label : ''
  }

  const getStatusClass = (statusValue) => {
    const status = statusOptions.find((option) => option.value === statusValue)
    return status ? status.className : ''
  }
  const handleModerationReasonChange = (event) => {
    setModerationReason(event.target.value);
  }

  const handleSubmitChangeModerationStatus = async () => {
    await updateCampaign({
      variables: {
        id: campaign.id,
        input: {
          moderationStatus: moderationStatus,
          moderationReason: moderationReason,
        }
      }
    })
    setShowStatusModal(!showStatusModal)
    update();
  }

  const [showLimits, setShowLimits] = useState(false);

  const handleToggleLimits = () => {
    setShowLimits(!showLimits);
  };

  const renderContent = () => {
    const status = getCampaignCurrentStatus({
      ...campaign,
      enabled: isToggle,
    });
    const statusLabel = getStatusLabel(status.code)
    const statusClass = getStatusClass(status.code)

    if (status.code === 'no_creatives' || status.code === 'limit_status') {
      return (
        <>
          <div className={` row ${statusClass}`} onClick={handleToggleLimits}>
            {statusLabel}
            {status.code === 'limit_status' && (
              <span className={`row mt-2`}>Reason: {status.reason}</span>
            )}
            {showLimits && (
              <>
                <Modal
                  className='modal fade'
                  data-backdrop='static'
                  tabIndex={-1}
                  role='dialog'
                  show={showLimits && status.code === 'limit_status'}
                  dialogClassName='modal-md'
                  aria-hidden='true'
                >
                  <tr>
                    <td className='text-center'>Daily Impressions: {campaign.dailyImp}</td>
                    <td className='text-center'>Daily Clicks: {campaign.dailyCls}</td>
                    <td className='text-center'>Daily Cost: {campaign.dailyCost}</td>
                    <td className='text-center'>Total Impressions: {campaign.totalImp}</td>
                    <td className='text-center'>Total Clicks: {campaign.totalCls}</td>
                    <td className='text-center'>Total Cost: {campaign.totalCost}</td>
                  </tr>
                </Modal>
              </>
            )}
          </div>
        </>
      )
    } else {
      return (
        <div className={` row ${statusClass} text-center`}>
          {statusLabel}
          {status.code === 'balance_low' && (
              <><tr/>{`Current balance: ${parseFloat(campaign.advertiser.user.balance.currentBalance).toFixed(6)}`}</>
          )}
          {status.code === 'rejected' && (
            <span className={`row mt-2`}>Reason: {status.reason}</span>
          )}
        </div>
      )
    }
  }

  return (
    <div className={`bg-secondary rounded-top`}>
      <div className=''>
        <ConfirmModal
          handleClose={async (confirm) => {
            setCopyModalShow(false)
            if (confirm) {
              await duplicateCampaign({
                variables: {
                  id: campaign.id,
                }
              })
              update()
            }
          }}
          show={copyModalShow}
          data={{
            title: 'Do you want to copy campany?',
            description: `New campany will be saved with name "${campaign.name}-copy"`,
          }}
        />
      </div>
      {/* begin::Body */}
      <div>
        {/* begin::Table container */}
        <div className={clsx('table-responsive rounded-top', { 'card rounded-0': mode === 'light' })}>
          {/* begin::Table */}
          <table className='table align-middle gs-0' style={{ marginBottom: '0' }}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold bg-light'>
                <th className='text-center ps-4 w-70px rounded-start'>Status</th>
                <th className='text-center w-150px'>Actual process</th>
                <th className='text-center w-150px'>Campaign</th>
                {currentUser?.role === UserRole.Admin && <th className='text-center w-150px'>Advertiser</th>}
                <th className='text-center w-150px'>Type</th>
                <th className='text-center w-150px'>Bid {campaign.payType.toUpperCase()}</th>
                <th className='w-200px text-end pe-4'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              <tr>
                <td>
                  <div className='form-check form-switch form-check-custom form-check-solid justify-content-start'>
                    <input
                      className='form-check-input ms-4'
                      type='checkbox'
                      disabled={loading}
                      checked={isToggle}
                      onChange={(e) => {
                        handleUpdateStatus()
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    {renderContent()}
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    <span className='d-text-dark mb-1 fs-6'>
                      {campaign.name}
                    </span>
                  </div>
                </td>
                {currentUser?.role === UserRole.Admin && (
                  <td>
                    <div className='d-flex justify-content-center'>
                      {campaign.advertiser.advertiserID} - {campaign.advertiser.user.email}
                    </div>
                  </td>
                )}
                <td>
                  <div className='d-flex justify-content-center'>
                    <span className='ms-2'>{campaign.type}</span>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center'>
                    {isBidInput ? (
                      <div>
                        <div className='input-group mb-3'>
                          <input
                            type='text'
                            className={`form-control ${isFocused ? 'small-input' : ''}`}
                            value={bidString}
                            onChange={handleBidChange}
                            onBlur={handleBidBlur}
                            onFocus={handleBidFocus}
                          />
                          <Button
                            variant='primary'
                            onClick={handleBidBlur}
                            style={{ display: isFocused ? 'inline-block' : 'none' }}
                          >
                            OK
                          </Button>
                        </div>

                        {(bidMax() && parseFloat(bidString) > bidMax()!) && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Bid is greater than {bidMax()}</div>
                          </div>
                        )}

                        {(bidMin() && parseFloat(bidString) < bidMin()!) && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>Bid is lower than {bidMin()}</div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {
                          currentUser?.role === UserRole.Admin
                            ? (
                              <>
                                <div onClick={handleBidClick}>
                                  <span
                                    className={`fw-semibold d-block ms-3 ${isUnderline ? 'text-decoration-underline' : ''
                                      }`}>
                                    ${campaign.bid.toString()}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                {campaign.payType.toLowerCase() === 'cpa' && <>
                                  CPA
                                </>}
                                {campaign.payType.toLowerCase() !== 'cpa' && <>
                                  <span
                                    className={`fw-semibold d-block ms-3 ${isUnderline ? 'text-decoration-underline' : ''
                                      }`}>
                                    ${campaign.bid}
                                  </span>
                                </>}
                              </>
                            )
                        }
                      </div>
                    )}
                  </div>
                </td>
                <td className='text-end pe-4'>
                  <div className='d-flex justify-content-end flex-shrink-0'>
                    <Link
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      to={`/stats/${campaign.id}`}
                    >
                      <i className="bi bi-bar-chart-fill"></i>
                    </Link>
                    {currentUser?.role === UserRole.Admin && (
                      <>
                        <a
                          href='#'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={handleChangeStatus}
                        >
                          <KTSVG path="/media/icons/duotune/general/gen051.svg" className="svg-icon-muted svg-icon-2hx" />
                        </a>
                      </>
                    )}
                    {showStatusModal && (
                      <div className='modal fade show' style={{ display: 'block' }} tabIndex={-1}>
                        <div className='modal-dialog'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title'>Change status</h5>
                              <button type='button' className='btn-close' onClick={cancelModerationChangeStatus} />
                            </div>
                            <select
                              id='statusSelect'
                              className='form-select bg-secondary rounded'
                              value={moderationStatus}
                              onChange={handleModerationStatusChange}
                            >
                              <option value='waiting'>In moderation</option>
                              <option value='successfully'>Approve</option>
                              <option value='rejected'>Reject</option>
                            </select>
                            {moderationStatus === 'rejected' && (
                              <div className='modal-body'>
                                <textarea
                                  className='form-control'
                                  value={moderationReason}
                                  onChange={handleModerationReasonChange}
                                />
                              </div>
                            )}
                            <div className='modal-footer'>
                              <button type='button' className='btn btn-secondary' onClick={cancelModerationChangeStatus}>
                                Cancel
                              </button>
                              <button
                                type='button'
                                className='btn btn-primary'
                                disabled={moderationStatus === 'rejected' && moderationReason.trim().length === 0}
                                onClick={() => {
                                  if (moderationStatus === 'rejected' && moderationReason.trim().length === 0) return;
                                  handleSubmitChangeModerationStatus()
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* <Link
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      to={`/filters/${campaign.id}`}
                    >
                      <KTIcon iconName='switch' className='fs-3' />
                    </Link> */}
                    {/* <Link
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      to={`/creatives/${campaign.id}`}
                    >
                      <KTIcon iconName='menu' className='fs-3' />
                    </Link> */}
                    <Link
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      to={`/campaign/${campaign.id}`}
                    >
                      <KTIcon iconName='pencil' className='fs-3' />
                    </Link>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={handleCopy}
                    >
                      <KTIcon iconName='copy' className='fs-3' />
                    </a>
                    <a
                      href='#'
                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                      onClick={handleDelete}
                    >
                      <KTIcon iconName='trash' className='fs-3' />
                    </a>
                    {showModal && (
                      <div className='modal fade show' style={{ display: 'block' }} tabIndex={-1}>
                        <div className='modal-dialog'>
                          <div className='modal-content'>
                            <div className='modal-header'>
                              <h5 className='modal-title'>Confirm deletion</h5>
                              <button type='button' className='btn-close' onClick={cancelDelete} />
                            </div>
                            <div className='modal-body d-flex align-items-center'>Are you sure you want to delete the campaign?</div>
                            <div className='modal-footer'>
                              <button type='button' className='btn btn-secondary' onClick={cancelDelete}>
                                Cancel
                              </button>
                              <button type='button' className='btn btn-danger' onClick={confirmDelete}>
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
