import React, { useCallback, useEffect, useRef, useState } from "react";
import Flatpickr from 'react-flatpickr';
import { IPaginationRequest } from "../../../api/models";
import { AxiosError } from "axios";
import * as Yup from 'yup'
import { AlertType, generateAlert } from "../../helpers/alert";
import { KTCard, KTCardBody, KTIcon, useDebounce } from "../../../_metronic/helpers";
import TransactionsTable from "./TransactionsTable";
import { Pagination } from "../../helpers/pagination/Pagination";
import { Modal } from "react-bootstrap";
import { UserPicker, UserPickerEntity } from "../../components/UserPicker";
import { useFormik } from "formik";
import moment from "moment";
import Select, { SingleValue } from 'react-select'
import { useAuth } from "../auth";
import { useLazyQuery, useMutation } from "@apollo/client";
import { MUTATION_CREATE_TRANSACTION, MUTATION_SYNC_COSTS, QUERY_FIND_TRANSACTIONS } from "../../../api/v2/gql/transactions";
import { FindTransactionsQuery, TransactionType } from "../../../api/v2/gql/gen/graphql";
import Decimal from "decimal.js";

export const Finance: React.FC = () => {
  const { currentUser } = useAuth();
  const [transactionsTotal, setTransactionsTotal] = useState(0)
  const [transactions, setTransactions] = useState<Array<FindTransactionsQuery['findTransactions']['transactions'][number]>>([])
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showSyncCostsModal, setShowSyncCostsModal] = useState(false)

  const [filterQuery, setFilterQuery] = useState('');
  const debouncedFilterQuery = useDebounce(filterQuery, 200);
  const [filterDate, setFilterDate] = useState<Array<Date>>([])
  const [filterUser, setFilterUser] = useState<UserPickerEntity | undefined>(currentUser?.role === 'admin' ? undefined : currentUser!)

  const pageSize = 20
  const [pagination, setPagination] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: pageSize,
  })

  const [findTransactions] = useLazyQuery(QUERY_FIND_TRANSACTIONS)

  const refFlatpickr = useRef<Flatpickr>(null);

  const update = useCallback(async () => {
    try {
      const response = await findTransactions({
        variables: {
          pagination: {
            pageSize: pagination.pageSize,
            pageNumber: pagination.pageNumber,
          },
          query: debouncedFilterQuery,
          startDate: filterDate[0],
          endDate: filterDate[1],
          userID: filterUser?.id,
        },
      })
      setTransactions(response.data!.findTransactions.transactions);
      setTransactionsTotal(response.data!.findTransactions.pageInfo.totalCount);
    } catch (error: any) {
      if (error instanceof AxiosError && error.response?.data.message) {
        generateAlert(error.response.data.message, AlertType.ERROR)
      } else {
        generateAlert("Error", AlertType.ERROR)
      }
    }
  }, [pagination, debouncedFilterQuery, filterDate, filterUser])

  useEffect(() => {
    update()
  }, [pagination, debouncedFilterQuery, filterDate, filterUser])

  return (
    <>
      <CreateTransactionModal onClose={(created) => {
        setShowCreateModal(false)
        if (created) {
          update()
        }
      }} show={showCreateModal} />
      <SyncCostsModal onClose={(created) => {
        setShowSyncCostsModal(false)
        if (created) {
          update()
        }
      }} show={showSyncCostsModal} />
      <KTCard>
        <div className="card-header border-0 py-6">
          <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search transaction'
                value={filterQuery}
                onChange={(e) => setFilterQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="card-toolbar" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {filterDate.length > 0 && (<>
                <span className='btn btn-flush btn-color-primary' onClick={(e) => {
                  if (refFlatpickr?.current?.flatpickr) {
                    refFlatpickr.current.flatpickr.clear(true, true)
                    refFlatpickr.current.flatpickr.selectedDates = [];
                  }
                }}>
                  <KTIcon iconName='cross' className='fs-1' />
                </span>
              </>)}
              <div className="me-2">
                <Flatpickr
                  ref={refFlatpickr}
                  value={filterDate}
                  onChange={setFilterDate}
                  style={{ width: 'auto' }}
                  options={{
                    mode: "range",
                  }}
                  className='form-control form-control-solid'
                  placeholder='Pick date'
                />
              </div>
              {currentUser?.role === 'admin' && (
                <div className="me-2">
                  <UserPicker
                    options={{
                      styles: {
                        container: (base, props) => {
                          return {
                            width: '250px',
                            ...base
                          }
                        },
                      },
                      isClearable: true
                    }}
                    onChange={setFilterUser}
                  />
                </div>
              )}
              <div className='me-2'>
                <select
                  value={pagination.pageSize}
                  onChange={(e) => setPagination({
                    ...pagination,
                    pageSize: parseInt(e.target.value)
                  })}
                  className='form-select w-100'
                >
                  <option value={5}>5</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </div>
              {currentUser?.role === 'admin' && (<>
                <div className='me-2'>
                  <button className='btn btn-primary' onClick={() => setShowCreateModal(true)}>
                    Create
                  </button>
                </div>
                <div className='me-2'>
                  <button className='btn btn-primary' onClick={() => setShowSyncCostsModal(true)}>
                    Sync costs
                  </button>
                </div>
              </>)}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <KTCardBody>
            <TransactionsTable
              data={transactions}
            />

            <Pagination
              currentPage={pagination.pageNumber}
              totalCount={transactionsTotal}
              pageSize={pagination.pageSize}
              onPageChange={(page) => setPagination({
                ...pagination,
                pageNumber: page,
              })}
            />
          </KTCardBody>
        </div>
      </KTCard>
    </>
  )
}

type CreateTransactionModalProps = {
  onClose: (created: boolean) => void,
  show: boolean,
}

export const CreateTransactionModal = (props: CreateTransactionModalProps) => {
  const typeOptions = [
    { label: 'Correction', value: TransactionType.Correction },
    { label: 'Cost', value: TransactionType.Cost },
    { label: 'Income', value: TransactionType.Income },
    { label: 'Payin', value: TransactionType.Payin },
    { label: 'Payout', value: TransactionType.Payout },
  ];

  const [create] = useMutation(MUTATION_CREATE_TRANSACTION)

  const formik = useFormik({
    initialValues: {
      user: undefined as UserPickerEntity | undefined,
      amount: 0,
      type: 'correction' as TransactionType,
      reason: '',
    },
    validationSchema: Yup.object().shape({
      user: Yup.object<UserPickerEntity>().required('User is required'),
      amount: Yup.number().required('Amount is required'),
      type: Yup.string().required('Type is required'),
      reason: Yup.string().required('Reason is required'),
    }),
    onSubmit: async (values) => {
      await create({
        variables: {
          input: {
            amount: values.amount.toString(),
            reason: values.reason,
            type: values.type,
            userID: values.user!.id,
          },
        },
      })
      props.onClose(true);
    },
  })

  return (
    <Modal
      show={props.show}
      onBackdropClick={() => props.onClose(false)}
    >
      <div className="modal-content shadow-none">
        <div className="container-xxl">
          <div className="modal-body py-12">
            <form
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {/* UserID Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>User</label>
                <div className='col-lg-8'>
                  <UserPicker
                    value={formik.values.user}
                    onChange={(user) => {
                      formik.setFieldValue('user', user)
                    }}
                  />
                  {formik.touched.user && formik.errors.user && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.user}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* TxType Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Type</label>
                <div className='col-lg-8'>
                  <Select
                    options={typeOptions}
                    id="type"
                    name="type-select"
                    className="react-select"
                    classNamePrefix="select"
                    value={typeOptions.find((e) => e.value === formik.values.type)}
                    onChange={(event: SingleValue<{ label: string, value: TransactionType }>) => {
                      formik.setFieldValue('type', event?.value);
                    }}
                  />
                  {formik.touched.type && formik.errors.type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.type}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Amount Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Amount</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Amount'
                    {...formik.getFieldProps('amount')}
                    type='number'
                    className={
                      formik.touched.amount && formik.errors.amount
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.amount && formik.errors.amount && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.amount}</div>
                    </div>
                  )}
                  {formik.values.amount < 0 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-danger'>Warning: withdraws from the account</div>
                    </div>
                  )}
                  {formik.values.amount > 0 && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-success'>Deposit to account</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Reason Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Comment</label>
                <div className='col-lg-8'>
                  <input
                    placeholder='Comment'
                    {...formik.getFieldProps('reason')}
                    type='text'
                    className={
                      formik.touched.reason && formik.errors.reason
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    autoComplete='off'
                  />
                  {formik.touched.reason && formik.errors.reason && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.reason}</div>
                    </div>
                  )}
                </div>
              </div>

              {/* Submit Button */}
              <div className='modal-footer' style={{ height: '50px' }}>
                <div className='d-flex flex-row mb-6'>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                  <div className="px-2" />
                  <div>
                    <button type='button' onClick={() => props.onClose(false)} className='btn btn-secondary'>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}


type SyncCostsModalProps = {
  onClose: (created: boolean) => void,
  show: boolean,
}

export const SyncCostsModal = (props: SyncCostsModalProps) => {
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: string; }>({}) // any
  const [syncCosts] = useMutation(MUTATION_SYNC_COSTS)
  
  const formik = useFormik({
    initialValues: {
      date: new Date(),
    },
    validationSchema: Yup.object().shape({
      date: Yup.date().required('Date is required'),
    }),
    onSubmit: async (values) => {
      const response = await syncCosts({
        variables: {
          date: values.date,
        }
      })
      setErrorMessages(response.data!.balanceSyncCosts.errorMessages)
      props.onClose(true);
    },
  })

  return (
    <Modal
      show={props.show}
      onBackdropClick={() => props.onClose(false)}
    >
      <div className="modal-content shadow-none">
        <div className="container-xxl">
          <div className="modal-body py-12">
            <form
              className='form'
              onSubmit={formik.handleSubmit}
              noValidate
            >
              {/* Date Input */}
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Date</label>
                <div className='col-lg-8'>
                  <Flatpickr
                    value={formik.values.date}
                    onChange={(date) => {
                      formik.setFieldValue('date', date[0])
                    }}
                    style={{ width: 'auto' }}
                    options={{
                      mode: "single",
                    }}
                    className={
                      formik.touched.date && formik.errors.date
                        ? 'form-control is-invalid'
                        : 'form-control'
                    }
                    placeholder='Pick date'
                  />
                </div>
              </div>
              {errorMessages && Object.keys(errorMessages).length > 0 && (
                <div className="mh-200px overflow-auto">
                  {Object.keys(errorMessages).map((key) => (<div>
                    <span className="text-error">Advertiser <b>{key}</b>: </span>
                    <span>{errorMessages[key]}</span>
                    <br />
                  </div>))}
                </div>
              )}

              {/* Submit Button */}
              <div className='modal-footer' style={{ height: '50px' }}>
                <div className='d-flex flex-row mb-6'>
                  <div>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                  <div className="px-2" />
                  <div>
                    <button type='button' onClick={() => props.onClose(false)} className='btn btn-secondary'>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  )
}