import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel } from './_models'
import * as authStorage from './AuthHelpers'
import { WithChildren } from '../../../../_metronic/helpers'
import { useLazyQuery, useQuery } from '@apollo/client'
import { QUERY_MY_FULL_PROFILE } from '../../../../api/v2/gql/user'
import { MyProfileQuery } from '../../../../api/v2/gql/gen/graphql'
import { client } from '../../../../api/v2/gql/client'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel) => void
  refresh: () => void,
  currentUser: MyProfileQuery['myProfile'] | undefined
  setCurrentUser: Dispatch<SetStateAction<MyProfileQuery['myProfile'] | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authStorage.getAuth(),
  saveAuth: () => { },
  refresh: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authStorage.getAuth())
  const [currentUser, setCurrentUser] = useState<MyProfileQuery['myProfile'] | undefined>()
  // const [queryProfile] = useLazyQuery(QUERY_MY_FULL_PROFILE)

  const refresh = () => {
    client.query({
      query: QUERY_MY_FULL_PROFILE,
    }).then((response) => {
      if (response.data) {
        setCurrentUser(response.data.myProfile)
      }
    })
  }

  const saveAuth = (auth: AuthModel) => {
    setAuth(auth)
    refresh()
    if (auth) {
      authStorage.setAuth(auth)
    } else {
      authStorage.removeAuth()
    }
  }

  const logout = () => {
    setAuth(undefined)
    authStorage.removeAuth()
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, refresh, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [queryProfile] = useLazyQuery(QUERY_MY_FULL_PROFILE)

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          await queryProfile().then((response) => {
            if (response.data) {
              setCurrentUser(response.data.myProfile)
            }
          })
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.access_token) {
      requestUser()
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
