import React, { useMemo, useState } from 'react'
import { KTCardBody } from '../../../_metronic/helpers';
import { Column, Row, useTable } from 'react-table';
import clsx from 'clsx';
import { FindTransactionsQuery } from '../../../api/v2/gql/gen/graphql';

type Item = FindTransactionsQuery['findTransactions']['transactions'][number]

interface TransactionTableProps {
  data: Array<Item>
}

const TransactionTable: React.FC<TransactionTableProps> = ({ data }) => {
  const columns = useMemo<Array<Column<Item>>>(() => [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'User',
      Cell: (cell) => <span>{[cell.data[cell.row.index].balance.user.advertiserProfile.fname, cell.data[cell.row.index].balance.user.advertiserProfile.lname].join(' ')}</span>
    },
    {
      Header: 'Before',
      accessor: 'before',
      Cell: (cell) => <span>{parseFloat(cell.value).toFixed(2)}</span>
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (cell) => {
        return <span className={clsx({ 'text-success': parseFloat(cell.value) > 0, 'text-danger': parseFloat(cell.value) < 0 })}>{parseFloat(cell.value) > 0 ? '+' : parseFloat(cell.value) < 0 ? '-' : ''}{Math.abs(parseFloat(cell.value)).toFixed(2)}</span>
      }
    },
    {
      Header: 'After',
      accessor: 'after',
      Cell: (cell) => <span>{parseFloat(cell.value).toFixed(2)}</span>
    },
    {
      Header: 'Reason',
      accessor: 'reason',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      Cell: (cell) => <span>{cell.value?.toLocaleString() ?? ''}</span>
    }
  ], [])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <KTCardBody className='p-0'>
      <div className='table-responsive'>
        <table
          id='kt_table_transactions'
          className='table align-middle table-row-dashed fs-6 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headerGroups[0].headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{ borderBottom: 'none' }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ verticalAlign: 'middle' }}>{column.render('Header') as React.ReactNode}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Item>, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>
                          {cell.render('Cell') as React.ReactNode}
                        </td>
                      )
                    })}
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </KTCardBody>
  )
}

export default TransactionTable
