import clsx from 'clsx'
import { useState } from 'react'
import { KTIcon } from '../../../helpers'
import { useLayout } from '../../core'
import { DefaultTitle } from '../header/page-title/DefaultTitle'
import { Link, useLocation } from 'react-router-dom'



const Toolbar1 = () => {
  const { classes } = useLayout()
  const location = useLocation()
  const createCampaign = "campaigns/new"
  const createDsp = "dsps/new"
  const createCreative = "creatives/new"

  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const renderCreateButton = () => {
    if (location.pathname === '/creatives' || location.pathname.startsWith('/creatives/')) {
      return (
        <Link
          className='btn bg-body btn-active-color-primary'
          id='kt_toolbar_primary_button'
          data-bs-theme='light'
          to={createCreative}
        >
          Create
        </Link>
      )
    }

    if (location.pathname === '/creatives/new' || RegExp('/creatives/(\d+)').test(location.pathname)) {
      return (<></>)
    }


    if (location.pathname === '/campaigns/new' || RegExp('/campaigns/(\d+)').test(location.pathname)) {
      return (<></>)
    }

    if (location.pathname === '/dsps/new' || RegExp('/dsps/(\d+)').test(location.pathname)) {
      return (<></>)
    }

    if (location.pathname === '/dsps') {
      return (<>
        <Link
          className='btn bg-body btn-active-color-primary'
          id='kt_toolbar_primary_button'
          data-bs-theme='light'
          to={createDsp}
        >
          Create
        </Link>
      </>)
    }

    return (<>
      <Link
        className='btn bg-body btn-active-color-primary'
        id='kt_toolbar_primary_button'
        data-bs-theme='light'
        to={createCampaign}
      >
        Create
      </Link>
    </>)
  }

  return (
    <>
      <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
        >
          <DefaultTitle />

          <div className='d-flex align-items-center py-1'>
            {/* Кнопка Create */}
            {renderCreateButton()}
          </div>
        </div>
      </div>
    </>
  )
}

export { Toolbar1 }