import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu, ThemeModeSwitcher } from '../../../partials'
import { useAuth } from '../../../../app/modules/auth'
import { useLazyQuery } from '@apollo/client'
import { QUERY_CURRENT_BALANCE } from '../../../../api/v2/gql/balance'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const { currentUser } = useAuth()
  const [userBalance, setUserBalance] = useState<number>(0)
  const [fetchBalance] = useLazyQuery(QUERY_CURRENT_BALANCE)

  useEffect(() => {
    const updateBalance = async () => {
      if (!currentUser) return;
      try {
        const response = await fetchBalance();
        if (response.data) {
          setUserBalance(parseFloat(response.data.myProfile.balance.amount));
        }
      } catch (error) {
        console.error('Failed to fetch user balance:', error)
      }
    }

    updateBalance();

    const intervalId = setInterval(updateBalance, 60 * 1000)
    return () => clearInterval(intervalId)
  }, [currentUser])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <div className={clsx('d-flex align-items-center mt-2', toolbarButtonMarginClass)}>
          <span className='form-label fw-bolder text-gray-500 fs-6 me-2'>Balance:</span>
          <label className='form-label fw-bolder text-gray-900 fs-6'>
            {`$${userBalance.toFixed(2)}`}
          </label>
        </div>
        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        {/* end::Theme mode */}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={toAbsoluteUrl('/media/avatars/blank.png')}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export { Topbar }
