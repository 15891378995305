import {useEffect, useMemo, useState} from 'react'
import {Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/StatsHeaderColumn'
import {Stats} from './Stats'
import {KTCardBody} from '../../../../_metronic/helpers'
import {StatsRow} from './StatsRow'
import {StatsListLoading} from './StatsListLoading'
import {Pagination} from '../../../helpers/pagination/Pagination'
import * as XLSX from 'xlsx'
import {StatsListHeader} from './StatsHeader'
import {StatsSelectOptions} from './model/StatsInterface'
import {useAuth} from '../../../modules/auth'
import { UserRole } from '../../../../api/v2/gql/gen/graphql'

const StatsTable = ({campID}) => {
  const {currentUser} = useAuth()
  const [stats, setStats] = useState([])
  const [statsData, setStatsData] = useState<Stats[]>(stats)
  const [isLoading, setIsLoading] = useState(false)
  const [pageSize, setPageSize] = useState(20)
  const [pagination, setPagination] = useState({pageNumber: 1, pageSize: pageSize})
  const [totalCount, setTotalCount] = useState(0)
  const [currentData, setCurrentData] = useState(
    statsData?.length ? statsData.slice(0, pageSize) : []
  )
  const statsOptions: StatsSelectOptions = {
    event_date: {value: 'event_date', label: 'Date'},
    ad_type: {value: 'ad_type', label: 'Ad Type'},
    category: {value: 'category', label: 'Source category'},
    camp: {value: 'camp', label: 'Campaign'},
    camp_id: {value: 'camp_id', label: 'Campaign ID'},
    cat_camp: {value: 'cat_camp', label: 'Campaign Category'},
    link: {value: 'link', label: 'Creative'},
    creative_id: {value: 'creative_id', label: 'Creative ID'},
    country: {value: 'country', label: 'Country'},
    region_name: {value: 'region_name', label: 'Region'},
    OS: {value: 'os', label: 'OS'},
    browser: {value: 'browser', label: 'Browser'},
    mobile_brand: {value: 'mobile_brand', label: 'Mobile carrier'},
    source: {value: 'source', label: 'Source'},
    ssp: {value: 'ssp', label: 'SSP'},
  }

  if (currentUser?.role === UserRole.Admin) {
    statsOptions.adv = {value: 'adv', label: 'Advertiser'}
    statsOptions.adv_id = {value: 'adv_id', label: 'Advertiser ID'}
  }

  type SortKey = keyof Stats
  type SortState = {
    id: SortKey
    desc: boolean
  }
  const [sortConfig, setSortConfig] = useState<SortState>({
    id: 'cost',
    desc: false,
  })

  const [selectedFields, setSelectedFields] = useState<any>([statsOptions.event_date])

  const handlePageSizeInHeader = (newPageSize) => {
    setPageSize(newPageSize)
  }

  useEffect(() => {
    setStatsData(stats)
    setPagination({pageNumber: 1, pageSize: pageSize})
  }, [stats, pageSize])

  useEffect(() => {
    if (statsData !== null) {
      const startIndex = (pagination.pageNumber - 1) * pageSize
      const endIndex = startIndex + pageSize
      setTotalCount(statsData.length)
      setCurrentData(statsData.slice(startIndex, endIndex))
    }
  }, [statsData, pagination, pageSize])

  useEffect(() => {
    const sortedData = [...stats].sort((a, b) => {
      const id = sortConfig.id
      const desc = sortConfig.desc
      const valueA = a[id]
      const valueB = b[id]
      if (valueA < valueB) {
        return desc ? 1 : -1
      }
      if (valueA > valueB) {
        return desc ? -1 : 1
      }
      return 0
    })
    setStats(sortedData)
  }, [sortConfig])

  let defaultColumns = [
    {
      Header: 'Impressions',
      accessor: 'impressions',
    },
    {
      Header: 'Clicks',
      accessor: 'clicks',
    },
    {
      Header: 'CTR',
      accessor: 'ctr',
    },
    {
      Header: 'Income',
      accessor: 'income',
    },
    {
      Header: 'Cost',
      accessor: currentUser?.role !== 'admin' ? 'income' : 'cost',
    },
    {
      Header: 'ROI',
      accessor: 'roi',
    },
    {
      Header: 'Profit',
      accessor: 'profit',
    },
    {
      Header: 'Conv1',
      accessor: 'conv1',
    },
    {
      Header: 'CR1',
      accessor: 'cr1',
    },
    {
      Header: 'Conv1 Price',
      accessor: 'conv1_price',
    },
    {
      Header: 'Conv2',
      accessor: 'conv2',
    },
    {
      Header: 'Conv2 Price',
      accessor: 'conv2_price',
    },
    {
      Header: 'CR2',
      accessor: 'cr2',
    },
  ]

  if (currentUser?.role !== UserRole.Admin) {
    defaultColumns = defaultColumns.filter((column) => {
      return !['Income', 'ROI', 'Profit'].includes(column.Header)
    })
  }

  const columns = useMemo(() => {
    const selectedColumns = selectedFields.map((field) => ({
      Header: field.label,
      accessor: field.value.toLowerCase(),
      sortType: (rowA, rowB, id) => {
        const valueA = rowA.values[id]
        const valueB = rowB.values[id]
        if (valueA < valueB) {
          return sortConfig.desc ? 1 : -1
        }
        if (valueA > valueB) {
          return sortConfig.desc ? -1 : 1
        }
        return 0
      },
    }))

    const combinedColumns = [...selectedColumns, ...defaultColumns]

    return combinedColumns
  }, [selectedFields, sortConfig])

  const handleSortClick = (columnId) => {
    if (currentData) {
      const isSameColumn = sortConfig.id === columnId
      const isDesc = isSameColumn ? !sortConfig.desc : false
      setSortConfig({
        id: columnId,
        desc: isDesc,
      })
    }
  }

  const handleExportData = () => {
    const exportRows = statsData.filter((row) => {
      const hasAllColumns = columns.every((col) => {
        const accessor = col.accessor
        return (
          row.hasOwnProperty(accessor) &&
          row[accessor] !== undefined &&
          row[accessor] !== null &&
          row[accessor] !== ''
        )
      })
      return hasAllColumns
    })

    const worksheet = XLSX.utils.json_to_sheet(
      exportRows.map((row) => {
        const rowData = {}
        columns.forEach((col) => {
          rowData[col.Header] = row[col.accessor]
        })
        return rowData
      })
    )
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Stats Data')
    XLSX.writeFile(workbook, 'stats_data.xlsx')
  }

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable<Stats>({
    columns,
    data: useMemo(() => currentData, [currentData]),
  })
  const handleDataChange = (newData, selected) => {
    if (newData !== null) {
      setStats(newData)
      const isEventDateSelected = selected.some((field) => field.value === 'event_date')
      const idSort = isEventDateSelected ? 'event_date' : 'cost'
      setSortConfig({
        id: idSort,
        desc: true,
      })
    }
    setSelectedFields(selected)
    setIsLoading(false)
  }

  return (
    <KTCardBody className='py-4'>
      <div className='row align-items-center mb-3'>
        <div className='col'>
          <StatsListHeader
            handleStatsDataReceived={handleDataChange}
            handleExportData={handleExportData}
            pageSize={pageSize}
            handlePageSizeInHeader={handlePageSizeInHeader}
            statsOptions={statsOptions}
            setLoading={setIsLoading}
            campID={campID}
          />
        </div>
      </div>

      <div className='table-responsive'>
        <table
          id='kt_table_stats'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column) => (
                <th
                  key={column.id}
                  className='cursor-pointer'
                  onClick={() => handleSortClick(column.id)}
                >
                  <CustomHeaderColumn column={column} sortOrder={sortConfig} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Stats>, i) => {
                prepareRow(row)
                return <StatsRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={12}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    No matching records found
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Pagination
        className='mt-10 mb-5'
        currentPage={pagination.pageNumber}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageChange={(page: number) => {
          setPagination({...pagination, pageNumber: page})
        }}
      />
      {isLoading && <StatsListLoading />}
    </KTCardBody>
  )
}

export {StatsTable}
