import { gql } from "./gen";

export const QUERY_GET_CAMPAIGN_CREATIVES = gql(`
  query GetCampaignCreatives($id: Int64!, $pagination: PaginationInput!) {
    getCampaign(id: $id) {
      creatives(pagination:$pagination){
        id
        enabled
        name
        type
        title
        description
        size
        imgRurl
        rurl
        iconRurl
        createdAt
      }
    }
  }
`)

export const QUERY_FIND_CAMPAIGNS = gql(`
  query FindCampaigns($advID: Int64, $type: CampaignType, $payType: PayType, $active: Boolean, $query: String, $pagination: PaginationInput!, $creativesPagination: PaginationInput!) {
    findCampaigns(
      advID: $advID
      type: $type
      payType: $payType
      active: $active
      query: $query
      pagination: $pagination
    ) {
      pageInfo {
        totalCount
      }
      campaigns {
        id
        enabled
        type
        name
        bid
        roi
        dailyCls
        dailyImp
        dailyCost
        totalCls
        totalImp
        totalCost
        advertiser{
          fname
          lname
          advertiserID
          user{
            email
            balance{
              amount
              loadLimit
              currentBalance
            }
          }
        }
        payType
        convTrack
        createdAt
        limitStatus
        creatives(pagination: $creativesPagination) {
          id
          enabled
          name
          type
          imgRurl
          rurl
          size
          title
          description
        }
        moderationStatus
        moderationReason
      }
    }
  }
`);

export const QUERY_FIND_CREATIVES = gql(`
  query FindCreatives($query:String, $advID:Int64, $type: CreativeType, $pagination:PaginationInput!) {
    findCreatives(query:$query, advID:$advID, type:$type, pagination:$pagination){
      pageInfo{
        totalCount
      }
      creatives{
        id
        enabled
        name
        type
        title
        description
        size
        imgRurl
        rurl
        iconRurl
        createdAt
      }
    }
  }
`)

export const QUERY_GET_CAMPAIGN_ADVERTISER = gql(`
  query GetCampaignAdvertiser($id: Int64!) {
    getCampaign(id: $id) {
      id
      advertiser{
        id
        advertiserID
      }
    }
  }
`)

export const QUERY_GET_CAMPAIGN = gql(`
  query GetCampaign($id: Int64!) {
    getCampaign(id: $id) {
      id
      name
      enabled
      type
      payType
      category
      convTrack
      mlConv
      mlConvMin
      mlABTest
      bid
      bidder
      roi
      subsTTL
      lastModelImps
      mlImpsStep
      advertiser{
        id
        advertiserID
        telegram
        fname
        lname
        user {
          id
          email
          role
        }
        createdAt
      }
      sourceAutorules{
        id
        days
        clickhouseExp
        createdAt
      }
      limitsFilter {
        dailyLimitUniq
        dailyLimitCls
        dailyLimitImp
        dailyLimitCost
        totalLimitCls
        totalLimitImp
        totalLimitCost
      }
      countriesFilter {
        whitelistCountries{
          id
          name
        }
      }
      regionsFilter {
        whitelistRegions{
          id
          name
        }
      }
      citiesFilter {
        whitelistCities{
          id
          name
        }
      }
      brandsFilter{
        whitelistBrands
        connectionType
        disableProxy
      }
      categoriesFilter{
        whitelistCategories
        blacklistCategories
      }
      devicesFilter {
        whitelistExec
      }
      sourceFilter {
        whitelistSourceID
        whitelistSSP
        blacklistSourceID
        blacklistSSP
      }
      languageFilter{
        whitelistLanguageCodes
      }
      ipRangeFilter {
        whitelistIPRanges
        blacklistIPRanges
      }
    }
  }
`)

export const MUTATION_CREATE_CAMPAIGN = gql(`
  mutation CreateCampaignFull($input:CreateCampaignFullInput!){
    createCampaignFull(input:$input){
      id
    }
  }
`);

export const MUTATION_UPDATE_CAMPAIGN = gql(`
  mutation UpdateCampaign($id:Int64!, $input:UpdateCampaignFullInput!) {
    updateCampaignFull(id:$id, input:$input){
      id
    }
  }
`)

export const MUTATION_ATTACH_CREATIVE = gql(`
  mutation AttachCreative($campaignID:Int64!, $creativeID:Int64!) {
    attachCreative(campaignID:$campaignID,creativeID:$creativeID)
  }
`)

export const MUTATION_DETACH_CREATIVE = gql(`
  mutation DetachCreativeToCampaign($campaignID:Int64!, $creativeID:Int64!) {
    detachCreative(campaignID:$campaignID,creativeID:$creativeID)
  }
`)

export const MUTATION_DELETE_CAMPAIGN = gql(`
  mutation DeleteCampaign($id:Int64!) {
    deleteCampaign(id:$id)
  }
`)

export const MUTATION_DUPLICATE_CAMPAIGN = gql(`
  mutation DuplicateCampaign($id:Int64!) {
    duplicateCampaign(id:$id){
      id
    }
  }
`)