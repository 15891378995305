import { getUniqueIdWithPrefix } from '../../../_metronic/assets/ts/_utils'
import AutoruleMetricsControl, { Autorule, RuleKey } from './AutoRules'
import RulesInfo from './RulesInfo'

export const GetInitialAutorule: () => Autorule = () => {
  return {
    frontend_id: getUniqueIdWithPrefix('autorule'),
    days: 7,
    metrics: [
      {
        frontend_id: getUniqueIdWithPrefix('metric'),
        metric: 'CLICKS',
        comparison: '>',
        value: '1000',
      },
      {
        frontend_id: getUniqueIdWithPrefix("metric_conv"),
        metric: 'CONV',
        comparison: '<',
        value: '1'
      }
    ],
  }
}

const RuleComponent = ({
  rules,
  onRulesChanged,
}: {
  rules: Autorule[],
  onRulesChanged: (rules: Autorule[]) => void,
}) => {
  return (
    <div className='accordion-body row'>
      {rules.map((ruleGroup) => (
        <div className='card-body border-top p-9 d-flex'>
          <div className='col-md-8'>
            <AutoruleMetricsControl
              rule={ruleGroup}
              handleRuleChange={(rule) => {
                const updated = [...rules];
                updated[updated.findIndex(e => e.frontend_id === rule.frontend_id)] = rule;
                onRulesChanged(updated);
              }}
              handleDelete={() => {
                const updated = [...rules].filter(e => e.frontend_id !== ruleGroup.frontend_id);
                onRulesChanged(updated);
              }}
            />
            <div className=' col-md-9 mb-3 d-flex justify-content-center '>
              <button
                type='button'
                className='btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary'
                style={{ width: '32px', height: '32px', padding: 0 }}
                onClick={() => {
                  const updatedRules = [...rules]
                  updatedRules[updatedRules.findIndex(e => e.frontend_id === ruleGroup.frontend_id)].metrics.push({ frontend_id: getUniqueIdWithPrefix('metric'), metric: 'CLICKS', comparison: '>', value: '1000' })
                  onRulesChanged(updatedRules);
                }}
              >
                <i
                  className='fas fa-plus text-primary fs-5'
                  style={{ verticalAlign: 'middle', padding: 0 }}
                ></i>
              </button>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='mb-2 d-flex align-items-center'>
              <label htmlFor={`last_days_${ruleGroup.frontend_id}`} className='form-label me-2'>
                Last Days
              </label>
              <select
                id={`last_days_${ruleGroup.frontend_id}`}
                name={`last_days_${ruleGroup.frontend_id}`}
                className='form-select'
                value={ruleGroup.days}
                onChange={(e) => {
                  const updatedRules = [...rules]
                  console.log(updatedRules.findIndex(e => e.frontend_id === ruleGroup.frontend_id))
                  updatedRules[updatedRules.findIndex(e => e.frontend_id === ruleGroup.frontend_id)].days = Number(e.target.value);
                  onRulesChanged(updatedRules);
                }}
              >
                {Array.from({ length: 30 }, (_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {'Last ' + (index + 1) + ' days'}
                  </option>
                ))}
              </select>
            </div>
            <RulesInfo />
          </div>
        </div>
      ))
      }

      <div className="form-check form-switch form-check-custom form-check-solid mt-2 ms-auto">
        <button type="button" className="btn btn-primary ms-2 me-4" onClick={(e) => {
          e.preventDefault();
          onRulesChanged([...rules, GetInitialAutorule()])
        }}>Add Rule</button>
      </div>
    </div >
  )
}

export default RuleComponent
