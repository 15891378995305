import { gql } from "./gen";

export const MUTATION_SIGNIN = gql(`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`)

export const MUTATION_REFRESH_TOKEN = gql(`
  mutation refreshToken($token:String!) {
    refreshToken(refreshToken:$token){
      accessToken
      refreshToken
    }
  }
`)

export const MUTATION_UPDATE_EMAIL = gql(`
  mutation updateEmail($userID:ID!,$email:String!,$password:String) {
    updateEmail(userID:$userID,email:$email,password:$password){
      id
    }
  }
`)

export const MUTATION_UPDATE_PASSWORD = gql(`
  mutation updatePassword($userID:ID!,$newPassword:String!,$currentPassword:String) {
    updatePassword(userID:$userID,newPassword:$newPassword,currentPassword:$currentPassword){
      id
    }
  }
`)

export const MUTATION_REGISTER_ADV = gql(`
  mutation registerAsAdvertiser($input:RegisterAsAdvertiserInput!) {
    registerAsAdvertiser(input:$input){
      accessToken
      refreshToken
    }
  }
`)