/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useLayout } from '../core'
import { ReactComponent as TorIcon } from '../../assets/keenicons/torIcons/textlogo.svg';

const Footer: FC = () => {
  const { classes } = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1' style={{ display: 'flex', alignItems: 'center' }}>
          <span className='text-muted fw-semibold me-2'>{new Date().getFullYear()} &copy;</span>
          <div style={{ width: '100px', height: '150px' }}>
            <TorIcon className='text-gray-800' style={{ width: '100%', height: '100%' }} />
          </div>
        </div>

        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='mailto:adv@tor.energy?subject=Self Service tor.energy' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export { Footer }
