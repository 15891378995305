import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_CITIES } from '../../api/v2/gql/geo';
import { FindCitiesQuery } from '../../api/v2/gql/gen/graphql';

export type CityMultiPickerResult = FindCitiesQuery['findCities']['cities'][number]

type Props = {
  regionIDs: string[],
  options?: Partial<AsyncPaginateProps<CityMultiPickerResult, GroupBase<CityMultiPickerResult>, { page: number }, true>>
  value: CityMultiPickerResult[] | null
  onChange?: (newValue: MultiValue<CityMultiPickerResult>) => void;
}

export const CityMultiPicker = (props: Props) => {
  const [fetchCities] = useLazyQuery(QUERY_FIND_CITIES)

  const loadOptions: LoadOptions<CityMultiPickerResult, GroupBase<CityMultiPickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchCities({
      variables: {
        query: search,
        regionIDs: props.regionIDs,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findCities.cities,
      hasMore: response.data!.findCities.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.name}`}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}