import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { MixedWidget1 } from '../../../_metronic/partials/widgets'
import {
  getTodayStats,
  getCurrentMonthStats,
  getLast7DaysStats,
  AdminGetCurrentMonthStats,
  AdminGetLast7DaysStats,
  AdminGetTodayStats,
} from '../../../api/stats'
import { useEffect, useState } from 'react'
import { useAuth } from '../../modules/auth'
import { AlertType, generateAlert } from '../../helpers/alert'
import { StatsTable } from './table/StatsTable'
import { KTCard } from '../../../_metronic/helpers'
import { UserRole } from '../../../api/v2/gql/gen/graphql'

const DashboardPage = ({ todayStats, monthStats, last7DaysStats }) => {
  const { currentUser } = useAuth()
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <MixedWidget1
            className='card-xl-stretch mb-xl-8'
            style=' linear-gradient(90deg, rgba(193,22,241,1) 0%, rgba(18,45,196,1) 86%)'
            title='Today'
            data={todayStats}
            currentUser={currentUser}
          />
        </div>
        <div className='col-xl-4'>
          <MixedWidget1
            className='card-xl-stretch mb-xl-8'
            style='linear-gradient(90deg, rgba(18,45,196,1) 0%, rgba(22,241,196,1) 86%)'
            title='Last 7 Days'
            data={last7DaysStats}
            currentUser={currentUser}
          />
        </div>
        <div className='col-xl-4'>
          <MixedWidget1
            className='card-xl-stretch mb-xl-8'
            style='linear-gradient(90deg, rgba(22,241,196,1) 0%, rgba(193,22,241,1) 86%)'
            title='This Month'
            data={monthStats}
            currentUser={currentUser}
          />
        </div>
        <KTCard>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <StatsTable campID={null} />
          </div>
        </KTCard>
      </div>
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const [todayStats, setTodayStats] = useState<any>(
    localStorage.getItem('todayStats') ? JSON.parse(localStorage.getItem('todayStats') || '') : []
  )
  const [monthStats, setMonthStats] = useState<any>(
    localStorage.getItem('monthStats') ? JSON.parse(localStorage.getItem('monthStats') || '') : []
  )
  const [last7DaysStats, setLast7DaysStats] = useState<any>(
    localStorage.getItem('last7DaysStats')
      ? JSON.parse(localStorage.getItem('last7DaysStats') || '')
      : []
  )

  const [lastFetchTime, setLastFetchTime] = useState<number>(
    localStorage.getItem('lastFetchTime')
      ? parseInt(localStorage.getItem('lastFetchTime') || '')
      : 0
  )

  useEffect(() => {
    const fetchData = async () => {
      const user = localStorage.getItem('user')
      if (user !== currentUser?.email) {
        setLastFetchTime(0);
      }
      if (currentUser?.role !== UserRole.Admin) {
        try {
          const currentTime = new Date().getTime()
          const timeDiff = currentTime - lastFetchTime

          if (timeDiff > 180000) {
            getTodayStats(currentUser!.advertiserProfile.advertiserID).then((response) => {
              if (response !== null) {
                const updatedTodayStats = { ...response.data }

                const localStorageTodayStats = localStorage.getItem('todayStats')
                if (localStorageTodayStats) {
                  const parsedTodayStats = localStorageTodayStats
                    ? JSON.parse(localStorageTodayStats)
                    : {}

                  Object.keys(response.data).forEach((key) => {
                    if (response.data[key] > parsedTodayStats[key]) {
                      updatedTodayStats[key + 'IsUp'] = true
                    }
                  })
                }

                setTodayStats(updatedTodayStats)
                localStorage.setItem('todayStats', JSON.stringify(updatedTodayStats))
              }
            })
            getLast7DaysStats(currentUser!.advertiserProfile.advertiserID).then((response) => {
              if (response.data !== null) {
                const updatedLast7DaysStats = { ...response.data[0] }

                const localStorageLast7DaysStats = localStorage.getItem('last7DaysStats')
                if (localStorageLast7DaysStats) {
                  const parsedLast7DaysStats = localStorageLast7DaysStats
                    ? JSON.parse(localStorageLast7DaysStats)
                    : {}

                  Object.keys(response.data[0]).forEach((key) => {
                    if (response.data[0][key] > parsedLast7DaysStats[key]) {
                      updatedLast7DaysStats[key + 'IsUp'] = true
                    } else if (response.data[0][key] < parsedLast7DaysStats[key]) {
                      updatedLast7DaysStats[key + 'IsUp'] = false
                    }
                  })
                }

                setLast7DaysStats(updatedLast7DaysStats)
                localStorage.setItem('last7DaysStats', JSON.stringify(updatedLast7DaysStats))
              }
            })
            getCurrentMonthStats(currentUser!.advertiserProfile.advertiserID).then((response) => {
              if (response !== null) {
                const updatedMonthStats = { ...response.data }

                const localStorageMonthStats = localStorage.getItem('monthStats')
                if (localStorageMonthStats) {
                  const parsedMonthStats = localStorageMonthStats
                    ? JSON.parse(localStorageMonthStats)
                    : {}

                  Object.keys(response.data).forEach((key) => {
                    if (response.data[key] > parsedMonthStats[key]) {
                      updatedMonthStats[key + 'IsUp'] = true
                    }
                  })
                }

                setMonthStats(updatedMonthStats)
                localStorage.setItem('monthStats', JSON.stringify(updatedMonthStats))
              }
            })

            setLastFetchTime(currentTime)
            localStorage.setItem('lastFetchTime', currentTime.toString())
          }
        } catch (error: any) {
          generateAlert(error.error, AlertType.ERROR)
        }
      } else {
        try {
          const currentTime = new Date().getTime()
          const timeDiff = currentTime - lastFetchTime
          if (timeDiff > 180000) {
            AdminGetTodayStats().then((response) => {
              if (response !== null) {
                const updatedTodayStats = { ...response.data }

                const localStorageTodayStats = localStorage.getItem('todayStats')
                if (localStorageTodayStats) {
                  const parsedTodayStats = localStorageTodayStats
                    ? JSON.parse(localStorageTodayStats)
                    : {}

                  Object.keys(response.data).forEach((key) => {
                    if (response.data[key] > parsedTodayStats[key]) {
                      updatedTodayStats[key + 'IsUp'] = true
                    }
                  })
                }

                setTodayStats(updatedTodayStats)
                localStorage.setItem('todayStats', JSON.stringify(updatedTodayStats))
              }
            })
            AdminGetLast7DaysStats().then((response) => {
              if (response?.data) {
                const updatedLast7DaysStats = { ...response.data }

                const localStorageLast7DaysStats = localStorage.getItem('last7DaysStats')
                if (localStorageLast7DaysStats) {
                  const parsedLast7DaysStats = localStorageLast7DaysStats
                    ? JSON.parse(localStorageLast7DaysStats)
                    : {}

                  Object.keys(response.data).forEach((key) => {
                    if (response.data[key] > parsedLast7DaysStats[key]) {
                      updatedLast7DaysStats[key + 'IsUp'] = true
                    } else if (response.data[key] < parsedLast7DaysStats[key]) {
                      updatedLast7DaysStats[key + 'IsUp'] = false
                    }
                  })
                }

                setLast7DaysStats(updatedLast7DaysStats)
                localStorage.setItem('last7DaysStats', JSON.stringify(updatedLast7DaysStats))
              }
            })
            AdminGetCurrentMonthStats().then((response) => {
              if (response !== null) {
                const updatedMonthStats = { ...response.data }

                const localStorageMonthStats = localStorage.getItem('monthStats')
                if (localStorageMonthStats) {
                  const parsedMonthStats = localStorageMonthStats
                    ? JSON.parse(localStorageMonthStats)
                    : {}

                  Object.keys(response.data).forEach((key) => {
                    if (response.data[key] > parsedMonthStats[key]) {
                      updatedMonthStats[key + 'IsUp'] = true
                    }
                  })
                }

                setMonthStats(updatedMonthStats)
                localStorage.setItem('monthStats', JSON.stringify(updatedMonthStats))
              }
            })

            setLastFetchTime(currentTime)
            localStorage.setItem('lastFetchTime', currentTime.toString())
          }
          localStorage.setItem('user', currentUser?.email || '')
        } catch (error: any) {
          generateAlert(error.error, AlertType.ERROR)
        }
      }
    }

    fetchData()
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
      <DashboardPage
        todayStats={todayStats}
        monthStats={monthStats}
        last7DaysStats={last7DaysStats}
      />
    </>
  )
}

export { DashboardWrapper }
