import {AxiosError} from 'axios'
import {$api} from '.'
import {StatsOption} from '../app/pages/dashboard/table/model/StatsInterface'

export const getTodayStats = async (adv_id: number): Promise<any> => {
  try {
    const res = await $api.get(`/stats/today/${adv_id}`)
    return res || null
  } catch (e) {
    const err = e as AxiosError<Error>
    return err?.response?.data ? null : null
  }
}

export const getLast7DaysStats = async (adv_id: number): Promise<any> => {
  try {
    const res = await $api.get(`/stats/last_7_days/${adv_id}`)
    return res || null
  } catch (e) {
    const err = e as AxiosError<Error>
    return err?.response?.data ? null : null
  }
}

export const getCurrentMonthStats = async (adv_id: number): Promise<any> => {
  try {
    const res = await $api.get(`/stats/current_month/${adv_id}`)
    return res || null
  } catch (e) {
    const err = e as AxiosError<Error>
    return err?.response?.data ? null : null
  }
}

export const getStatsByGroup = async (
  adv_id: number,
  date?: string[],
  selectedFields?: string[]
): Promise<any> => {
  try {
    const url = `/stats/${adv_id}`
    return (await $api.post(url, {date, selectedFields})).data
  } catch (e) {
    const err = e as AxiosError<Error>
    throw err?.response?.data
  }
}

export const getStatsByCampaign = async (
  adv_id: number,
  camp_id: number,
  date?: string[],
  selectedFields?: string[]
): Promise<any> => {
  try {
    const url = `/stats/${adv_id}/${camp_id}`
    return (await $api.post(url, {date, selectedFields})).data
  } catch (e) {
    const err = e as AxiosError<Error>
    throw err?.response?.data
  }
}

export const AdminGetTodayStats = async (): Promise<any> => {
  try {
    const res = await $api.get(`/stats/admin/today`)
    return res || null
  } catch (e) {
    const err = e as AxiosError<Error>
    return err?.response?.data ? null : null
  }
}

export const AdminGetLast7DaysStats = async (): Promise<any> => {
  try {
    const res = await $api.get(`/stats/admin/last_7_days`)
    return res
  } catch (e) {
    const err = e as AxiosError<Error>
    return err?.response?.data ? null : null
  }
}

export const AdminGetCurrentMonthStats = async (): Promise<any> => {
  try {
    const res = await $api.get(`/stats/admin/current_month`)
    return res || null
  } catch (e) {
    const err = e as AxiosError<Error>
    return err?.response?.data ? null : null
  }
}

export const AdminGetStatsByGroup = async (
  date?: string[],
  selectedFields?: string[]
): Promise<any> => {
  try {
    const url = `/stats/admin`
    return (await $api.post(url, {date, selectedFields})).data
  } catch (e) {
    const err = e as AxiosError<Error>
    throw err?.response?.data
  }
}
