import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';
import { FindUsersWithProfileQuery } from '../../api/v2/gql/gen/graphql';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_USERS_WITH_PROFILE } from '../../api/v2/gql/user';

export type AdvPickerResult = FindUsersWithProfileQuery['findUsers']['users'][number]

type Props = {
  options?: Partial<AsyncPaginateProps<AdvPickerResult, GroupBase<AdvPickerResult>, { page: number }, false>>
  value: AdvPickerResult | null | undefined
  onChange?: (newValue: AdvPickerResult | null) => void;
}

export const AdvPicker = (props: Props) => {
  const [fetchAdvs] = useLazyQuery(QUERY_FIND_USERS_WITH_PROFILE)

  const loadOptions: LoadOptions<AdvPickerResult, GroupBase<AdvPickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchAdvs({
      variables: {
        query: search,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findUsers.users,
      hasMore: response.data!.findUsers.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate<AdvPickerResult, GroupBase<AdvPickerResult>, { page: number }>
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.advertiserProfile.advertiserID} - ${u.email}`}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}