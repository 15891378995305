// import Uppy from '@uppy/core';

// import '@uppy/core/dist/style.min.css';
// import '@uppy/dashboard/dist/style.min.css';
// import '@uppy/file-input/dist/style.min.css';
// import { Dashboard } from '@uppy/react';

import { FC, useEffect, useState } from "react"
import XHRUpload from '@uppy/xhr-upload';
import { basePath } from '../../../api';
import { getAuth } from '../auth';
import { getUniqueIdWithPrefix } from '../../../_metronic/assets/ts/_utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useFormik } from 'formik';
import clsx from 'clsx';
import Select, { SingleValue } from 'react-select'
import { CreativeType } from "../../../api/v2/gql/gen/graphql";

export type CreativeControlDTO = {
  name: string
  rurl: string
  pushTitle: string,
  pushDescription: string,
  pushIconUrl: string,
  pushImageUrl: string,
  bannerTitle: string,
  bannerDescription: string,
  bannerImageUrl: string,
  bannerSize: string,
}

type Props = {
  type: CreativeType
  initial: CreativeControlDTO,
  onChanged?: (dto: CreativeControlDTO) => void
}

const macroses = {
  click_id: {
    value: "{click_id}",
    hint: "A unique click identifier used for conversion tracking.",
  },
  source: {
    value: "{source}",
    hint: "The ID of the traffic source.",
  },
  cost: {
    value: "{cost}",
    hint: "The cost per 1 impression (CPM) or click (CPC).",
  },
  creative_name: {
    value: "{creative_name}",
    hint: "The name of the creative/advertisement.",
  },
  creative_id: {
    value: "{creative_id}",
    hint: "The unique identifier of the creative/advertisement.",
  },
  camp_name: {
    value: "{camp_name}",
    hint: "The name of the advertising campaign.",
  },
  camp_id: {
    value: "{camp_id}",
    hint: "The unique identifier of the advertising campaign.",
  },
  banner_size: {
    value: "{banner_size}",
    hint: "The dimensions of the banner advertisement.",
  },
  os: {
    value: "{os}",
    hint: "Returns the name of the operating system.",
  },
  browser: {
    value: "{browser}",
    hint: "Returns the name of the browser.",
  },
  device_type: {
    value: "{device_type}",
    hint: "Returns the type of device, such as mobile or desktop.",
  },
  mobile_carrier: {
    value: "{mobile_carrier}",
    hint: "Returns the name of the mobile carrier/provider."
  },
}

export const CreativeControl: FC<Props> = ({ type, initial, onChanged }) => {
  const [name, setName] = useState(initial?.name ?? '')
  const [rurl, setRurl] = useState(initial?.rurl ?? '')
  const [isValid, setIsValid] = useState(true)

  // Push
  const [pushTitle, setPushTitle] = useState(initial?.pushTitle ?? '')
  const [pushDescription, setPushDescription] = useState(initial?.pushDescription ?? '')
  const [pushIconUrl, setPushIconUrl] = useState(initial?.pushIconUrl ?? '')
  const [pushImageUrl, setPushImageUrl] = useState(initial?.pushImageUrl ?? '')

  // Banner
  const [bannerTitle, setBannerTitle] = useState(initial?.bannerTitle ?? '')
  const [bannerDescription, setBannerDescription] = useState(initial?.bannerDescription ?? '')
  const [bannerImageUrl, setBannerImageUrl] = useState(initial?.bannerImageUrl ?? '')
  const [bannerSize, setBannerSize] = useState(initial?.bannerSize ?? '')

  useEffect(() => {
    if (onChanged !== null) {
      onChanged!({
        rurl: rurl,
        name: name,
        bannerDescription: bannerDescription,
        bannerImageUrl: bannerImageUrl,
        bannerSize: bannerSize,
        bannerTitle: bannerTitle,
        pushDescription: pushDescription,
        pushIconUrl: pushIconUrl,
        pushImageUrl: pushImageUrl,
        pushTitle: pushTitle,
      })
    }
  }, [
    name,
    rurl,
    pushTitle,
    pushDescription,
    pushIconUrl,
    pushImageUrl,
    bannerTitle,
    bannerDescription,
    bannerImageUrl,
    bannerSize,
  ])

  // Native
  // const [nativeTitle, setNativeTitle] = useState('')
  // const [nativeDescription, setNativeDescription] = useState('')
  // const [nativeImageUrl, setNativeImageUrl] = useState('')

  switch (type) {
    case "banner":
      return (
        <>
          <div className="my-3">
            <label htmlFor="c_name" className="form-label">Name</label>
            <input id="c_name" name="c_name" className="form-control form-control-solid"
              value={name} onChange={(e) => {
                setName(e.target.value)
              }} />
          </div>
          <div className="my-3">
            <label htmlFor="c_rurl" className="form-label">URL</label>
            <input id="c_rurl" name="c_rurl" className={`form-control form-control-solid ${isValid ? '' : 'is-invalid'}`}
              value={rurl} onChange={(e) => {
                setRurl(e.target.value.replace(' ', ''))
              }} />
            {Object.keys(macroses).map((key) => {
              return (<>
                <OverlayTrigger key={key} overlay={
                  <Tooltip>{macroses[key].hint}</Tooltip>
                }>
                  <a
                    className={clsx(
                      'badge cursor-pointer fs-8 fw-bold my-2 py-3 px-4 me-2',
                      { 'badge-light-success': rurl.indexOf(macroses[key].value) > -1 },
                      { 'badge-light-primary': rurl.indexOf(macroses[key].value) === -1 }
                    )}
                    onClick={(e) => {
                      e.preventDefault()
                      if (rurl.indexOf(macroses[key].value) > -1) {
                        setRurl(rurl.replaceAll(macroses[key].value, ""))
                      } else {
                        setRurl(`${rurl}${macroses[key].value}`)
                      }
                    }}
                  >
                    {macroses[key].value}
                  </a>
                </OverlayTrigger>
              </>)
            })}
          </div>
          <BannerControl
            title={bannerTitle}
            onTitleChanged={setBannerTitle}
            desc={bannerDescription}
            onDescChanged={setBannerDescription}
            imageUrl={bannerImageUrl}
            onImageUrlChanged={setBannerImageUrl}
            size={bannerSize}
            onSizeChanged={setBannerSize}
          />
        </>
      )
    case "pops":
      return (
        <>
          <div className="my-3">
            <label htmlFor="c_name" className="form-label">Name</label>
            <input id="c_name" name="c_name" className="form-control form-control-solid"
              value={name} onChange={(e) => {
                setName(e.target.value)
              }} />
          </div>
          <div className="my-3">
            <label htmlFor="c_rurl" className="form-label">URL</label>
            <input id="c_rurl" name="c_rurl" className={`form-control form-control-solid ${isValid ? '' : 'is-invalid'}`}
              value={rurl} onChange={(e) => {
                setRurl(e.target.value.replace(' ', ''))
              }} />
          </div>
          {Object.keys(macroses).map((key) => {
            return (<>
              <OverlayTrigger key={key} overlay={
                <Tooltip>{macroses[key].hint}</Tooltip>
              }>
                <a
                  className={clsx(
                    'badge cursor-pointer fs-8 fw-bold my-2 py-3 px-4 me-2',
                    { 'badge-light-success': rurl.indexOf(macroses[key].value) > -1 },
                    { 'badge-light-primary': rurl.indexOf(macroses[key].value) === -1 }
                  )}
                  onClick={(e) => {
                    e.preventDefault()
                    if (rurl.indexOf(macroses[key].value) > -1) {
                      setRurl(rurl.replaceAll(macroses[key].value, ""))
                    } else {
                      setRurl(`${rurl}${macroses[key].value}`)
                    }
                  }}
                >
                  {macroses[key].value}
                </a>
              </OverlayTrigger>
            </>)
          })}
          <PopsControl />
        </>
      )
    case "push":
      return (
        <>
          <div className="my-3">
            <label htmlFor="c_name" className="form-label">Name</label>
            <input id="c_name" name="c_name" className="form-control form-control-solid"
              value={name} onChange={(e) => {
                setName(e.target.value)
              }} />
          </div>
          <div className="my-3">
            <label htmlFor="c_rurl" className="form-label">URL</label>
            <input id="c_rurl" name="c_rurl" className={`form-control form-control-solid ${isValid ? '' : 'is-invalid'}`}
              value={rurl} onChange={(e) => {
                setRurl(e.target.value.replace(' ', ''))
              }} />
          </div>
          {Object.keys(macroses).map((key) => {
            return (<>
              <OverlayTrigger key={key} overlay={
                <Tooltip>{macroses[key].hint}</Tooltip>
              }>
                <a
                  className={clsx(
                    'badge cursor-pointer fs-8 fw-bold my-2 py-3 px-4 me-2',
                    { 'badge-light-success': rurl.indexOf(macroses[key].value) > -1 },
                    { 'badge-light-primary': rurl.indexOf(macroses[key].value) === -1 }
                  )}
                  onClick={(e) => {
                    e.preventDefault()
                    if (rurl.indexOf(macroses[key].value) > -1) {
                      setRurl(rurl.replaceAll(macroses[key].value, ""))
                    } else {
                      setRurl(`${rurl}${macroses[key].value}`)
                    }
                  }}
                >
                  {macroses[key].value}
                </a>
              </OverlayTrigger>
            </>)
          })}
          <PushControl
            title={pushTitle}
            onTitleChanged={setPushTitle}
            desc={pushDescription}
            onDescChanged={setPushDescription}
            iconUrl={pushIconUrl}
            onIconUrlChanged={setPushIconUrl}
            imageUrl={pushImageUrl}
            onImageUrlChanged={setPushImageUrl}
          />
        </>
      )
    default:
        return (<></>)
  }
}

type BannerProps = {
  title: string,
  onTitleChanged: (title: string) => void,
  desc: string,
  onDescChanged: (desc: string) => void,
  imageUrl: string,
  onImageUrlChanged: (url: string) => void,
  size: string,
  onSizeChanged: (size: string) => void,
}

const BannerControl: FC<BannerProps> = (props) => {
  // const [uppy] = useState(() => new Uppy({
  //     restrictions: {
  //         maxNumberOfFiles: 1,
  //         maxFileSize: 5 * 1024 * 1024, // 5mb
  //         allowedFileTypes: ['.png', '.jpeg', '.jpg']
  //     },
  // }).use(XHRUpload, {
  //     endpoint: `${basePath}/storage/upload`,
  //     headers: (file) => {
  //         const authModel = getAuth();
  //         return {
  //             "X-File-Name": getUniqueIdWithPrefix('creative-file'),
  //             "X-File-Size": file.size!,
  //             Authorization: authModel ? `Bearer ${authModel.access_token}` : '',
  //         }
  //     },
  //     validateStatus: (statusCode, responseText, response) => {
  //         return statusCode < 300;
  //     },
  //     method: "POST",
  //     fieldName: "file",
  //     getResponseData: (responseText, response) => {
  //         console.log(`upload response text: ${responseText}`)
  //     },
  // }));

  // uppy.on('upload-success', (file, response) => {
  //     console.log(`url: ${response.uploadURL}`);
  //     if (response.uploadURL) {
  //         onImageUrlChanged(response.uploadURL);
  //     }
  // })
  const sizes = [
    "300x250",
  ];
  const { title, onTitleChanged } = props;
  const { desc, onDescChanged } = props;
  const { imageUrl, onImageUrlChanged } = props;
  const { size, onSizeChanged } = props;
  const [isValidImage, setIsValidImage] = useState(true);
  const handleImageChanged = (value) => {
    try {
      new URL(value);
      setIsValidImage(true);
    } catch (_) {
      setIsValidImage(false);
    }
    onImageUrlChanged(value);
  }

  useEffect(() => {
    if (size === "") {
      onSizeChanged(sizes[0]);
    }
  })

  return (<>
    <div className="my-3">
      <label htmlFor="banner_title" className="form-label">Title</label>
      <input id="banner_title" name="banner_title" className="form-control form-control-solid"
        value={title} onChange={(e) => {
          onTitleChanged(e.target.value.substring(0, 30))
        }} />
    </div>

    <div className="my-3">
      <label htmlFor="banner_desc" className="form-label">Description</label>
      <input id="banner_desc" name="banner_desc" className="form-control form-control-solid"
        value={desc} onChange={(e) => {
          onDescChanged(e.target.value.substring(0, 50))
        }} />
    </div>

    <div className="my-3">
      <label className="form-label">Image URL</label>
      {/* <Dashboard uppy={uppy} singleFileFullScreen={true} proudlyDisplayPoweredByUppy={false} /> */}
      <input id="banner_image_url" name="banner_url" className={`form-control form-control-solid ${isValidImage ? '' : 'is-invalid'}`}
        value={imageUrl} onChange={(e) => {
          handleImageChanged(e.target.value)
        }} />
    </div>

    <div className="my-3">
      <label htmlFor="banner_size" className="form-label">Size</label>
      <Select
        options={sizes.map((e) => ({
          label: e,
          value: e,
        }))}
        id="banner_size"
        name="banner_size"
        className="react-select"
        classNamePrefix="select"
        value={{
          label: size,
          value: size,
        }}
        onChange={(e: SingleValue<{ label: string, value: string | undefined }>) => {
          if (e?.value) {
            onSizeChanged(e.value)
          }
        }}
      />
    </div>
  </>)
}

type PopsProps = {
}

const PopsControl: FC<PopsProps> = (props) => {
  return (<></>)
  // return (<>
  //     <div className="my-3">
  //         <label htmlFor="pops_url" className="form-label">URL</label>
  //         <input id="pops_url" name="pops_url" className="form-control form-control-solid mb-4"
  //             value={url} onChange={(e) => {
  //                 onUrlChanged(e.target.value)
  //             }} />
  //     </div>
  // </>)
}

type PushProps = {
  title: string
  onTitleChanged: (title: string) => void,
  desc: string,
  onDescChanged: (desc: string) => void,
  iconUrl: string,
  onIconUrlChanged: (iconUrl: string) => void,
  imageUrl: string,
  onImageUrlChanged: (imageUrl: string) => void,
}

const PushControl: FC<PushProps> = (props) => {
  const { title, onTitleChanged } = props;
  const { desc, onDescChanged } = props;
  const { iconUrl, onIconUrlChanged } = props;
  const { imageUrl, onImageUrlChanged } = props;
  const [isValidIcon, setIsValidIcon] = useState(true);
  const [isValidImage, setIsValidImage] = useState(true);

  const handleIconChanged = (value) => {
    try {
      new URL(value);
      setIsValidIcon(true);
    } catch (_) {
      setIsValidIcon(false);
    }
    onIconUrlChanged(value);
  };

  const handleImageChanged = (value) => {
    try {
      new URL(value);
      setIsValidImage(true);
    } catch (_) {
      setIsValidImage(false);
    }
    onImageUrlChanged(value);
  }

  return (<>
    <div className="my-3">
      <label htmlFor="push_title" className="form-label">Title</label>
      <input id="push_title" name="push_title" className="form-control form-control-solid"
        value={title} onChange={(e) => {
          onTitleChanged(e.target.value.substring(0, 30))
        }} />
    </div>

    <div className="my-3">
      <label htmlFor="push_desc" className="form-label">Description</label>
      <input id="push_desc" name="push_desc" className="form-control form-control-solid"
        value={desc} onChange={(e) => {
          onDescChanged(e.target.value.substring(0, 50))
        }} />
    </div>

    <div className="my-3">
      <label htmlFor="push_icon_url" className="form-label">Icon URL</label>
      <input id="push_icon_url" name="push_icon_url" className={`form-control form-control-solid ${isValidIcon ? '' : 'is-invalid'}`}
        value={iconUrl} onChange={(e) => {
          handleIconChanged(e.target.value)
        }} />
    </div>

    <div className="my-3">
      <label htmlFor="push_image_url" className="form-label">Image URL</label>
      <input id="push_image_url" name="push_image_url" className={`form-control form-control-solid ${isValidImage ? '' : 'is-invalid'}`}
        value={imageUrl} onChange={(e) => {
          handleImageChanged(e.target.value)
        }} />
    </div>
  </>)
}