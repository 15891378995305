import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import { KTIcon } from '../../helpers';

type Props = {
  data: {title: string; description: string}
  show: boolean
  handleClose: (value: boolean) => void
}

const ConfirmModal: React.FC<Props> = ({show, handleClose, data}) => {
  const dissmiss = () => {
    handleClose(false)
  }
  const apply = () => {
    handleClose(true)
  }

  return (
    <Modal
      className='modal fade'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-md'
      aria-hidden='true'
      onHide={dissmiss}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>{data.title}</h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dissmiss}
          >
            <KTIcon iconName='cross' className='fs-2x' />
          </div>
        </div>
        <div className='modal-body'>
          {data.description}
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dissmiss}>
            Cancel
          </button>
          <button id='submit' type='button' className='btn btn-primary' onClick={apply}>
            Apply
          </button>
        </div>
      </div>
    </Modal>
  )
}

export {ConfirmModal}
