import { CampaignDisplayType } from "../app/pages/campaign/Campaigns"
import { DSPDisplayType } from "../app/pages/campaign/DSPS"
import { LimitStatus } from "./v2/gql/gen/graphql"

export type CampaignCurrentStatus = "no_creatives" | "rejected" | "waiting" | "disabled" | "limit_status" | "active" | "balance_low"
export type ModerationStatus = "waiting" | "successfully" | "rejected"

export interface ICampaignCurrentStatus {
  code: CampaignCurrentStatus,
  reason: string
}

export const getCampaignCurrentStatus = (campaign: CampaignDisplayType): ICampaignCurrentStatus => {
  if (parseFloat(campaign.advertiser.user.balance.currentBalance) < parseFloat(campaign.advertiser.user.balance.loadLimit)) {
    return {
      code: 'balance_low',
      reason: 'Balance is too low'
    }
  }
  
  if (campaign.creatives.filter((e) => e.enabled).length === 0) {
    return {
      code: "no_creatives",
      reason: "No creatives"
    }
  }

  if (campaign.moderationStatus === 'rejected') {
    return {
      code: "rejected",
      reason: campaign.moderationReason,
    }
  }
  if (campaign.moderationStatus === 'waiting') {
    return {
      code: 'waiting',
      reason: "Under moderation"
    }
  }
  if (!campaign.enabled) {
    return {
      code: "disabled",
      reason: "Campaign disabled"
    }
  }
  if (campaign.limitStatus ?? 0 > 0) {
    switch (campaign.limitStatus) {
      case LimitStatus.InactiveDailyCls:
        return {
          code: 'limit_status',
          reason: 'Daily click limit is inactive'
        }
      case LimitStatus.InactiveDailyImp:
        return {
          code: 'limit_status',
          reason: 'Daily impression limit is inactive'
        }
      case LimitStatus.InactiveDailyCost:
        return {
          code: 'limit_status',
          reason: 'Daily cost limit is inactive'
        }
      case LimitStatus.InactiveTotalCls:
        return {
          code: 'limit_status',
          reason: 'Total click limit is inactive'
        }
      case LimitStatus.InactiveTotalImp:
        return {
          code: 'limit_status',
          reason: 'Total impression limit is inactive'
        }
      case LimitStatus.InactiveTotalCost:
        return {
          code: 'limit_status',
          reason: 'Total cost limit is inactive'
        }
    }
  }

  return {
    code: 'active',
    reason: ''
  }
}

export const getDSPCurrentStatus = (campaign: DSPDisplayType): ICampaignCurrentStatus => {
  if (!campaign.enabled) {
    return {
      code: "disabled",
      reason: "DSP disabled"
    }
  }
  if (campaign.limitStatus ?? 0 > 0) {
    switch (campaign.limitStatus) {
      case LimitStatus.InactiveDailyCls:
        return {
          code: 'limit_status',
          reason: 'Daily click limit is inactive'
        }
      case LimitStatus.InactiveDailyImp:
        return {
          code: 'limit_status',
          reason: 'Daily impression limit is inactive'
        }
      case LimitStatus.InactiveDailyCost:
        return {
          code: 'limit_status',
          reason: 'Daily cost limit is inactive'
        }
      case LimitStatus.InactiveTotalCls:
        return {
          code: 'limit_status',
          reason: 'Total click limit is inactive'
        }
      case LimitStatus.InactiveTotalImp:
        return {
          code: 'limit_status',
          reason: 'Total impression limit is inactive'
        }
      case LimitStatus.InactiveTotalCost:
        return {
          code: 'limit_status',
          reason: 'Total cost limit is inactive'
        }
    }
  }

  return {
    code: 'active',
    reason: ''
  }
}

export interface IPaginationRequest {
  pageNumber: number
  pageSize: number
}