import { gql } from "./gen";

export const QUERY_CURRENT_BALANCE = gql(`
  query CurrentBalance {
    myProfile{
      balance{
        amount
      }
    }
  }
`)

export const MUTATION_UPDATE_LOAN_LIMIT = gql(`
  mutation UpdateLoanLimit($balanceID:ID!,$limit:Decimal!) {
    updateLoanLimit(id:$balanceID,limit:$limit){
      id
    }
  }
`)