import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase } from 'react-select';

export type CampaignCategoryTypePickerResult = { name: string }

type Props = {
  options?: Partial<AsyncPaginateProps<CampaignCategoryTypePickerResult, GroupBase<CampaignCategoryTypePickerResult>, { page: number }, false>>
  value: CampaignCategoryTypePickerResult | null
  onChange?: (newValue: CampaignCategoryTypePickerResult | null) => void;
}

export const CampaignCategoryTypePicker = (props: Props) => {
  const loadOptions: LoadOptions<CampaignCategoryTypePickerResult, GroupBase<CampaignCategoryTypePickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const q = search.toLowerCase();
    const response = search && search.length > 0 ? categoryTypeOptions.filter((e) => {
      return e.name.toLowerCase().indexOf(q) > -1;
    }) : categoryTypeOptions;

    return {
      options: response,
      hasMore: false,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => u.name}
      getOptionValue={(u) => u.name}
      value={props.value}
      {...props.options}
    />
  )
}

const categoryTypeOptions = [
  {
    name: 'ad networks',
  },
  {
    name: 'betting',
  },
  {
    name: 'dating',
  },
  {
    name: 'download',
  },
  {
    name: 'e-commerce',
  },
  {
    name: 'education',
  },
  {
    name: 'finances',
  },
  {
    name: 'gambling',
  },
  {
    name: 'gaming',
  },
  {
    name: 'health & beauty',
  },
  {
    name: 'news',
  },
  {
    name: 'pinsubmits',
  },
  {
    name: 'push subscribes',
  },
  {
    name: 'sweepstakes',
  },
  {
    name: 'video',
  },
]