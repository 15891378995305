import 'flatpickr/dist/flatpickr.min.css'
import {StatsGroupSelector} from './StatsSelector'

const StatsListHeader = ({
  handleStatsDataReceived,
  handleExportData,
  handlePageSizeInHeader,
  pageSize,
  statsOptions,
  setLoading,
  campID,
}) => {
  const handleStats = (selectedFields, response) => {
    handleStatsDataReceived(response, selectedFields)
  }

  return (
    <StatsGroupSelector
      onStatsDataReceived={handleStats}
      handleExportData={handleExportData}
      handlePageSizeInHeader={handlePageSizeInHeader}
      pageSize={pageSize}
      statsOptions={statsOptions}
      setLoading={setLoading}
      campID={campID}
    />
  )
}

export {StatsListHeader}
