/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useAuth } from '../core/Auth'
import { KTIcon } from '../../../../_metronic/helpers/components/KTIcon'
import { ApolloError, useMutation } from '@apollo/client'
import { MUTATION_SIGNIN } from '../../../../api/v2/gql/auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .matches(
      /^([a-zA-Z0-9_.+-])+@([a-zA-Z0-9-])+(\.[a-zA-Z0-9-]{2,})$/,
      {
        message: 'Wrong email format',
        excludeEmptyString: true,
      }
    )
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

export function Login() {
  const { saveAuth } = useAuth()

  const [showPassword, setShowPassword] = useState(false)
  const [signIn, { loading }] = useMutation(MUTATION_SIGNIN, {})

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        const response = await signIn({
          variables: {
            email: values.email,
            password: values.password,
          },
        })

        if (response.data) {
          saveAuth({
            access_token: response.data.signIn.accessToken,
            refresh_token: response.data.signIn.refreshToken,
          })
        }
      } catch (e) {
        if (e instanceof ApolloError && e.graphQLErrors && e.graphQLErrors[0].extensions) {
          if (e.graphQLErrors[0].extensions['field'] === 'email') {
            formik.setFieldError('email', e.message)
          }
          if (e.graphQLErrors[0].extensions['field'] === 'password') {
            formik.setFieldError('password', e.message)
          }
        }
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div className='position-relative'>
          <input
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'border-danger': formik.touched.password && formik.errors.password,
              },
              {
                'border-success': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <span
            className={clsx('position-absolute top-50 translate-middle-y end-0 me-3', {
              'text-primary': showPassword,
            })}
          >
            <button
              type='button'
              className={clsx(
                'btn btn-sm btn-icon btn-icon-end btn-color-dark btn-active-light-primary',
                {
                  'text-primary': !showPassword,
                }
              )}
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <KTIcon iconName='eye-slash' /> : <KTIcon iconName='eye' />}
            </button>
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div>
    </form>
  )
}
