import React from 'react';
import { CampaignType, PayType } from '../../../api/v2/gql/gen/graphql';

type CampaignFilter = {
    status: boolean | undefined,
    type: CampaignType | undefined,
    pay_type: PayType | undefined,
}

type CampaignFilterProps = {
    filter: CampaignFilter,
    setFilter: (filter: CampaignFilter) => void,
};

const CampaignFilter: React.FC<CampaignFilterProps> = ({ filter, setFilter }) => {
    const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const status = e.target.value;

        switch (status) {
            case "show_all":
                setFilter({ ...filter, status: undefined })
                break;
            case "paused":
                setFilter({ ...filter, status: false })
                break;
            case "active":
                setFilter({ ...filter, status: true })
                break;
        }
    };

    const handleFormatChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const format = e.target.value as (CampaignType | "show_all");
        switch (format) {
            case "show_all":
                setFilter({ ...filter, type: undefined })
                break;
            default:
                setFilter({ ...filter, type: format })
                break;
        }
    };

    const handlePaymentTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const paymentType = e.target.value as (PayType | "show_all");
        switch (paymentType) {
            case "show_all":
                setFilter({ ...filter, pay_type: undefined })
                break;
            default:
                setFilter({ ...filter, pay_type: paymentType })
                break;
        }
    };

    return (
        <div className="d-flex align-items-center fw-bold">
            <label htmlFor="filterStatus" className="form-label text-white fs-7 lh-1 py-0 mb-0">
                Status
            </label>
            <select
                className="form-select form-select-transparent text-white fs-7 lh-1 fw-bold py-0 ps-4 w-auto select2-hidden-accessible"
                data-hide-search="true"
                data-dropdown-css-class="w-150px"
                data-control="select2"
                data-placeholder="Select an option"
                data-kt-table-widget-3="filter_status"
                data-select2-id="select2-data-15-08jc"
                aria-hidden="true"
                data-kt-initialized="1"
                value={filter.status === true ? 'active' : filter.status === false ? 'paused' : 'show_all'}
                onChange={handleStatusChange}
            >
                <option value="show_all" selected data-select2-id="select2-data-17-tza1">
                    Show All
                </option>
                <option value="active" data-select2-id="select2-data-172-sy77">
                    Active
                </option>
                <option value="paused" data-select2-id="select2-data-173-uqj1">
                    Paused
                </option>
            </select>
            <label htmlFor="filterFormat" className="form-label text-white fs-7 lh-1 py-0 mb-0">
                Format
            </label>
            <select
                className="form-select form-select-transparent text-white fs-7 lh-1 fw-bold py-0 ps-4 w-auto select2-hidden-accessible"
                data-hide-search="true"
                data-dropdown-css-class="w-150px"
                data-control="select2"
                data-placeholder="Select an option"
                data-kt-table-widget-3="filter_format"
                data-select2-id="select2-data-15-08jc"
                aria-hidden="true"
                data-kt-initialized="1"
                value={filter.type === undefined ? 'show_all' : filter.type}
                onChange={handleFormatChange}
            >
                <option value="show_all" selected data-select2-id="select2-data-17-tza1">
                    Show All
                </option>
                <option value={CampaignType.Pops} data-select2-id="select2-data-172-sy77">
                    Pops
                </option>
                <option value={CampaignType.Banner} data-select2-id="select2-data-173-uqj1">
                    Banner
                </option>
                <option value={CampaignType.Push} data-select2-id="select2-data-173-uqj1">
                    Push
                </option>
            </select>
            <label htmlFor="filterPaymentType" className="form-label text-white fs-7 lh-1 py-0 mb-0">
                Payment Type
            </label>
            <select
                className="form-select form-select-transparent text-white fs-7 lh-1 fw-bold py-0 ps-4 w-auto select2-hidden-accessible"
                data-hide-search="true"
                data-dropdown-css-class="w-150px"
                data-control="select2"
                data-placeholder="Select an option"
                data-kt-table-widget-3="filter_payment_type"
                data-select2-id="select2-data-15-08jc"
                aria-hidden="true"
                data-kt-initialized="1"
                value={filter.pay_type === undefined ? 'show_all' : filter.pay_type}
                onChange={handlePaymentTypeChange}
            >
                <option value="show_all" selected data-select2-id="select2-data-17-tza1">
                    Show All
                </option>
                <option value={PayType.Cpm} data-select2-id="select2-data-172-sy77">
                    CPM
                </option>
                <option value={PayType.Cpc} data-select2-id="select2-data-173-uqj1">
                    CPC
                </option>
                <option value={PayType.Cpa} data-select2-id="select2-data-173-uqj1">
                    CPA
                </option>
            </select>
        </div>
    );
};

export default CampaignFilter;