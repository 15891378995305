import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useAuth } from '../core/Auth'
import { KTIcon } from '../../../../_metronic/helpers/components/KTIcon'
import { useMutation } from '@apollo/client'
import { MUTATION_REGISTER_ADV } from '../../../../api/v2/gql/auth'
import { UsageType } from '../../../../api/v2/gql/gen/graphql'

const initialValues: RegistrationForm = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  confirmpassword: '',
  acceptTerms: false,
  accountType: 'Advertiser',
}

type AccountType = 'Advertiser' | 'Publisher'

type RegistrationForm = {
  firstname: string
  lastname: string
  email: string
  password: string
  confirmpassword: string
  acceptTerms: boolean
  accountType: AccountType | undefined
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string().matches(/^([a-zA-Z0-9_.+-])+@([a-zA-Z0-9-])+(\.[a-zA-Z0-9-]{2,})$/, {
    message: 'Wrong email format',
    excludeEmptyString: true,
  }),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string().when('$initialValue', (initialValue, schema) => {
    return initialValue
      ? schema
        .min(5, 'Minimum 5 symbols')
        .max(20, 'Maximum 20 symbols')
        // .matches(
        //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d-]{5,20}$/, // Updated regex pattern to allow hyphens
        //   'Password should contains at least 1 upper case letter, 1 lower case letter and 1 number'
        // )
      : schema.optional()
  }),
  confirmpassword: Yup.string()
    .required('Confirm Password is Required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const { saveAuth } = useAuth()
  const [showPassword, setShowPassword] = useState(false)
  const [register] = useMutation(MUTATION_REGISTER_ADV)

  const formik = useFormik<RegistrationForm>({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data } = await register({
          variables: {
            input: {
              email: values.email,
              firstName: values.firstname,
              lastName: values.lastname,
              password: values.password,
              usageType: UsageType.Individual,
            }
          }
        })
        if (data) {
          saveAuth({
            access_token: data?.registerAsAdvertiser.accessToken,
            refresh_token: data.registerAsAdvertiser.refreshToken,
          })
        }
      } catch (error) {
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder d-flex align-items-center text-dark'>Choose Account Type</h2>
        </div>

        <div className='fv-row'>
          <div className='row'>
            <div className='col-lg-6'>
              <input
                type='radio'
                className='btn-check'
                name='accountType'
                value='advertiser'
                id='kt_create_account_form_account_type_advertiser'
                checked={formik.values.accountType === 'Advertiser'}
                onChange={() => formik.setFieldValue('accountType', 'Advertiser')}
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                htmlFor='kt_create_account_form_account_type_advertiser'
              >
                <KTIcon iconName='briefcase' className='fs-3x me-5' />

                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>Advertiser</span>
                </span>
              </label>
            </div>
            <div className='col-lg-6'>
              <input
                type='radio'
                className='btn-check'
                name='accountType'
                value='publisher'
                id='kt_create_account_form_account_type_publisher'
                checked={formik.values.accountType === 'Publisher'}
                onChange={() => formik.setFieldValue('accountType', 'Publisher')}
                disabled
              />
              <label
                className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10 disabled'
                htmlFor='kt_create_account_form_account_type_publisher'
              >
                <KTIcon iconName='address-book' className='fs-3x me-5' />

                <span className='d-block fw-bold text-start'>
                  <span className='text-dark fw-bolder d-block fs-4 mb-2'>Publisher</span>
                </span>
              </label>
            </div>
            <div className='text-danger mt-2'>
              <span className='validation-message'>{formik.errors.accountType}</span>
            </div>
          </div>
        </div>
      </div>

      {formik.values.accountType && (
        <>
          {/* begin::Heading */}
          <div className='text-center mb-11'>
            {/* begin::Title */}
            <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
            {/* end::Title */}
          </div>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          {/* begin::Form group Firstname */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>First name</label>
            <input
              placeholder='First name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.firstname && formik.errors.firstname,
                },
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstname}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          <div className='fv-row mb-8'>
            {/* begin::Form group Lastname */}
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <input
              placeholder='Last name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.lastname && formik.errors.lastname,
                },
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastname}</span>
                </div>
              </div>
            )}
            {/* end::Form group */}
          </div>

          {/* begin::Form group Email */}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Password</label>
              <div className='position-relative mb-3'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'border-danger': formik.touched.password && formik.errors.password,
                    },
                    {
                      'border-success': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <span
                  className={clsx('position-absolute top-50 translate-middle-y end-0 me-3', {
                    'text-primary': showPassword,
                  })}
                >
                  <button
                    type='button'
                    className={clsx(
                      'btn btn-sm btn-icon btn-icon-end btn-color-dark btn-active-light-primary',
                      {
                        'text-primary': !showPassword,
                      }
                    )}
                    onClick={() => setShowPassword((prev) => !prev)}
                  >
                    {showPassword ? <KTIcon iconName='eye-slash' /> : <KTIcon iconName='eye' />}
                  </button>
                </span>
                {formik.touched.password && formik.errors.password && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
              {/* end::Meter */}
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Confirm password */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('confirmpassword')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'border-danger': formik.touched.confirmpassword && formik.errors.confirmpassword,
                },
                {
                  'border-success':
                    formik.touched.confirmpassword && !formik.errors.confirmpassword,
                }
              )}
            />
            {formik.touched.confirmpassword && formik.errors.confirmpassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmpassword}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-8'>
            <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <span>
                I Accept the{' '}
                <a href='#' target='_blank' className='ms-1 link-primary'>
                  Terms
                </a>
                .
              </span>
            </label>
            {formik.touched.acceptTerms && formik.errors.acceptTerms && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.acceptTerms}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Cancel
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </>
      )}
    </form>
  )
}
