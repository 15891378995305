import { gql } from "./gen";

export const QUERY_FIND_COUNTRIES = gql(`
  query FindCountries($query:String,$pagination:PaginationInput!){
    findCountries(query:$query, pagination:$pagination){
      pageInfo{
        totalCount
      }
      countries{
        id
        name
      }
    }
  }
`)

export const QUERY_FIND_REGIONS = gql(`
  query FindRegions($query: String, $countryIDs: [ID!]!, $pagination: PaginationInput!) {
    findRegions(query: $query, countryIDs: $countryIDs, pagination: $pagination) {
      pageInfo{
        totalCount
      }
      regions{
        id
        name
      }
    }
  }
`)

export const QUERY_FIND_CITIES = gql(`
  query FindCities($query: String, $regionIDs: [ID!]!, $pagination: PaginationInput!) {
    findCities(query: $query, regionIDs: $regionIDs, pagination: $pagination) {
      pageInfo{
        totalCount
      }
      cities{
        id
        name
      }
    }
  }
`)