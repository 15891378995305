import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { FindDeviceTypesQuery } from '../../api/v2/gql/gen/graphql';
import { QUERY_FIND_DEVICE_TYPES } from '../../api/v2/gql/devices';

export type DeviceTypeMultiResult = FindDeviceTypesQuery['findDeviceTypes']['types'][number]

type Props = {
  options?: Partial<AsyncPaginateProps<DeviceTypeMultiResult, GroupBase<DeviceTypeMultiResult>, { page: number }, true>>
  value: DeviceTypeMultiResult[] | null
  onChange?: (newValue: MultiValue<DeviceTypeMultiResult>) => void;
}

export const DeviceTypeMultiPicker = (props: Props) => {
  const [fetchRegions] = useLazyQuery(QUERY_FIND_DEVICE_TYPES)

  const loadOptions: LoadOptions<DeviceTypeMultiResult, GroupBase<DeviceTypeMultiResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchRegions({
      variables: {
        query: search,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findDeviceTypes.types,
      hasMore: response.data!.findDeviceTypes.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => `${u.name}`}
      getOptionValue={(u) => u.id}
      value={props.value}
      {...props.options}
    />
  )
}