import { MenuItem } from './MenuItem'
import { MenuInnerWithSub } from './MenuInnerWithSub'
import { useIntl } from 'react-intl'
import { useAuth } from '../../../../app/modules/auth'
import { UserRole } from '../../../../api/v2/gql/gen/graphql'

export function MenuInner() {
  const intl = useIntl()
  const { currentUser } = useAuth();
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/dashboard' />
      {currentUser?.role === 'admin' && <>
        <MenuItem title='Layout Builder' to='/builder' />
        <MenuInnerWithSub
          title='Crafted'
          to='/crafted'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {/* ACCOUNT */}
          <MenuInnerWithSub
            title='Accounts'
            to='/crafted/accounts'
            icon='profile-circle'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
          </MenuInnerWithSub>

          {/* ERRORS */}
          <MenuInnerWithSub
            title='Errors'
            to='/error'
            icon='fingerprint-scanning'
            hasArrow={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
            <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>

        <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
          {/* PAGES */}
          <MenuItem icon='shield-tick' to='/apps/user-management/users' title='User management' />
        </MenuInnerWithSub>
      </>}
      <MenuItem title='Campaigns' to='/campaigns' />
      {currentUser?.role === UserRole.Admin && (
        <MenuItem title='DSP' to='/dsps' />
      )}
      <MenuItem title='Creatives' to='/creatives' />
      <MenuItem title='Finance' to='/finance' />
    </>
  )
}
