import { useState, useEffect, ReactNode } from 'react'
import { AlertType, generateAlert } from '../../helpers/alert'
import { PageTitle } from '../../../_metronic/layout/core'
import { useNavigate, useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { CreativeControl } from '../../modules/creatives/creativesControl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PushPreview } from './PushPreview'
import { useAuth } from '../../modules/auth'
import { CreativeType, GetCreativeQuery, UpdateCreativeInput, UserRole } from '../../../api/v2/gql/gen/graphql'
import { useFormik } from 'formik'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { MUTATION_CREATE_CREATIVE, MUTATION_UPDATE_CREATIVE, QUERY_GET_CREATIVE } from '../../../api/v2/gql/creatives'
import { AdvPicker, AdvPickerResult } from '../../components/AdvPicker'

type TypeOption = {
  value: CreativeType
  title: string
  description: string
  icon: ReactNode
  allowedPayOptions: string[]
}

const typeOptions: TypeOption[] = [
  {
    value: CreativeType.Pops,
    title: 'Pops',
    description: 'New tab on click',
    allowedPayOptions: ['cpm', 'cpa'],
    icon: (
      <span className='svg-icon svg-icon-2x me-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-window-stack'
          viewBox='0 0 16 16'
        >
          <path d='M4.5 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1M6 6a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1m2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0' />
          <path d='M12 1a2 2 0 0 1 2 2 2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2 2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zM2 12V5a2 2 0 0 1 2-2h9a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1m1-4v5a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V8zm12-1V5a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v2z' />
        </svg>
      </span>
    ),
  },
  {
    value: CreativeType.Push,
    title: 'Push',
    description: 'Push notification on browser',
    allowedPayOptions: ['cpc', 'cpa'],
    icon: (
      <span className='svg-icon svg-icon-2x me-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-chat-right-dots'
          viewBox='0 0 16 16'
        >
          <path d='M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z' />
          <path d='M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0' />
        </svg>
      </span>
    ),
  },
  {
    value: CreativeType.Banner,
    title: 'Banner',
    description: '300x250',
    allowedPayOptions: ['cpm', 'cpa'],
    icon: (
      <span className='svg-icon svg-icon-2x me-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='currentColor'
          className='bi bi-file-richtext'
          viewBox='0 0 16 16'
        >
          <path d='M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0m-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208M5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z' />
          <path d='M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1' />
        </svg>
      </span>
    ),
  },
]

type CreateEditCreativeForm = {
  name: string
  rurl: string,
  status: boolean,
  adv: AdvPickerResult,
  bannerDescription: string,
  bannerImageUrl: string,
  bannerSize: string,
  bannerTitle: string,
  pushDescription: string,
  pushIconUrl: string,
  pushImageUrl: string,
  pushTitle: string,
  type: CreativeType,
}

export function CreateEditCreative() {
  const { id } = useParams();
  const idInt = id ? parseInt(id) : undefined;
  const { currentUser } = useAuth()
  const [exists, setExists] = useState<GetCreativeQuery['getCreative'] | undefined>();
  const [getCreative] = useLazyQuery(QUERY_GET_CREATIVE)

  useEffect(() => {
    const fetch = async () => {
      if (idInt) {
        const response = await getCreative({
          variables: {
            id: idInt
          }
        })
        setExists(response.data?.getCreative);
      }
    }

    fetch();
  }, [id])

  return (
    <>
      {id
        ? exists
          ? <CreateEditCreativeCard
            id={idInt!}
            initialValues={{
              adv: {
                ...exists.advertiser.user,
                advertiserProfile: exists.advertiser,
                // FIXME (marcus): 22.07.24 fix balance requirements
                balance: {
                  id: "",
                  amount: "",
                },
              },
              bannerDescription: exists.description ?? '',
              bannerImageUrl: exists.imgRurl ?? '',
              bannerSize: exists.size ?? '',
              bannerTitle: exists.title ?? '',
              name: exists.name,
              pushDescription: exists.description ?? '',
              pushIconUrl: exists.iconRurl ?? '',
              pushImageUrl: exists.imgRurl ?? '',
              pushTitle: exists.title ?? '',
              rurl: exists.rurl ?? '',
              status: exists.enabled,
              type: exists.type,
            }}
          />
          : <>Loading..</>
        : <CreateEditCreativeCard
          id={undefined}
          initialValues={{
            adv: {
              ...currentUser!,
              // FIXME (marcus): 22.07.24 fix balance requirements
              balance: {
                id: "",
                amount: "",
              },
            },
            bannerDescription: "",
            bannerImageUrl: "",
            bannerSize: "",
            bannerTitle: "",
            name: "",
            pushDescription: "",
            pushIconUrl: "",
            pushImageUrl: "",
            pushTitle: "",
            rurl: "",
            status: true,
            type: CreativeType.Pops,
          }}
        />
      }
    </>
  )
}
const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator
  return !!urlPattern.test(urlString);
}

function CreateEditCreativeCard({ id, initialValues }: { id: number | undefined, initialValues: CreateEditCreativeForm }) {
  const intl = useIntl()
  const navigate = useNavigate();
  const { currentUser } = useAuth()

  const [update] = useMutation(MUTATION_UPDATE_CREATIVE);
  const [create] = useMutation(MUTATION_CREATE_CREATIVE);

  const formik = useFormik<CreateEditCreativeForm>({
    initialValues: initialValues,
    onSubmit: async (values) => {
      try {
        switch (values.type) {
          case CreativeType.Banner:
            // if (!isValidUrl(values.bannerImageUrl)) {
            //   formik.setFieldError('bannerImageUrl', 'Must be correct URL')
            //   return;
            // }

            const bannerInput = {
              description: values.bannerDescription,
              enabled: values.status,
              iconRurl: "",
              imgRurl: values.bannerImageUrl,
              rurl: values.rurl,
              name: values.name,
              size: values.bannerSize,
              title: values.bannerTitle,
              type: values.type,
              advID: values.adv.advertiserProfile.advertiserID,
            }
            if (id) {
              await update({ variables: { id: id!, input: bannerInput } })
            } else {
              const response = await create({ variables: { input: bannerInput } })
              if (response.data) {
                navigate(`/creatives/${response.data?.createCreative.id}`);
              }
            }
            break;
          case CreativeType.Push:
            // if (!isValidUrl(values.pushIconUrl)) {
            //   formik.setFieldError('pushIconUrl', 'Must be correct URL')
            //   return;
            // }
            // if (!isValidUrl(values.pushImageUrl)) {
            //   formik.setFieldError('pushImageUrl', 'Must be correct URL')
            //   return;
            // }
            // if (!isValidUrl(values.rurl)) {
            //   formik.setFieldError('rurl', 'Must be correct URL')
            //   return;
            // }
            const pushInput = {
              description: values.pushDescription,
              enabled: values.status,
              iconRurl: values.pushIconUrl,
              imgRurl: values.pushImageUrl,
              name: values.name,
              rurl: values.rurl,
              size: "",
              title: values.pushTitle,
              type: values.type,
              advID: values.adv.advertiserProfile.advertiserID,
            }

            if (id) {
              await update({ variables: { id: id!, input: pushInput } })
            } else {
              const response = await create({ variables: { input: pushInput } })
              if (response.data) {
                navigate(`/creatives/${response.data?.createCreative.id}`);
              }
            }
            break;
          case CreativeType.Pops:
            // if (!isValidUrl(values.rurl)) {
            //   formik.setFieldError('rurl', 'Must be correct URL')
            //   return;
            // }
            const popsInput = {
              description: "",
              enabled: values.status,
              iconRurl: "",
              imgRurl: "",
              name: values.name,
              rurl: values.rurl,
              size: "",
              title: "",
              type: values.type,
              advID: values.adv.advertiserProfile.advertiserID,
            }

            if (id) {
              await update({ variables: { id: id!, input: popsInput } })
            } else {
              const response = await create({ variables: { input: popsInput } });
              if (response.data) {
                navigate(`/creatives/${response.data?.createCreative.id}`);
              }
            }
            break;
          default:
            return;
        }
        if (id) {
          generateAlert('Creative updated', AlertType.SUCCESS);
        } else {
          generateAlert('Creative created', AlertType.SUCCESS);
        }
      } catch (e) {
        generateAlert('Unknown error', AlertType.ERROR)
      }
    }
  });

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: id ? 'MENU.EDITCREATIVE' : 'MENU.NEWCREATIVE' })}
      </PageTitle>
      <div className='row gy-5 gx-xl-8'>
        <div className='col-xl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-8'>
            <div className='card-body py-3 pt-8 container'>
              <form onSubmit={formik.handleSubmit} noValidate>
                {currentUser?.role === UserRole.Admin && (
                  <>
                    <div className='mb-5'>
                      <label htmlFor='adv-id' className='required form-label'>
                        Advertiser
                      </label>
                      <AdvPicker onChange={(user) => formik.setFieldValue('adv', user)} value={formik.values.adv} />
                    </div>
                  </>
                )}
                <div className='mb-5'>
                  <div className='form-check form-switch form-check-custom form-check-solid mt-2'>
                    <label className='me-2'>Status</label>
                    <input
                      className='form-check-input h-20px w-30px'
                      type='checkbox'
                      id='status'
                      checked={formik.values.status}
                      onChange={(e) => formik.setFieldValue('status', !formik.values.status)}
                    />
                  </div>
                </div>
                <div className='mb-5'>
                  <label>Type</label>
                  <div className='row'>
                    {typeOptions.map((e) => {
                      return (
                        <div
                          className='col-lg-4'
                          onClick={(_) => {
                            formik.setFieldValue('type', e.value)
                          }}
                          key={e.value}
                        >
                          <input
                            type='radio'
                            className='btn-check'
                            value={e.value}
                            checked={formik.values.type === e.value}
                          />
                          <label className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-5'>
                            {e.icon}

                            <span className='d-block fw-bold text-start'>
                              <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                {e.title}
                              </span>
                              <span className='text-gray-400 fw-bold fs-6'>{e.description}</span>
                            </span>
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={clsx('d-flex flex-row', { 'border-top': true })}>
                  <div className='d-flex flex-grow-1 flex-column pe-5'>
                    <div>
                      <CreativeControl
                        initial={formik.values}
                        type={formik.values.type}
                        onChanged={(updated) => {
                          formik.setValues({
                            bannerDescription: updated.bannerDescription,
                            bannerImageUrl: updated.bannerImageUrl,
                            bannerSize: updated.bannerSize,
                            bannerTitle: updated.bannerTitle,
                            name: updated.name,
                            pushDescription: updated.pushDescription,
                            pushIconUrl: updated.pushIconUrl,
                            pushImageUrl: updated.pushImageUrl,
                            pushTitle: updated.pushTitle,
                            rurl: updated.rurl,
                            type: formik.values.type,
                            adv: formik.values.adv,
                            status: formik.values.status,
                          })
                        }}
                      />
                    </div>
                  </div>
                  {formik.values.type === 'banner' && (
                    <>
                      <div className=''>
                        <span className='text-dark fw-bolder d-block fs-4 mb-2'>Preview</span>
                        <iframe
                          height={250}
                          width={300}
                          scrolling='no'
                          style={{ overflow: 'hidden' }}
                          src={toAbsoluteUrl(
                            `/template/creative?name=${encodeURIComponent(
                              formik.values.name
                            )}&camp=${encodeURIComponent(
                              formik.values.bannerImageUrl
                            )}&click_url=${btoa(
                              encodeURIComponent(formik.values.rurl)
                            )}&desc=${encodeURIComponent(
                              formik.values.bannerDescription
                            )}&title=${encodeURIComponent(formik.values.bannerTitle)}`
                          )}
                        />
                      </div>
                    </>
                  )}
                  {formik.values.type === 'pops' && (
                    <>
                      <div className=''></div>
                    </>
                  )}
                  {formik.values.type === 'push' && (
                    <>
                      <div className=''>
                        <span className='text-dark fw-bolder d-block fs-4 mb-2'>Preview</span>
                        <div className='w-300px h-250px'>
                          <PushPreview
                            title={formik.values.pushTitle}
                            description={formik.values.pushDescription}
                            site={formik.values.rurl}
                            logo={formik.values.pushIconUrl}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {/* {formik.values.type === 'native' && (
                    <>
                      <div className=''></div>
                    </>
                  )} */}
                </div>
                <div className='mb-5'>
                  <button
                    type='submit'
                    className={clsx('btn btn-success mt-5')}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}