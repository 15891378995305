import { useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth'
import { Modal } from 'react-bootstrap'
import { UserRole } from '../../../api/v2/gql/gen/graphql'
import { AdvPicker, AdvPickerResult } from '../../components/AdvPicker'

export type CampaignQueryFilter = {
  adv_id: number | undefined,
  camp_name: string | undefined,
}

type CampaignFilterByAdvProps = {
  filter: CampaignQueryFilter,
  setFilter: (filter: CampaignQueryFilter) => void,
}

const CampaignFilterByAdv = ({ filter, setFilter }: CampaignFilterByAdvProps) => {
  const { currentUser } = useAuth()
  const [modalShow, setModalShow] = useState(false)
  const [searchAdv, setSearchAdv] = useState<AdvPickerResult | null>(null)
  const [searchCampByName, setSearchCampByName] = useState<string | undefined>(filter.camp_name)

  const resetData = () => {
    setModalShow(false)
    setSearchAdv(null)
    setSearchCampByName(undefined)
    setFilter({ adv_id: undefined, camp_name: undefined })
  }

  const handleSet = () => {
    setModalShow(false)
    setFilter({
      adv_id: searchAdv?.advertiserProfile.advertiserID,
      camp_name: searchCampByName,
    })
  };

  return (
    <>
      {/* begin::Filter Button */}
      <button
        type='button'
        className='position-absolute end-0 btn me-3'
        onClick={(e) => {
          e.preventDefault()
          setModalShow(true);
        }}
      >
        <KTIcon iconName='filter' className='fs-2x text-white' />
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <Modal show={modalShow} onHide={() => {
        setModalShow(false);
      }}>
        <div>
          {/* begin::Header */}
          <div className='px-7 py-5'>
            <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className='separator border-gray-200'></div>
          {/* end::Separator */}

          <div className='px-7 py-5'>
            {/* begin::Input group */}
            <div className='mb-10'>
              {currentUser?.role === UserRole.Admin && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <AdvPicker value={searchAdv} onChange={(user) => setSearchAdv(user)} />
                </div>
              )}
              <div className='d-flex align-items-center position-relative my-2'>
                <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                <input
                  type='text'
                  data-kt-campaign-table-filter='search'
                  className='form-control form-control-solid ps-14'
                  placeholder='Search campaign by name'
                  value={searchCampByName}
                  onChange={(e) => setSearchCampByName(e.target.value)}
                />
              </div>
            </div>

            {/* begin::Actions */}
            <div className='d-flex justify-content-end'>
              <button
                type='button'
                onClick={resetData}
                className='btn btn-light btn-active-light-primary fw-bold px-6'
                data-kt-menu-dismiss='true'
                data-kt-campaign-table-filter='reset'
              >
                Reset
              </button>
              <button
                type='button'
                onClick={handleSet}
                className='btn btn-primary fw-bold me-2 px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Apply
              </button>
            </div>
            {/* end::Actions */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::SubMenu */}
      </Modal>
    </>
  )
}

export { CampaignFilterByAdv }
