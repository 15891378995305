import { getUniqueIdWithPrefix } from "../../../_metronic/assets/ts/_utils";

const ComparisonOptions = ["<", "<=", ">", ">=", "=="];
const MetricOptions = [
  {
    value: "IMPS",
    title: "IMPRESSIONS"
  },
  {
    value: "CLICKS",
    title: "CLICKS",
  },
  {
    value: "CONV",
    title: "CONV1",
  },
  {
    value: "CONV2",
    title: "CONV2",
  },
  {
    value: "CR",
    title: "CR1",
  },
  {
    value: "CR2",
    title: "CR2",
  },
  {
    value: "CTR",
    title: "CTR",
  }
];

export interface RuleMetric {
  frontend_id: string,
  metric: string;
  comparison: string;
  value: string;
}

export interface Autorule {
  frontend_id: string
  days: number,
  metrics: RuleMetric[]
}

export type RuleKey = 'metric' | 'comparison' | 'value';

export const generateRuleString = (autoRules: RuleMetric[]): string => {
  return autoRules.map((rule, index) => {
    if (rule.metric && index > 0) {
      return ` AND ${rule.metric}${rule.comparison}${rule.value}`;
    } else if (rule.metric) {
      return `${rule.metric}${rule.comparison}${rule.value}`;
    }
    return '';
  }).join('');
};

export const parseExp = (exp: string): RuleMetric[] => {
  const regex = /([A-Z0-9]+)\s*(<=?|>=?|==)\s*([0-9]+(?:\.[0-9]+)?)/g;
  let match;
  const rules: RuleMetric[] = [];

  while ((match = regex.exec(exp)) !== null) {
    const metric = match[1];
    const comparison = match[2];
    const value = match[3];

    rules.push({ metric, comparison, value, frontend_id: getUniqueIdWithPrefix('metric') });
  }

  return rules;
};

type Props = {
  handleRuleChange: (rule: Autorule) => void,
  handleDelete: () => void,
  rule: Autorule
}

const AutoruleMetricsControl = ({ handleRuleChange, handleDelete, rule }: Props) => {
  const comparisonOptions = ComparisonOptions.map((option, i) => (
    <option key={i} value={option}>{option}</option>
  ));

  return (
    <div>
      {rule.metrics.map((metric) => (
        <div key={`${rule.frontend_id}_metric_${metric.frontend_id}`} className="row align-items-center">
          <div className="col-md-3 mb-3">
            <label htmlFor={`metric_${metric.frontend_id}`} className="form-label">Metric</label>
            <select
              id={`metric_${metric.frontend_id}`}
              name={`metric_${metric.frontend_id}`}
              className="form-select"
              value={metric.metric}
              onChange={(e) => {
                const updated = [...rule.metrics]
                updated[updated.findIndex(e => e.frontend_id === metric.frontend_id)].metric = e.target.value;
                const updatedRule: Autorule = { ...rule, metrics: updated };
                handleRuleChange(updatedRule);
              }}
            >
              {MetricOptions.map(e => <option value={e.value}>{e.title}</option>)}
            </select>
          </div>
          <div className="col-md-3 mb-3">
            <label htmlFor={`comparison_${metric.frontend_id}`} className="form-label">Comparison</label>
            <select
              id={`comparison_${metric.frontend_id}`}
              name={`comparison_${metric.frontend_id}`}
              className="form-select"
              value={metric.comparison}
              onChange={(e) => {
                const updated = [...rule.metrics]
                updated[updated.findIndex(e => e.frontend_id === metric.frontend_id)].comparison = e.target.value;
                const updatedRule: Autorule = { ...rule, metrics: updated };
                handleRuleChange(updatedRule);
              }}
            >
              {comparisonOptions}
            </select>
          </div>
          <div className="col-md-3 mb-3">
            <label htmlFor={`value_${metric.frontend_id}`} className="form-label">Value</label>
            <input
              type="text"
              id={`value_${metric.frontend_id}`}
              name={`value_${metric.frontend_id}`}
              className="form-control"
              value={metric.value}
              onChange={(e) => {
                const updated = [...rule.metrics]
                updated[updated.findIndex(e => e.frontend_id === metric.frontend_id)].value = e.target.value;
                const updatedRule: Autorule = { ...rule, metrics: updated };
                handleRuleChange(updatedRule);
              }}
            />
          </div>
          <div className="col-md-3 mt-5 d-flex align-items-center ">
            <button
              type="button"
              className="btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2"
              style={{ width: '32px', height: '32px', padding: 0 }}
              onClick={(e) => {
                e.preventDefault();
                if (rule.metrics.length === 1) {
                  handleDelete();
                  return;
                }

                const updated = [...rule.metrics].filter(e => e.frontend_id !== metric.frontend_id)
                const updatedRule: Autorule = { ...rule, metrics: updated };
                handleRuleChange(updatedRule);
              }}
            >
              <i className="bi bi-x-lg" style={{ verticalAlign: 'middle', margin: '0 auto', padding: 0, marginLeft: '1px', marginTop: '-1px' }}></i>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};


export default AutoruleMetricsControl;
