/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { SelectCreativeButton } from './SelectCreativeButton'
import { useThemeMode } from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import clsx from 'clsx'
import { SelectCreativeModal } from '../creative/CreativePicker'
import { Link } from 'react-router-dom'
import { CampaignDisplayType } from './Campaigns'
import { client } from '../../../api/v2/gql/client'
import { MUTATION_ATTACH_CREATIVE, MUTATION_DETACH_CREATIVE, MUTATION_UPDATE_CAMPAIGN } from '../../../api/v2/gql/campaigns'
import { CampaignType } from '../../../api/v2/gql/gen/graphql'

type Props = {
  className?: string | undefined
  campaignType: CampaignType,
  createButtonTitle?: string,
  onAttach: (creative: CampaignDisplayType['creatives'][number]) => void,
  onDetach: (creative: CampaignDisplayType['creatives'][number]) => void,
  creatives: CampaignDisplayType['creatives'][number][],
}

const CreativesTable: React.FC<Props> = ({ className, campaignType, createButtonTitle = "Add creative", creatives, onAttach, onDetach }) => {
  const mode = useThemeMode().currentMode()
  const [addCreativeModal, setAddCreativeModal] = useState(false);

  if (creatives.length === 0) {
    return (
      <>
        <SelectCreativeModal campaignType={campaignType} handleClose={() => {
          setAddCreativeModal(false);
        }} show={addCreativeModal} data={{
          setCreative: async (e) => {
            onAttach(e);
          }
        }} ignoredIds={creatives.map((e) => e.id)} />
        <div className={clsx('d-flex flex-row align-items-center justify-content-center', { 'card': mode === 'light' })}>
          <button type="button" className="btn btn-md p-2 px-6 btn-color-primary btn-active-light-primary" style={{ width: '100%' }} onClick={(e) => {
            e.preventDefault();
            setAddCreativeModal(true);
          }}>
            <SelectCreativeButton />
            <span>
              {createButtonTitle}
            </span>
          </button>
        </div>
      </>
    )
  }
  
  const handleStatusChange = async (creative: CampaignDisplayType['creatives'][number]) => {
    onDetach(creative);
  }

  return (
    <div className={`card ${className ?? ''}`}>
      {/* begin::Body */}
      <SelectCreativeModal campaignType={campaignType} handleClose={() => {
        setAddCreativeModal(false);
      }} show={addCreativeModal} data={{
        setCreative: async (e) => {
          onAttach(e);
        }
      }} ignoredIds={creatives.map((e) => e.id)} />
      <div>
        {/* begin::Table container */}
        <div className={clsx('table-responsive', { 'card rounded-0 rounded-bottom': mode === 'light' })}>
          {/* begin::Table */}
          <table className='table align-middle gs-0' style={{ marginBottom: '0' }}>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 w-60px'>Status</th>
                <th className='w-200px'>Creatives</th>
                <th className='w-200px'>Creative data</th>
                <th className='w-100px'>Traffic %</th>
                <th className='w-200px text-end pe-4'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {creatives.map((creative) => {
                return (
                  <tr key={creative.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className="form-check form-switch form-check-custom form-check-solid justify-content-start">
                          <input className="form-check-input w-35px h-20px ms-4" type="checkbox"
                            defaultChecked={true}
                            onChange={(e) => {
                              handleStatusChange(creative);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='me-5'>
                          <span className='symbol-label'>
                            <img src={creative.imgRurl ?? undefined} style={{ maxWidth: "100px" }} className='h-50px rounded align-self-center' alt='' />
                          </span>
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <span className='text-dark mb-1 fs-6'>
                            {creative.name}
                          </span>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {(() => {
                              switch (creative.type) {
                                case 'banner':
                                  return <>Banner({creative.size})</>
                                default:
                                  return <>{creative.type}</>
                              }
                            })()}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href={creative.rurl ?? undefined}
                        className='text-dark text-hover-primary d-block fs-6'
                      >
                        {creative.rurl && (<>
                          {creative.rurl.length >= 30
                            ? `${creative.rurl.substring(0, 30)}...`
                            : creative.rurl}
                        </>)}
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7' style={{
                        maxHeight: "3.6em",
                        lineHeight: "1.8em",
                        textOverflow: "ellipsis",
                        wordWrap: "break-word",
                        overflow: "hidden",
                      }}>
                        {[creative.title, creative.description]
                          .filter((e) => e ? e.trim().length > 0 : '')
                          .join(' ')}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark text-hover-primary d-block fs-6'>
                        {(100 / creatives.length).toFixed(0)}%
                      </span>
                      {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        <div className='dropzone-progress'>
                          <div className='progress'>
                            <div
                              className='progress-bar bg-primary'
                              role='progressbar'
                              style={{width: `${100 / creatives.length}%`}}
                            ></div>
                          </div>
                        </div>
                      </span> */}
                    </td>
                    <td className='text-end pe-4'>
                      <span onClick={(e) => {
                        e.preventDefault();
                        setAddCreativeModal(true);
                      }}>
                        <SelectCreativeButton className='btn-bg-light' />
                      </span>

                      <Link
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        to={`/creatives/${creative.id}`}
                      >
                        <KTIcon iconName='pencil' className='fs-3' />
                      </Link>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { CreativesTable }
