import { ApolloClient, FetchResult, InMemoryCache, Observable, createHttpLink } from "@apollo/client";
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { getAuth, setAuth } from "../../../app/modules/auth";
import { MUTATION_REFRESH_TOKEN } from "./auth";

export const link = createHttpLink({
  uri: window._env_.REACT_APP_BALANCE_API_URL
});

const authLink = setContext((_, { headers }) => {
  const token = getAuth()?.access_token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const refreshJWTLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'unauthorized':
            // ignore 401 error for a refresh request
            if (operation.operationName === 'refreshToken') return;

            const observable = new Observable<FetchResult<Record<string, any>>>(
              (observer) => {
                (async () => {
                  try {
                    const accessModel = await refreshToken();
                    if (!accessModel) {
                      throw err;
                    }

                    setAuth({
                      access_token: accessModel.accessToken,
                      refresh_token: accessModel.refreshToken,
                    })

                    // Retry the failed request
                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                  } catch (err) {
                    observer.error(err);
                  }
                })();
              }
            );

            return observable;
        }
      }
    }
  }
);

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: refreshJWTLink.concat(authLink.concat(link)),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

const refreshToken = async () => {
  const authModel = getAuth()
  if (!authModel) return undefined;
  const response = await client.mutate({
    mutation: MUTATION_REFRESH_TOKEN,
    variables: {
      token: authModel.refresh_token,
    }
  })
  return response.data?.refreshToken;
}