import { gql } from "./gen";

export const QUERY_FIND_MOBILE_CARRIERS = gql(`
  query FindMobileCarriers($query: String, $pagination: PaginationInput!, $countryIDs: [ID!]) {
      findMobileCarriers(
        query: $query
        pagination: $pagination
        country_ids: $countryIDs
      ) {
        pageInfo {
          totalCount
        }
        carriers {
          id
          name
        }
      }
    }
`)