import { toast } from 'react-toastify'

export enum AlertType {
  ERROR,
  SUCCESS
}

export const generateAlert = (message: string, type: AlertType) => {
  if (type === AlertType.ERROR) {
    toast.error(message)
  }
  else if (type === AlertType.SUCCESS) {
    toast.success(message)
  }
}
