import { AsyncPaginate, AsyncPaginateProps, LoadOptions } from 'react-select-async-paginate';
import { GroupBase, MultiValue, SelectInstance } from 'react-select';
import { useLazyQuery } from '@apollo/client';
import { QUERY_FIND_MOBILE_CARRIERS } from '../../api/v2/gql/mobile_carriers';
import { useEffect, useRef } from 'react';

export type MobileCarrierMultiPickerResult = { name: string }

type Props = {
  options?: Partial<AsyncPaginateProps<MobileCarrierMultiPickerResult, GroupBase<MobileCarrierMultiPickerResult>, { page: number }, true>>
  value: MobileCarrierMultiPickerResult[] | null
  countryIDs: string[] | undefined
  onChange?: (newValue: MultiValue<MobileCarrierMultiPickerResult>) => void;
}

export const MobileCarrierMultiPicker = (props: Props) => {
  const [fetchCarriers] = useLazyQuery(QUERY_FIND_MOBILE_CARRIERS)

  const loadOptions: LoadOptions<MobileCarrierMultiPickerResult, GroupBase<MobileCarrierMultiPickerResult>, { page: number }> = async (search, options, additional) => {
    const page = additional?.page ?? 1;
    const pageSize = 10;
    const response = await fetchCarriers({
      variables: {
        query: search,
        countryIDs: props.countryIDs,
        pagination: {
          pageNumber: page,
          pageSize: pageSize,
        },
      }
    });

    return {
      options: response.data!.findMobileCarriers.carriers.map(e => {
        return { name: e.name }
      }),
      hasMore: response.data!.findMobileCarriers.pageInfo.totalCount > page * pageSize,
      additional: {
        page: page + 1
      }
    }
  }

  return (
    <AsyncPaginate
      isMulti
      loadOptions={loadOptions}
      loadOptionsOnMenuOpen
      onChange={(u) => {
        if (props.onChange) {
          props.onChange(u)
        }
      }}
      className="react-select"
      classNamePrefix="select"
      debounceTimeout={100}
      getOptionLabel={(u) => u.name}
      getOptionValue={(u) => u.name}
      value={props.value}
      cacheUniqs={[ JSON.stringify(props.countryIDs) ]}
      {...props.options}
    />
  )
}