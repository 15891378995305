import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import {
  IPaginationRequest,
} from '../../../api/models'
import { Modal, Spinner } from 'react-bootstrap'
import { Campaign } from './Campaign'
import { Pagination } from '../../helpers/pagination/Pagination'
import { useAuth } from '../../../app/modules/auth'
import { CreativesTable } from './CreativesTable'
import CampaignFilter from './CampaignFilter'
import { CampaignFilterByAdv, CampaignQueryFilter } from './CampaignFilterByAdv'
import clsx from 'clsx'
import { FindCampaignsQuery, UserRole } from '../../../api/v2/gql/gen/graphql'
import { useLazyQuery } from '@apollo/client'
import { MUTATION_ATTACH_CREATIVE, MUTATION_DETACH_CREATIVE, QUERY_FIND_CAMPAIGNS } from '../../../api/v2/gql/campaigns'
import { client } from '../../../api/v2/gql/client'

export type CampaignDisplayType = FindCampaignsQuery['findCampaigns']['campaigns'][number]

export const Campaigns = () => {
  const { currentUser } = useAuth()
  const PageSize = 10
  const intl = useIntl()
  const [pagination, setPagination] = useState<IPaginationRequest>({
    pageNumber: 1,
    pageSize: PageSize,
  })
  const [totalCount, setTotalCount] = useState(0)

  const [filter, setFilter] = useState<CampaignFilter>({ pay_type: undefined, status: undefined, type: undefined })
  const [queryFilter, setQueryFilter] = useState<CampaignQueryFilter>({ adv_id: undefined, camp_name: undefined })

  const [fetchCampaigns, { loading }] = useLazyQuery(QUERY_FIND_CAMPAIGNS)
  const [campaigns, setCampaigns] = useState<CampaignDisplayType[]>([]);

  useEffect(() => {
    update()
  }, [pagination, filter, queryFilter])

  const update = () => {
    const fetchData = async () => {
      try {
        const adv_id = currentUser?.role == UserRole.Admin ? queryFilter.adv_id : currentUser?.advertiserProfile.advertiserID;
        const response = await fetchCampaigns({
          variables: {
            creativesPagination: {
              pageNumber: 1,
              pageSize: 20,
            },
            pagination: {
              pageNumber: pagination.pageNumber,
              pageSize: pagination.pageSize,
            },
            active: filter.status,
            advID: adv_id,
            payType: filter.pay_type,
            query: queryFilter.camp_name,
            type: filter.type,
          }
        });
        if (response.data) {
          setCampaigns(response.data?.findCampaigns.campaigns)
          setTotalCount(response.data?.findCampaigns.pageInfo.totalCount);
        }
      } catch (error) {

      }
    }

    fetchData()
  }

  return (
    <>
      <Modal show={loading}>
        <div className='d-flex column align-self-center align-items-center'>
          <Spinner animation='border' role='status' />
          <span className='px-4 py-4'>Loading...</span>
        </div>
      </Modal>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CAMPAIGNS' })}</PageTitle>
      <div className='card-body py-3 container rounded-top'>
        <div className="d-flex align-items-center position-relative">
          <CampaignFilterByAdv filter={queryFilter} setFilter={setQueryFilter} />
          <CampaignFilter filter={filter} setFilter={setFilter} />
        </div>
      </div>
      <>
        <div className='row gy-5 gx-xl-8'>
          <div className='col-xl-12'>
            <div>
              {/* Обертка для всех кампаний и творческих элементов */}
              <div className='card-xxl-stretch mb-5 mb-xl-8'>
                {/* begin::Table */}
                <div className='row align-middle gs-0 gy-4'>
                  {campaigns.map((campaign, index) => (
                    <div key={campaign.id} className='col-12'>
                      {/* Карточка для кампании */}
                      <div className={clsx('bg-secondary border border-gray-300 rounded')}>
                        <div className='bg-secondary'>
                          <Campaign
                            key={campaign.id}
                            index={index}
                            campaign={campaign}
                            update={update}
                            deleteUpdate={() =>
                              setCampaigns(
                                campaigns.filter((x) => x.id !== campaign.id)
                              )
                            }
                          />
                        </div>
                        {/* Карточка для творческих элементов */}
                        <div className='bg-secondary'>
                          <div
                            className='bg-secondary'
                            style={{ maxHeight: '400px', overflowY: 'auto' }}
                          >
                            <CreativesTable
                              className='bg-secondary'
                              campaignType={campaign.type}
                              creatives={campaign.creatives}
                              onAttach={async (creative) => {
                                await client.mutate({
                                  mutation: MUTATION_ATTACH_CREATIVE,
                                  variables: {
                                    campaignID: campaign.id,
                                    creativeID: creative.id,
                                  }
                                })
                                update();
                              }}
                              onDetach={async (creative) => {
                                await client.mutate({
                                  mutation: MUTATION_DETACH_CREATIVE,
                                  variables: {
                                    campaignID: campaign.id,
                                    creativeID: creative.id,
                                  }
                                })
                                update();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* end::Table */}
                <Pagination
                  className='mt-10 mb-5'
                  currentPage={pagination.pageNumber}
                  totalCount={totalCount}
                  pageSize={PageSize}
                  onPageChange={(page: number) => {
                    setPagination({ ...pagination, pageNumber: page })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}
