type Props = {
    className?: string
}

export const SelectCreativeButton = ({className}: Props) => {
    return (
        <>
            <a
                href="#"
                className={`btn btn-icon btn-active-color-primary ${className} btn-sm me-1`}
            >
                <i className="ki-duotone ki-element-plus text-success">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                </i>
            </a>
        </>
    )
}